import React from 'react';
import { Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function ArrowButton({ buttonText }) {
  return (
    <Button variant="contained" color="primary" className="arrow-button">
      {buttonText}
      <ArrowRightAltIcon className="arrow-icon" />
    </Button>
  );
}

export default ArrowButton;
