import React, { useEffect, useState } from "react";
import DashboardProjects from "../components/DashboardProjects";
import DashboardRecent from "../components/DashboardRecent";
import "../css/dashboard.css";
import { SendRequest } from "../api"; // path to api.js
import LoadingComponent from "../components/LoadingComponent";
import DashboardTutorial from "../components/DashboardTutorial";
import { Box, Grid } from "@mui/material";

function DashboardPage({ isAdmin, user, isLoggedIn , isSuperAdmin}) {
  const [recentProjects, setRecentProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);

  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [isRecentLoading, setIsRecentLoading] = useState(false);

  useEffect(() => {
    const fetchRecentProjects = async () => {
      setIsRecentLoading(true);
      try {
        const response = await SendRequest("/api/last_projects/?n=5", "GET");
        // console.log(response)
        setRecentProjects(response);
        setIsRecentLoading(false);
      } catch (error) {
        console.error("Failed to fetch recent projects:", error);
        setIsRecentLoading(false);
      }
    };

    const fetchAllProjects = async () => {
      setIsProjectLoading(true);
      try {
        const response = await SendRequest("/api/projects/", "GET");
        // console.log('ALL projects = ',response)
        setAllProjects(response["results"]);
        setIsProjectLoading(false);
      } catch (error) {
        console.error("Failed to fetch all projects:", error);
        setIsProjectLoading(false);
      }
    };

    fetchRecentProjects();
    fetchAllProjects();
  }, []);

  if (isRecentLoading || isProjectLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={5}
      mt={5}
      p={3}
      style={{ backgroundColor: "#fafbfc", flexWrap: "wrap" }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <DashboardRecent projects={recentProjects} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardProjects allProjects={allProjects} />
        </Grid>
      </Grid>

      <DashboardTutorial isSuperAdmin={isSuperAdmin} isAdmin={isAdmin}/>
    </Box>
   
  );
}

export default DashboardPage;
