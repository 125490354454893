import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Paper } from '@mui/material';

function ScreenCaptureComponent({ status, isLoading, setIsLoading  }) {

  // const [images, setImages] = useState([]);
  const [error, setError] = useState([]);
  // const [texts, setTexts] = useState([]);
  const [output, setOutput] = useState([]);
  const { scriptid } = useParams();

  useEffect(() => {
    const fetchPageData = async () => {
      setIsLoading(true)
      const response = await SendRequest(
        `/api/scriptentries/${scriptid}/`,
        "GET"
      );
      // console.log("In output => ", response);

      // if (response.error) {
      //   // alert(response.error);
      //   setLoaded(true);
      // } else {
      if (status == "3") {
        setError(response.error);
      } else {
        // print()
        setOutput(response.output);
      }

      setIsLoading(false)
      // }
    };
    if (status == "3" || status == "2") {
      fetchPageData();
    } else {
      // TODO later show
    }
    // console.log("STATUS-->>>> ", status);
  }, [status]);

  return (
    <Box className="script-right-in">
      {error && status === "3" && (
        <Paper elevation={1} sx={{ mb: 2, p: 2, backgroundColor: 'error.light' }}>
          <Typography variant="h6" color="error.main">:ERROR:</Typography>
          <Typography variant="body1" color="textSecondary">{error}</Typography>
        </Paper>
      )}
      {status !== "3" && (
        output.length === 0 ? (
          <Typography variant="h6" className="no-data">No output</Typography>
        ) : (
          output.map((o) => (
            <Box key={o.created_date} sx={{ mb: 2 }}>
               <Typography variant="body2" className="out-created">
                    {o.created_date}
                  </Typography>
              <Box mt={1}>
                {o.output_screen_capture.map((image) => (
                  <Box key={image.id} sx={{ mb: 1 }}>
                    <img
                      src={image.image}
                      alt="description"
                      style={{ maxWidth: '100%', borderRadius: 4 }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))
        )
      )}
    </Box>
  );
  
}

export default ScreenCaptureComponent;
