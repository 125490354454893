import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Alert } from "@mui/material";
import { SendRequest } from "../api";

function CustomWidgets({
  setCustomWidgets,
  customWidgets,
  setShowCustomWidgets,
  setIsLoading,
  setAlert
}) {
  const [openForm, setOpenForm] = useState(false);
  const [error, setError] = useState(null);

  const LEVEL_MAP = {
    0: "Header",
    1: "Item",
  };

  const TYPE_MAP = {
    0: "Choice",
    1: "Numeric",
    2: "Users",
  };

  const [formData, setFormData] = useState({
    name: "",
    level: "",
    type: "",
    choices: [],
    description: "",
  });
  const [editingWidget, setEditingWidget] = useState(null);

  // useEffect(() => {
  //   console.log("customWidgets", customWidgets);
  // }, [customWidgets]);

  const openCreateForm = () => {
    setFormData({
      name: "",
      level: "",
      type: "",
      choices: [],
      description: "",
    });
    setEditingWidget(null);
    setOpenForm(true);
  };

  const handleAddChoice = () => {
    if (formData.choices.includes("")) {
      setError("Please fill out the existing choices before adding a new one.");
      return;
    }
    if (isDuplicateChoice("")) {
      setError("Choices should be unique.");
      return;
    }
    setFormData({ ...formData, choices: [...formData.choices, ""] });
  };

  const handleChoiceChange = (index, value) => {
    const newChoices = [...formData.choices];
    newChoices[index] = value;
    if (isValidChoice(value, index)) {
      setError(null);
    }
    setFormData({ ...formData, choices: newChoices });
  };

  const handleRemoveChoice = (index) => {
    const newChoices = formData.choices.filter((_, i) => i !== index); // remove the specific choice
    setFormData({ ...formData, choices: newChoices });
  };

  const openEditForm = (widget) => {
    setFormData({
      ...widget,
      level: LEVEL_MAP[widget.level],
      type: TYPE_MAP[widget.type],
    });
    setEditingWidget(widget);
    setOpenForm(true);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const isValidChoice = (choice, index) => {
    const regex = /\band\b|\bor\b/i; // \b denotes word boundaries, i makes it case insensitive

    // Check for invalid words
    if (regex.test(choice)) {
      setError("Invalid choice. Avoid using whole words 'AND' or 'OR'.");
      return false;
    }
    // Check for spaces
    if (choice.includes(" ")) {
      setError("Invalid choice. Avoid using spaces.");
      return false;
    }
    // Check for duplicate choices
    if (formData.choices.some((ch, idx) => ch === choice && idx !== index)) {
      setError("Duplicate choice detected.");
      return false;
    }

    return true;
  };

  const isDuplicateChoice = (newChoice) => {
    return formData.choices.some(
      (choice) => choice.toLowerCase() === newChoice.toLowerCase()
    );
  };

  const isFormValid = () => {
    if (!formData.name.trim() || !formData.description.trim()) {
      return false;
    }
    if (formData.type === "Choice" && formData.choices.length === 0) {
      return false;
    }
    if (formData.choices.some((choice) => !choice.trim())) {
      return false;
    }
    if (formData.choices.every((choice) => choice.trim() && !choice.includes(' '))) {
      return true;
  } else {
      return false;
  }
    return true;
  };

  const canAddChoice = () => {
    if (formData.choices.every((choice) => choice.trim() && !choice.includes(' '))) {
        return true;
    } else {
        return false;
    }
};



  const handleCreateEdit = async () => {
    const payload = {
      ...formData,
      level: getKeyByValue(LEVEL_MAP, formData.level),
      type: getKeyByValue(TYPE_MAP, formData.type),
    };

    if (!isFormValid()) {
      // Show an error message to the user
      return;
    }

    setIsLoading(true);

    try {
      let response;
      let updatedWidgets;
      if (editingWidget) {
        response = await SendRequest(
          `/api/customwidgets/${editingWidget.id}/`,
          "PUT",
          payload
        );
        if (response.error) {
          setAlert(response.error, "error")
        } else {
          updatedWidgets = customWidgets.map((widget) =>
            widget.id === editingWidget.id ? { ...widget, ...response } : widget
          );
          setCustomWidgets(updatedWidgets);
        }
      } else {
        response = await SendRequest(`/api/customwidgets/`, "POST", payload);

        if (response.error) {
          setAlert(response.error, "error")
        } else {
          updatedWidgets = [...customWidgets, response];
          setCustomWidgets(updatedWidgets);
        }
      }

      // Setting the updated state
      // console.log(response);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setOpenForm(false);
  };

  const handleDelete = async (widget) => {
    setIsLoading(true);
    try {
      const response = await SendRequest(
        `/api/customwidgets/${widget.id}/`,
        "DELETE"
      );
      // console.log(response);
      // Removing the deleted widget from the state
      const updatedWidgets = customWidgets.filter((w) => w.id !== widget.id);
      setCustomWidgets(updatedWidgets);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={true} onClose={() => setShowCustomWidgets(false)} fullWidth>
      <Box sx={{ p: 3 }}>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            Custom Widgets
          </Typography>

          <Button onClick={openCreateForm}>+ Create Widget</Button>
        </div>

        <List>
          {customWidgets.map((widget) => (
            <ListItem key={widget.id}>
              <ListItemText primary={widget.name} />
              {widget.sub_domain_name !== "www" && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => openEditForm(widget)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(widget)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => setShowCustomWidgets(false)}
          >
            Close
          </Button>
        </Box>
      </Box>

      <Dialog open={openForm} onClose={() => setOpenForm(false)} fullWidth>
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {editingWidget ? "Edit Widget" : "Create Widget"}
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            label="Name"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <Select
            fullWidth
            displayEmpty
            value={formData.level}
            onChange={(e) =>
              setFormData({ ...formData, level: e.target.value })
            }
          >
            <MenuItem value="" disabled>
              Select Level
            </MenuItem>
            {Object.values(LEVEL_MAP).map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            displayEmpty
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
          >
            <MenuItem value="" disabled>
              Select Type
            </MenuItem>
            {Object.values(TYPE_MAP).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>

          {formData.type === "Choice" && (
            <Box mt={2}>
              {formData.choices.map((choice, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    fullWidth
                    label={`Choice ${index + 1}`}
                    value={choice}
                    onChange={(e) => handleChoiceChange(index, e.target.value)}
                  />
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveChoice(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                onClick={handleAddChoice}
                variant="outlined"
                disabled={!canAddChoice()}
              >
                + Add Choice
              </Button>
            </Box>
          )}

          <TextField
            multiline
            fullWidth
            label="Description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
          <Box mt={3} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              disabled={!isFormValid()}
              onClick={handleCreateEdit}
            >
              {editingWidget ? "Edit" : "Create"}
            </Button>
            <Button variant="contained" onClick={() => setOpenForm(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Dialog>
  );
}

export default CustomWidgets;
