import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { SendRequest } from "../api";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";

import {
  GanttComponent,
  Selection,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Edit,
  Filter,
  Sort,
  Reorder,
  PdfExport,
  ExcelExport,
  RowDD,
  Toolbar,
  ToolbarItem,
  ContextMenu,
  Resize,
} from "@syncfusion/ej2-react-gantt";

function GanttChartComponent({ data, setLoading }) {
  const [updatedGanttData, setUpdatedGanttData] = useState(data.chart_data);
  const [chartid, setChartId] = useState(data.id);
  const ganttRef = useRef(null);

  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    duration: "Duration",
    progress: "Progress",
    dependency: "Predecessor",
    child: "subtasks",
  };

  const toolbar = [
    "Add",
    "Edit",
    "Update",
    "Delete",
    "Cancel",
    "ExpandAll",
    "CollapseAll",
    "Indent",
    "Outdent",
  ];

  const editSettings = {
    allowEditing: true,
    mode: "Dialog",
    allowTaskbarEditing: true,
    allowAdding: true,
    allowDeleting: true,
    showDeleteConfirmDialog: true,
  };

  const saveToServer = async () => {
    try {
      setLoading(true);
      const response = await SendRequest(`/api/charts/${chartid}/`, "PUT", {
        chart_data: updatedGanttData,
      });
      if (response.error) {
        alert("Something went wrong");
      }
      // console.log("Data saved successfully");
    } catch (error) {
      console.log("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary"
        onClick={saveToServer}
        color="primary"
      >
        Save
      </Button>
      <GanttComponent
        ref={ganttRef}
        dataSource={updatedGanttData}
        height="450px"
        taskFields={taskFields}
        allowExcelExport={true}
        allowSelection={true}
        editSettings={editSettings}
        allowFiltering={true}
        allowSorting={true}
        toolbar={toolbar}
        enableContextMenu={true}
        actionComplete={(args) => {
          if (args.requestType === "save" || args.requestType === "delete") {
            // console.log("SAVING");
            // Capture the updated data using the Gantt component's ref
            const updatedData = ganttRef.current.dataSource;
            // console.log(updatedData, updatedGanttData);
            setUpdatedGanttData(updatedData);
          }
        }}
      >
        <Inject
          services={[
            Edit,
            Selection,
            Filter,
            Sort,
            Reorder,
            RowDD,
            ExcelExport,
            PdfExport,
            Toolbar,
            ContextMenu,
            Resize,
          ]}
        />
      </GanttComponent>
    </>
  );
}

export default GanttChartComponent;
