import RenameSvg from "../svg/rename";
import DeleteSvg from "../svg/delete";
import EditSvg from "../svg/edit";

import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

function PagePopupComponent({ page, onClose, onRename, onEdit, onDelete, selectedPageLocation }) {
  return (
    <Menu
      open={Boolean(selectedPageLocation)}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        selectedPageLocation
          ? { top: selectedPageLocation, left: 220 }
          : undefined
      }
    >
      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <EditSvg />
        </ListItemIcon>
        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={onRename}>
        <ListItemIcon>
          <RenameSvg />
        </ListItemIcon>
        <ListItemText primary="Rename" />
      </MenuItem>

      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <DeleteSvg />
        </ListItemIcon>
        <ListItemText primary="Delete" />
      </MenuItem>
    </Menu>
  );
}

export default PagePopupComponent;
