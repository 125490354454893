import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SendRequest } from "../api";
import { Box, CircularProgress } from "@mui/material";
import GanttChartComponent from "../components/GanttChartComponent";
import KanbanChartComponent from "../components/KanbanChartComponent";
import SchedulerChartComponent from "../components/SchedulerChartComponent";

function ChartPage() {
  const { chartid , project_id } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    // Define the function to fetch the report
    const fetchData = async () => {
      const response = await SendRequest(`/api/charts/${chartid}/`, "GET");
      if ("error" in response) {
        setError(response.error);
        setLoading(false)
      } else {
        // console.log(response);
        setData(response);
        setLoading(false)
      }
    };
    fetchData();
  }, [chartid]);

  // Determine which component to render based on data.type
  const renderChartComponent = () => {
    switch (data.type) {
      case 0:
        return <GanttChartComponent data={data} setLoading={setLoading} />;
      case 1:
        return <KanbanChartComponent project_id={project_id} data={data} setLoading={setLoading} />;
      case 2:
        return <SchedulerChartComponent data={data} setLoading={setLoading} />;
      default:
        return null; // Return null or some default component if none of the conditions match
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1400",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <div>{error ? <p>Error: {error}</p> : renderChartComponent()}</div>
    </>
  );
}

export default ChartPage;
