import Cookies from "js-cookie"; // import the js-cookie library

// const BASE_URL = "http://127.0.0.1:8000";
const BASE_URL = 'https://accpro-backend.up.railway.app';

const getSubdomain = () => {
  const hostname = window.location.hostname;
  // Assuming you're using the standard <subdomain>.<domain>.<TLD> format
  const parts = hostname.split(".");
  // If there's a subdomain, it should be the first part of the split hostname.
  return parts.length > 2 ? parts[0] : null;
};

export async function SendRequest(endpoint, method, body, headers = {}) {
  // const navigate= useNavigate(); // get the navigate object
  const token = Cookies.get("token"); // get the token from the cookies
  // console.log('TOKEN COOKIE = ', token)
  const subdomain = getSubdomain();
  // console.log(subdomain);
  const authHeaders = token ? { Authorization: `Token ${token}` } : {};

  // Add the subdomain to the headers if it exists
  if (subdomain) {
    authHeaders["Subdomain"] = subdomain;
  }

  // TESTING -
  // authHeaders["Subdomain"] = "www";
  //
  

  let finalHeaders = { ...authHeaders, ...headers };
  if (body instanceof FormData) {
    delete finalHeaders["Content-Type"];
    // console.log(headers);
    // console.log(body);
  } else {
    body = JSON.stringify(body);
    finalHeaders["Content-Type"] = "application/json";
  }
  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method,
      headers: finalHeaders,
      body,
    });

    if (response.status === 404) {
      // navigate('/404/'); // redirect to /404/ if the response status is 404
      window.location.href = "/404/";
      return;
    }
    if (response.status === 401 && window.location.pathname !== "/login/") {
      window.location.href = "/login/";
      return;
    }

    return await response.json();
  } catch (error) {
    console.log("ERROR IN -> ", error, endpoint);
    if (error instanceof TypeError) {
      alert("Couldn't connect to internet!!!");
    }
  }

  // if (!response.ok) {
  //   throw await response.json();
  // }
}
