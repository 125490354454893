import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { SendRequest } from "../api"; // Update the path
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../components/LoadingComponent";
import AlertContext from "../components/AlertContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Box, Button, Typography, Grid, CircularProgress } from "@mui/material";

function TemplatesPage() {
  const navigate = useNavigate();
  const { folderid } = useParams();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AlertContext);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await SendRequest("/api/templates/", "GET");
        // console.log("TEMPLATES = ", response);
        if (response && response.data) {
          setTemplates(response.data);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [folderid]);

  const createTemplatePage = async (templateId) => {
    // Handle the logic of using the template based on its ID
    // console.log(`Using template with ID: ${templateId}`);
    setLoading(true);

    try {
      const response = await SendRequest(
        `/api/createtemplatepage/${templateId}/${folderid}/`,
        "POST"
      );

      if (
        response &&
        response.project_id &&
        response.folder_id &&
        response.page_id
      ) {
        navigate(
          `/edit/${response.project_id}/${response.folder_id}/${response.page_id}/`
        );
      } else {
        console.error("Error in response:", response);

        setAlert("Failed to create template page.", "error");
      }
    } catch (error) {
      console.error("Error creating template page:", error);
      setAlert("Error occurred while creating the template page.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className="template-main">
          <div className="d-flex align-items-center">
          <IconButton onClick={() => navigate(-1)} edge="start" color="inherit">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom className="template-title">
            Templates
          </Typography>
          </div>
         
          <Grid
            container
            spacing={3}
            sx={{ gap: "30px", justifyContent: "center" }}
          >
            {templates.map((template) => (
              <Grid
                item
                xs={12}
                md={4}
                key={template.id}
                className="template-cont-in"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  className="template-cont"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                  >
                    <img
                      className="template-img"
                      src={
                        template.image ? template.image : "/images/template.png"
                      }
                      // alt={template.name}
                    />

                    <Typography variant="h6" className="template-tit">
                      {template.name}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => createTemplatePage(template.id)}
                    className="template-but"
                  >
                    Use
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}

export default TemplatesPage;
