import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { TextField, Grid, Box, Alert } from "@mui/material";
import { SendRequest } from "../api";
import PopupComponent from "./PopupComponent";
import InlineToolsWidget from "./InlineToolsWidget";
import { createRoot } from "react-dom/client";
import ReportBuilder from "./ReportBuilder";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

let root = null;

function EditorBottom({
  customWidgets,
  title,
  setTitle,
  editor,
  setAlert,
  setIsLoading,
  handlePublish,
  params,
  pages,
  scripts,
  setEditorLoaded,
  setEditor,
  content,
  handleEditorChange,
  customWidgetEntries,
  users,
  usersRef,
  userAllowedToResolve,
  currentAuthor,
  setCustomWidgetEntries,
}) {
  // const IMAGE_UPLOAD_URL = "http://127.0.0.1:8000/api/upload_image/";
  const IMAGE_UPLOAD_URL ="https://accpro-backend.up.railway.app/api/upload_image/";

  const [mentionedUser, setMentionedUser] = useState("");
  const [showMentionEmailModal, setShowMentionEmailModal] = useState(false);
  const [buttonSource, setButtonSource] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedHTML, setSelectedHTML] = useState("");
  const [formValues, setFormValues] = useState([]);
  const [showParams, setShowParams] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInlineTools, setShowInlineTools] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const [searchText, setSearchText] = useState(null);
  const [showReport, setShowReport] = useState(false);

  const chartsCreatedRef = useRef(false);

  const [openKanbanPopup, setOpenKanbanPopup] = useState(false);
  // const [scriptAdded, setScriptAdded] = useState(false);

  const isProgramaticChange = useRef(false);
  const prevContentRef = useRef("");

  const { id, folderid, pageid } = useParams();


  // Function to close the popup
  const handleClose = () => {
    setOpenKanbanPopup(false);
  };

  const createChart = async (type, widgetId = "") => {
    if (!pageid) {
      setAlert("Publish page first.", "info");
      return;
    }
    setIsLoading(true);
    const type1 = type == "gantt" ? 0 : type == "kanban" ? 1 : 2;
    const data = { type: type1, page: parseInt(pageid) };
    if (type1 === 1) {
      data.widget_id = widgetId;
      data.project_id = id
    }
    const endpoint = `/api/charts/`;
    const response = await SendRequest(endpoint, "POST", data);
    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      const link = `<div contenteditable="false" class="chart chart-${type}"  data-id="${response.id}"  style="background: #aaa;padding: 2px 10px 2px 10px;border-radius: 5px;width:100%;height:657px;">
          <div >
            ${type}
            <button data-id="${response.id}" class="del-chart">x</button>
          </div> 
          <div class="chart-div" style="width: 100%;height: 620px;">
            <iframe  style="width: 100%;height: 620px;" src="/chart/${id}/${response.id}/" ></iframe>
          </div>
        </div>`;
      replaceText(link);
      handlePublish(null);
    }
  };

  useEffect(() => {
    if (editor && customWidgetEntries) {
      const widgetElements = editor.dom.select(".custom-widget-content");
      // console.log(widgetElements);
      widgetElements.forEach((widgetElement, index) => {
        const widgetId = Number(widgetElement.getAttribute("data-id")); // Convert to number
        const widgetData = customWidgetEntries.find(
          (item) => item.id === widgetId
        ); // Find the corresponding widget data

        if (
          widgetData &&
          !editor.getDoc().getElementById(`custom-widget-component-${widgetId}`)
        ) {
          // console.log("HMMM HEREE -> ", widgetData);

          // Create a new iframe element
          const iframe = document.createElement("iframe");
          iframe.id = `custom-widget-component-${widgetId}`;
          iframe.width = "100%"; // Adjust width as needed
          iframe.height = "70px"; // Adjust height as needed

          // Construct the iframe URL
          let iframeURL = `/widgetentry/${pageid}/?`;
          iframeURL += `widgetentryid=${widgetId}`;
          iframeURL += `&customwidgetid=${widgetData.custom_widget.id}`;
          iframeURL += `&pageid=${pageid}`;
          iframeURL += `&type=${widgetData.custom_widget.type}`;
          iframeURL += `&choices=${widgetData.custom_widget.choices.join(",")}`;
          iframeURL += `&selected_choice=${widgetData.selected_choice}`;
          iframeURL += `&numeric_value=${widgetData.numeric_value}`;
          iframeURL += `&users=${users.map((user) => user.email).join(",")}`;
          iframe.src = iframeURL;
          iframe.style = `
          padding: 0px;
          margin: 0px;
          border: medium;
          overflow: hidden;`;

          widgetElement.appendChild(iframe);
        }
      });
    }

    if (editor && content && !chartsCreatedRef.current) {
      chartsCreatedRef.current = true;


      const chartElements = editor.dom.select(".chart");
      // console.log("chartElements" ,chartElements , content);
      chartElements.forEach((chartElement, index) => {
        const chartId = Number(chartElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `chart-component-${chartId}`;
        iframe.width = "100%";
        iframe.height = "620px";

        // Construct the iframe URL
        let iframeURL = `/chart/${id}/${chartId}/`;
        iframe.src = iframeURL;

        // Use querySelector to find the .chart-div element within chartElement
        let chartDiv = chartElement.querySelector(".chart-div");
        // console.log("CREATING CHART IFRAME", chartDiv);
        if (chartDiv) {
          chartDiv.appendChild(iframe);
        } else {
          console.error("No .chart-div found in chartElement", chartElement);
        }
      });

      // SCRIPTS

      const scriptElements = editor.dom.select(".script-content");
      // console.log(scriptElements);
      scriptElements.forEach((scriptElement, index) => {
        const scriptId = Number(scriptElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `script-component-${scriptId}`;
        iframe.width = "1100";
        iframe.height = "500";

        // Construct the iframe URL
        let iframeURL = `/script/${scriptId}/`;
        iframe.src = iframeURL;

        scriptElement.appendChild(iframe);
      });

      // Reports

      const reportElements = editor.dom.select(".report-content");
      // console.log(reportElements);
      reportElements.forEach((reportElement, index) => {
        const reportId = Number(reportElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `report-component-${reportId}`;
        iframe.width = "1100";
        iframe.height = "500";

        // Construct the iframe URL
        let iframeURL = `/report/${reportId}/`;
        iframe.src = iframeURL;
        reportElement.appendChild(iframe);
      });
    }
  }, [customWidgetEntries, editor , content]);

  useEffect(() => {
    if (editor) {
      const uniqueClass = "inline-tools-widget";
      let portalDiv = document.querySelector(`.${uniqueClass}`);

      if (!portalDiv) {
        portalDiv = document.createElement("div");
        portalDiv.className = uniqueClass;

        let iframeElement = editor.getContainer().querySelector("iframe");
        let parentElement = iframeElement.parentElement;
        parentElement.insertBefore(portalDiv, iframeElement.nextSibling);
      }

      if (!root) {
        root = createRoot(portalDiv);
      }

      // Render the component using a Portal every time the effect runs.
      root.render(
        <InlineToolsWidget
          replaceText={replaceText}
          setShowReport={setShowReport}
          customWidgetEntries={customWidgetEntries}
          users={users}
          customWidgets={customWidgets}
          cursorPosition={cursorPosition}
          searchText={searchText}
          scripts={scripts}
          pages={pages}
          params={params}
          showInlineTools={showInlineTools}
          editor={editor}
          handlePublish={handlePublish}
          setIsLoading={setIsLoading}
          setAlert={setAlert}
          pageid={pageid}
          id={id}
          folderid={folderid}
          setShowInlineTools={setShowInlineTools}
          setCustomWidgetEntries={setCustomWidgetEntries}
          openKanbanPopup={openKanbanPopup}
          setOpenKanbanPopup={setOpenKanbanPopup}
          createChart={createChart}
        />
      );

      return () => {
        // Only unmount the root when the component itself unmounts
        if (
          !cursorPosition &&
          !searchText &&
          !scripts &&
          !pages &&
          !params &&
          !showInlineTools &&
          !editor
        ) {
          try {
            root && root.unmount();
          } catch (error) {
            console.error("Unmount Error: ", error);
          }
        }
      };
    }
  }, [
    cursorPosition,
    searchText,
    scripts,
    pages,
    params,
    showInlineTools,
    editor,
  ]);

  const onValueChange = (name, value) => {
    setFormValues((prev) => {
      const newValues = { ...prev };
      newValues[name] = value;
      return newValues;
    });
  };

  const onSubmitMentionEmail = async () => {
    const response = await SendRequest(
      `/api/mentionemail/${id}/${folderid}/${pageid}/${currentAuthor}/${mentionedUser}/`,
      "POST"
    );

    if (response.error) {
      setAlert(response.error, "error");
    } else {
    }
    setShowMentionEmailModal(false);
  };

  function hasTextPattern(content) {
    const regex = /\{\{([^}]+)\}\}/g;
    return regex.test(content);
  }
  // Function to handle the replacement logic
  async function handleTextReplacement(editor) {
    let editDom = editor.getBody();

    // Find the first element containing the text "{{"
    let elements = Array.from(editDom.getElementsByTagName("*"));
    let targetElement = elements.find((el) => el.textContent.includes("{{"));

    if (targetElement) {
      const content = targetElement.innerHTML;
      const regex = /\{\{([^}]+)\}\}/g;
      let match;

      // Search for the pattern {{text}}
      while ((match = regex.exec(content)) !== null) {
        const text = match[1]; // Extract the text between {{ and }}

        // Show LoadingComponent (assuming it's a function that displays a loading spinner or similar)
        // LoadingComponent();
        // setIsLoading(true)

        // Send request
        // console.log("CHECKING PAGEID", pageid);
        if (!pageid) {
          setAlert("Publish the page first.", "info");
          targetElement.innerHTML = content.replace(`{{${text}}}`, `{{${text}`);
          return;
        }

        setIsLoading(true);
        const response = await SendRequest("/api/getparam/", "POST", {
          text: text,
          pageid: pageid,
        });

        if (response.error) {
          // Alert if there's an error
          setAlert(response.error, "error");
          targetElement.innerHTML = content.replace(`{{${text}}}`, `{{${text}`);
          setIsLoading(false);
        } else {
          const newCont = response.content;

          // console.log("NEW CONTT ", response);

          const replacement = `
                    <div style=""  class="reference-item" data-ref-id="${text}">
                        <div class="reference-item-but" contenteditable="false" style="width: fit-content;display: flex;gap: 10px;margin: 2px;background: cadetblue;padding: 5px;border-radius: 5px;">
                            <div  style="">${text}</div>
                            <button class="del-ref" style="">x</button>
                        </div>
                        <div class="ref-cont" style="padding: 5px;background: aqua;">${newCont}</div>
                    </div>
                `;
          targetElement.innerHTML = content.replace(match[0], replacement);
          setIsLoading(false);
        }

        setTimeout(() => {
          isProgramaticChange.current = false; // Reset the flag after a short delay
        }, 100);
      }
    }
  }

  const mentions_fetch = function (query, success) {
    // console.log("RUNNING mentions_fetch");

    const matchedUsers = usersRef.current
      .filter((user) => user.email.includes(query.term))
      .map((user) => ({
        id: user.id,
        name: user.email,
      }))
      .slice(0, 10);
    // console.log("USERS 2 ", usersRef.current);
    success(matchedUsers);
  };

  const mentions_menu_complete = function (editor, userInfo) {
    // console.log("RUNNING mentions_menu_complete");
    setShowMentionEmailModal(true);
    setMentionedUser(userInfo.id);
    const span = editor.getDoc().createElement("span");
    span.className = "mymention";
    span.setAttribute("data-mention-id", userInfo.id);
    span.textContent = "@" + userInfo.name;
    return span;
  };

  const mentions_select = function (mention, success) {
    // console.log("RUNNING mentions_select");
    const userEmail = mention.textContent.replace("@", "");

    const div = (
      <div className="card">
        <h1>{userEmail}</h1>
      </div>
    );

    success(div);
  };

  function checkPattern(editor) {
    // console.log("INSIDE CHECKPATTERN");
    const currentContent = editor.getContent();
    if (currentContent !== prevContentRef.current) {
      // console.log("CONTENT CHANGED");

      if (!isProgramaticChange.current && hasTextPattern(editor.getContent())) {
        isProgramaticChange.current = true;
        handleTextReplacement(editor);
        isProgramaticChange.current = false;
      }

      const match = currentContent.match(/\/\*([\w]*)/);
      // console.log("CHECKING PATTERN -> ", match);
      if (match) {
        // console.log("YES MATCH");
        setShowInlineTools(true);
        setSearchText(match[1] || "");
        const selection = editor.selection.getRng();
        let top_v = selection.getBoundingClientRect().top
        if (top_v > 350 && top_v <= 400){
          top_v = top_v - 380
        } else if (top_v > 400 && top_v <= 460){
          top_v = top_v - 410
        } else if (top_v > 460 && top_v <= 490){
          top_v = top_v - 450
        }else if (top_v > 490){
          top_v = top_v - 570
        }
        
        setCursorPosition((prevState) => ({
          ...prevState,
          top: top_v,
          left: Math.min(
            selection.getBoundingClientRect().left,
            0.25 * (window.innerWidth || document.documentElement.clientWidth)
          ),
        }));

        // console.log("CURSOR POSITION>....--->>>>>" , selection.getBoundingClientRect().top)


      } else {
        // console.log("NOT A MATCH");
        setShowInlineTools(false);
      }
    }
    prevContentRef.current = currentContent;
  }

  function copyToClipboard(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.top = "0";
    textarea.style.left = "0";
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
  function generateUniqueID() {
    // console.log("GENERATINGGGGGGGG");
    return "table_" + Date.now() + "_" + Math.floor(Math.random() * 1000);
  }

  const onSubmit = async () => {
    setIsLoading(true);
    setShowModal(false);

    // console.log("KOOOII--->>>>>", formValues);
    let data = {};
    if (buttonSource === "tableParamButton") {
      data.type = 1;
      data.tableId = selectedId;
      data.name = formValues["Parameter name"];
      data.action = "1";
    } else {
      data.type = 0;
      data.name = formValues["Parameter name"];
      data.action = "1";
      data.content = selectedHTML; // Assuming you want the form values as content
    }

    const response = await SendRequest(
      `/api/parameter/${pageid}/`,
      "POST",
      data
    );

    if (response.error) {
      setAlert(response.error, "error");
      setShowModal(false);
      setIsLoading(false);
    } else {
      const divElement = editor.dom.create("div", {
        contenteditable: "false",
        class: "para-but",
        style:
          "display:flex;gap:10px;background-color: cornflowerblue;color: white;width: fit-content;padding: 5px;margin: 2px;border-radius: 6px;",
      });

      const divElement1 = editor.dom.create(
        "div",
        {
          style: "",
        },
        `${response.name}`
      );
      divElement.appendChild(divElement1);

      const buttonElement = editor.dom.create(
        "button",
        {
          "data-id": `${response.id}`,
          class: "del-para",
        },
        "x"
      );

      // Append the button to the div
      divElement.appendChild(buttonElement);

      if (editor.dom.getParent(editor.selection.getNode(), "table")) {
        // console.log("INNNN TABBLLLLEEE");
        const tableElement = editor.dom.getParent(
          editor.selection.getNode(),
          "table"
        );
        editor.dom.insertAfter(divElement, tableElement);
      } else {
        let rng = editor.selection.getRng(); // Get the current selection range
        rng.collapse(false); // Collapse the range to its end point to move cursor after the selected text

        editor.selection.setRng(rng); // Set the new range
        editor.dom.add(editor.getBody(), divElement); // Insert the divElement at the new cursor position

        // move the cursor after the inserted divElement
        rng.setStartAfter(divElement);
        rng.setEndAfter(divElement);
        editor.selection.setRng(rng);
      }

      handlePublish(1);
    }
  };

  const replaceText = (text) => {
    let editDom = editor.getBody();

    // // Find the first element containing the text "/*"
    let elements = Array.from(editDom.getElementsByTagName("*"));
    let targetElement = elements.find((el) => el.textContent.includes("/*"));

    // console.log("targetElement", targetElement);

    if (targetElement) {
      targetElement.innerHTML = targetElement.innerHTML.replace("/*", text);
    }
    editor.fire("KeyUp", {
      key: "Enter",
    });
  };

  return (
    <>
      <Dialog open={openKanbanPopup} onClose={handleClose}>
        <DialogTitle>Select a parameter to create Kanban board</DialogTitle>
        <DialogContent>
          <List>
            {customWidgets
              .filter((widget) => widget.level === 0)
              .map((widget) => (
                <ListItem
                  button
                  key={widget.id}
                  onClick={() => {
                    createChart("kanban", widget.id);
                    setOpenKanbanPopup(false);
                  }}
                  sx={{ "&:hover": { backgroundColor: "lightgray" } }} // Hover style
                >
                  <ListItemText primary={widget.name} />
                </ListItem>
              ))}
          </List>

          <Button onClick={handleClose}>Close</Button>
        </DialogContent>
      </Dialog>

      {showReport && (
        <ReportBuilder
          users={users}
          handlePublish={handlePublish}
          setIsLoading={setIsLoading}
          pageid={pageid}
          customWidgets={customWidgets}
          replaceText={replaceText}
          setShowReport={setShowReport}
        />
      )}

      <PopupComponent
        heading={"Add parameter"}
        fields={["text"]}
        placeholders={["Enter parameter name"]}
        labels={["Parameter name"]}
        values={[]}
        buttonLabel={"Save"}
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        onValueChange={onValueChange}
        buttonAction={onSubmit}
        errors={[]}
      />

      <PopupComponent
        heading={"Do you want to send a mail to the user?"}
        fields={[]}
        placeholders={[]}
        labels={[]}
        values={[]}
        buttonLabel={"Yes"}
        showModal={showMentionEmailModal}
        handleCloseModal={() => setShowMentionEmailModal(false)}
        onValueChange={""}
        buttonAction={onSubmitMentionEmail}
        errors={[]}
      />

      <Grid container className="mb-3 ml-2">
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            boxShadow="0px 3px 3px -2px rgba(0,0,0,0.2)" // shadow only at the bottom
            marginBottom={2}
          >
            <TextField
              placeholder="Give this page a title"
              value={title}
              onChange={(event) => {
                if (event.target.value.length < 50) {
                  setTitle(event.target.value);
                }
              }}
              multiline
              fullWidth
              variant="standard"
              InputProps={{ disableUnderline: true }}
              sx={{
                border: 0,
                overflowY: "auto",
                minHeight: 48, // adjusted height
                maxHeight: 100,
                flexGrow: 1,
                padding: 0,
                "& .MuiInputBase-inputMultiline": {
                  padding: "8px 0", // adjusted padding for vertical centering
                  fontSize: "2.0714285714285716em",
                  lineHeight: "0.5",
                  fontWeight: 700,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {currentAuthor && (
            <Editor
              apiKey="lwuj26i6isqrpnark1w4uigrnk7d60mer0vdkwhwhfudw0jo"
              init={{
                placeholder: "Type your content here...",
                extended_valid_elements: "button[onclick|class|data-id]",
                setup: (editor) => {
                  editor.on("KeyUp", function (event) {
                    checkPattern(editor);
                  });

                  // TODO: DON"T ALLOW DELETE SCRIPT < REF  < CUSTOM WIDGET

                  //   editor.on("keydown", function (e) {
                  //     if (e.keyCode !== 8 && e.keyCode !== 46) {
                  //         // If the key pressed isn't Backspace or Delete, we don't need to do anything.
                  //         return;
                  //     }

                  //     const protectedClasses = ["chart", "custom-widget", "script-but", "para-but"];
                  //     const rng = editor.selection.getRng();

                  //     // If range isn't collapsed, meaning something is selected, we should check for protected elements in selection.
                  //     if (!rng.collapsed) {
                  //         const allElementsInRange = editor.dom.select("*");
                  //         const filteredElements = allElementsInRange.filter((el) => rng.intersectsNode(el));

                  //         for (let el of filteredElements) {
                  //             for (let protectedClass of protectedClasses) {
                  //                 if (editor.dom.hasClass(el, protectedClass)) {
                  //                     console.log(`ELEMENT WITH CLASS ${protectedClass} IS PARTIALLY OR FULLY SELECTED`);
                  //                     e.preventDefault();
                  //                     return false;
                  //                 }
                  //             }
                  //         }
                  //     }

                  //     // Check for caret position relative to protected elements.
                  //     const caretNode = rng.startContainer;

                  //     if (e.keyCode === 8 && caretNode.nodeType === 3 && caretNode.nodeValue && rng.startOffset === 0) { // Backspace at the beginning of a text node.
                  //         let prevElement = caretNode.previousSibling || caretNode.parentElement.previousElementSibling;
                  //         for (let protectedClass of protectedClasses) {
                  //             if (prevElement && editor.dom.hasClass(prevElement, protectedClass)) {
                  //                 console.log(`PREVIOUS ELEMENT HAS CLASS ${protectedClass}`);
                  //                 e.preventDefault();
                  //                 return false;
                  //             }
                  //         }
                  //     } else if (e.keyCode === 46 && caretNode.nodeType === 3 && caretNode.nodeValue && rng.startOffset === caretNode.nodeValue.length) { // Delete at the end of a text node.
                  //         let nextElement = caretNode.nextSibling || caretNode.parentElement.nextElementSibling;
                  //         for (let protectedClass of protectedClasses) {
                  //             if (nextElement && editor.dom.hasClass(nextElement, protectedClass)) {
                  //                 console.log(`NEXT ELEMENT HAS CLASS ${protectedClass}`);
                  //                 e.preventDefault();
                  //                 return false;
                  //             }
                  //         }
                  //     }
                  // });

                  editor.on("dblclick", function (event) {
                    const clickedElement = event.target;
                    // console.log("DB CLICKKKK", clickedElement);
                    if (clickedElement.classList.contains("script-link")) {
                      window.open(clickedElement.href, "_blank");
                    }
                  });

                  editor.on("NodeChange", function (event) {
                    // positionButtonAboveCursor(editor);

                    if (event.element.nodeName === "TD") {
                      const tableElement = editor.dom.getParent(
                        event.element,
                        "TABLE"
                      ); // This will get the closest parent TABLE element
                      if (
                        tableElement &&
                        !editor.dom.getAttrib(tableElement, "id")
                      ) {
                        // console.log("CREATING TABLEEEE");
                        const uniqueID = generateUniqueID(); // your function to generate a unique ID
                        editor.dom.setAttrib(tableElement, "id", uniqueID);
                      }
                    }
                  });

                  editor.ui.registry.addButton("tableParamButton", {
                    text: "Add parameter",
                    icon: "plus",
                    onAction: function () {
                      if (!pageid) {
                        setAlert("Publish page first.", "info");
                        return;
                      }
                      const selectedNode = editor.selection.getNode();
                      const tableElement = editor.dom.getParent(
                        selectedNode,
                        "table"
                      );

                      if (tableElement) {
                        const tableId = tableElement.getAttribute("id");
                        setSelectedId(tableId);
                        // console.log(tableId);
                      } else {
                        console.warn("No table selected!");
                      }
                      setButtonSource("tableParamButton");
                      setShowModal(true);
                    },
                  });

                  editor.ui.registry.addButton("tableCopyButton", {
                    text: "Copy",
                    icon: "copy",
                    onAction: function () {
                      const selectedNode = editor.selection.getNode();
                      const tableElement = editor.dom.getParent(
                        selectedNode,
                        "table"
                      );
                      if (tableElement) {
                        const tableHTML = tableElement.outerHTML;
                        copyToClipboard(tableHTML);
                        setAlert(
                          "Copied to clipboard. Ctrl + V in the script page to paste.",
                          "info"
                        );
                      } else {
                        console.warn("No table selected!");
                      }
                    },
                  });

                  editor.ui.registry.addButton("paramButton", {
                    text: "Add parameter",
                    icon: "plus",
                    onAction: function () {
                      if (!pageid) {
                        setAlert("Publish page first.", "info");
                        return;
                      }
                      const content = editor.selection.getContent(); // Get the selected HTML content
                      setSelectedHTML(content); // Save the content to the state

                      setButtonSource("paramButton");
                      setShowModal(true);
                    },
                  });
                },
                height: 500,
                menubar: false,
                // inline: true,
                quickbars_selection_toolbar:
                  "bold italic underline | paramButton",
                table_toolbar:
                  "tableParamButton | tableCopyButton | tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                plugins:
                  "tableofcontents mentions quickbars link uploadimage autolink charmap codesample emoticons image lists checklist searchreplace table visualblocks wordcount superscript subscript textcolor colorpicker hr paste pageembed media tinycomments",
                selector: "textarea",

                toc_header: "div",
                menu: {
                  tc: {
                    title: "Comments",
                    items: "addcomment showcomments deleteallconversations",
                  },
                },
                tinycomments_mode: "embedded",
                tinycomments_author: currentAuthor,
                tinycomments_can_resolve: function (req, done, fail) {
                  var allowed =
                    req.comments.length > 0 &&
                    req.comments[0].author === currentAuthor;
                  done({
                    canResolve:
                      allowed || currentAuthor === userAllowedToResolve,
                  });
                },
                toolbar:
                  "undo redo | blocks | formatselect | bold italic underline strikethrough  code subscript superscript | align |\
 | link image media table | forecolor backcolor | bullist numlist outdent indent | checklist | emoticons charmap | showcomments",

                //
                images_upload_url: IMAGE_UPLOAD_URL,
                content_style:
                  ".mymention{ color: gray; }" +
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                mentions_selector: ".mymention",
                mentions_fetch: mentions_fetch,
                // mentions_menu_hover: mentions_menu_hover,
                mentions_menu_complete: mentions_menu_complete,
                mentions_select: mentions_select,
                mentions_item_type: "profile",
                //
              }}
              onInit={(evt, editor) => {
                // editor.execCommand('ToggleSidebar', false, 'comments');
                setEditorLoaded(true);
                setEditor(editor);
                setIsLoading(false);
              }}
              value={content}
              onEditorChange={handleEditorChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EditorBottom;
