import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from '@mui/material';
import LandingPageSection1 from "../components/LandingPageSection1";
import LandingPageSection2 from "../components/LandingPageSection2";
import LandingPageSection3 from "../components/LandingPageSection3";
import LandingPageSection4 from "../components/LandingPageSection4";

function LandingPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsLoading(false), 5000); // 5 seconds, adjust as needed
    window.onload = () => {
      clearTimeout(timeoutId);
      setIsLoading(false);
    };
  
    return () => {
      clearTimeout(timeoutId);
      window.onload = null;
    };
  }, []);
  
  
  

  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <LandingPageSection1 />
          {/* <LandingPageSection2 /> */}
          {/* <LandingPageSection3 /> */}
          <LandingPageSection4 />
        </>
      )}
    </div>
  );
}

export default LandingPage;
