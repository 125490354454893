import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SendRequest } from "../api";

import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  Chip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ReferenceDropdownComponent({
  setSelectedPagesLoaded,
  selectedPages,
  setSelectedPages,
  pages,
  setPages,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { id, pageid } = useParams();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await SendRequest(
          `/api/getpages/${id}/${pageid}/`,
          "GET"
        );

        if (response) {
          if (response.pages && response.pages.length > 0) {
            setPages(response.pages);
          }

          if (response.selected && response.selected.length > 0) {
            setSelectedPages(response.selected);
            setSelectedPagesLoaded(true);
          }
        }
      } catch (error) {
        console.error("Error fetching pages:", error);
        setSelectedPagesLoaded(true);
      }
    };
    if (pageid) {
      fetchPages();
    }
  }, [id, pageid]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePageSelect = (page) => {
    if (!selectedPages.some((selectedPage) => selectedPage.id === page.id)) {
      setSelectedPages([...selectedPages, page]);
    } else {
      setSelectedPages(
        selectedPages.filter((selectedPage) => selectedPage.id !== page.id)
      );
    }
  };

  const removePage = (event, pageId) => {
    event.stopPropagation();
    setSelectedPages(selectedPages.filter((page) => page.id !== pageId));
  };

  return (
    <Box
      onClick={toggleDropdown}
      sx={{ position: "relative", cursor: "pointer" }}
      className="custom-dropdown"
    >
      <Box className="selected-status">
        {selectedPages.length > 0 ? (
          selectedPages.map((page) => (
            <Chip
              key={page.id}
              label={page.name}
              onDelete={(event) => {
                event.stopPropagation();
                removePage(event, page.id);
              }}
              color="primary"
              sx={{ mr: 1, mb: 1 }}
            />
          ))
        ) : (
          <Typography variant="body1">Select Reference Pages</Typography>
        )}
      </Box>

      {isOpen && (
        <List
          component="ul"
          sx={{
            mt: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
            maxHeight: 300,
            overflowY: "auto",
            position: "absolute",
            background: "#ffff",
            zIndex: 100,
          }}
        >
          {(() => {
            if (pages.length === 0 && typeof pageid === "undefined") {
              return (
                <ListItem>
                  <Typography>Publish page to see reference pages</Typography>
                </ListItem>
              );
            } else if (typeof pageid !== "undefined" && pages.length === 0) {
              return (
                <ListItem>
                  <Typography>No pages</Typography>
                </ListItem>
              );
            } else {
              return pages
                .filter(
                  (page) =>
                    !selectedPages.some((selected) => selected.id === page.id)
                )
                .map((page) => (
                  <ListItem
                    key={page.id}
                    button
                    onClick={() => handlePageSelect(page)}
                  >
                    <Typography>{page.name}</Typography>
                  </ListItem>
                ));
            }
          })()}
        </List>
      )}
    </Box>
  );
}

export default ReferenceDropdownComponent;
