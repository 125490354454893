import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

function OutputTableComponent({ status, isLoading, setIsLoading }) {
  const [error, setError] = useState([]);
  const [output, setOutput] = useState([]);
  const { scriptid } = useParams();

  useEffect(() => {
    const fetchPageData = async () => {
      setIsLoading(true);

      const response = await SendRequest(
        `/api/scriptentries/${scriptid}/`,
        "GET"
      );
      if (status === "3") {
        setError(response.error);
      } else {
        setOutput(response.output);
      }
      setIsLoading(false);
    };

    if (status === "3" || status === "2") {
      fetchPageData();
    }
  }, [status]);

  return (
    <Box className="script-right-in">
      {error && status === "3" && (
        <Box className="error-div">
          <Typography variant="h6" className="error-tit">
            :ERROR:
          </Typography>
          <Typography variant="body1" className="error-cont-out">
            {error}
          </Typography>
        </Box>
      )}
      {output.length === 0 ? (
        <Typography variant="h6" className="no-data">
          No output
        </Typography>
      ) : (
        output.map((o, oIndex) => (
          <Box key={oIndex}>
            <Typography variant="body2" className="out-created">
              {o.created_date}
            </Typography>
            <Box
              className="script-table-container"
              style={{
                maxWidth: "880px",
                maxHeight: "400px",
                overflow: "auto",
              }}
            >
              <Table className="script-table">
                {o.table.output && (
                  <>
                    <TableHead className="script-table-head">
                      <TableRow className="script-table-tr-th">
                        {o.table.output[0].map((headerCell, headerIndex) => (
                          <TableCell
                            className="script-table-th"
                            key={headerIndex}
                          >
                            {headerCell}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {o.table.output.slice(1).map((row, rowIndex) => (
                        <TableRow key={rowIndex} className="script-table-tr">
                          {row.map((cell, cellIndex) => (
                            <TableCell
                              className="script-table-td1"
                              key={cellIndex}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                )}
              </Table>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
}

export default OutputTableComponent;
