import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Box, Typography, CircularProgress } from "@mui/material";

function SubDomainsVisibilityPage() {
  const [loading, setLoading] = useState(true);
  const [subData, setSubData] = useState([]);
  const [rootData, setRootData] = useState([]);

  const { subdomainid } = useParams();

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [hiddenFolders, setHiddenFolders] = useState([]);
  const [hiddenPages, setHiddenPages] = useState([]);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
  };

  const handleFolderHide = (folder) => {
    if (!hiddenFolders.includes(folder)) {
      setHiddenFolders((prevState) => [...prevState, folder]);
    } else {
      setHiddenFolders((prevState) =>
        prevState.filter((f) => f.id !== folder.id)
      );
    }
  };

  const handlePageHide = (page) => {
    if (!hiddenPages.includes(page)) {
      setHiddenPages((prevState) => [...prevState, page]);
    } else {
      setHiddenPages((prevState) => prevState.filter((p) => p.id !== page.id));
    }
  };

  const saveData = async () => {
    try {
      setLoading(true);

      // Construct the data based on visible folders and pages
      let data = rootData
        .map((project) => {
          return project.folders
            .filter((folder) => !hiddenFolders.includes(folder))
            .map((folder) => {
              return {
                folder: folder.id,
                pages: folder.pages
                  .filter((page) => !hiddenPages.includes(page))
                  .map((page) => {
                    return { page: page.id };
                  }),
              };
            });
        })
        .flat();

      const dat1 = { data: data, subdomain: subdomainid };

      const response = await SendRequest(`/api/saverootdata/`, "POST", dat1);
      // console.log(response);
      //   if (response.results) {
      //     setSubDomains(response.results);
      //   }
    } catch (error) {
      console.error("Error fetching the data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [response, response1] = await Promise.all([
          SendRequest(`/api/subdomains/${subdomainid}/`, "GET"),
          SendRequest(`/api/getrootprojects/`, "GET"),
        ]);

        if (response && response1) {
          setSubData(response.main_folders);
          setRootData(response1);

          // Extract all folders and pages from rootData
          let allRootFolders = [];
          let allRootPages = [];
          response1.forEach((project) => {
            allRootFolders = allRootFolders.concat(project.folders);
            project.folders.forEach((folder) => {
              allRootPages = allRootPages.concat(folder.pages);
            });
          });

          // Extract folder IDs from subData
          const visibleFolderIds = response.main_folders.map(folder => folder.id);

          // Extract pages from subData
          let visiblePages = [];
          response.main_folders.forEach((folder) => {
            visiblePages = visiblePages.concat(folder.pages);
          });

          // Filter out the folders and pages present in subData from allRootFolders and allRootPages to identify which are hidden
          const hiddenRootFolders = allRootFolders.filter(
            (rootFolder) => !visibleFolderIds.includes(rootFolder.id)
          );

          const hiddenRootPages = allRootPages.filter(
            (rootPage) =>
              !visiblePages.some((visiblePage) => visiblePage.id === rootPage.id)
          );

          setHiddenFolders(hiddenRootFolders);
          setHiddenPages(hiddenRootPages);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);




  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button
            sx={{ marginLeft: "20px", marginTop: "20px" }}
            onClick={saveData}
          >
            Save
          </Button>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                flex: "0 0 30%",
                borderRight: "1px solid gray",
                padding: "20px",
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                Projects
              </Typography>
              {rootData.map((project) => (
                <Typography
                  className="click-vis"
                  key={project.id}
                  onClick={() => {
                    setSelectedProject(project);
                    setSelectedFolder(null);
                  }}
                >
                  {project.name}
                </Typography>
              ))}
            </Box>

            <Box sx={{ flex: "1", display: "flex", padding: "20px" }}>
              {selectedProject && (
                <>
                  <Box
                    sx={{
                      flex: "0 0 50%",
                      borderRight: "1px solid gray",
                      padding: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: "20px" }}>
                      Folders in {selectedProject.name}
                    </Typography>
                    {selectedProject.folders.map(
                      (folder) =>
                        // This condition ensures we don't render hidden folders in the regular section
                        !hiddenFolders.includes(folder) && (
                          <Box key={folder.id}>
                            <Typography
                              className="click-vis"
                              onClick={() => handleFolderClick(folder)}
                            >
                              <input
                                className="check-visib"
                                type="checkbox"
                                checked={hiddenFolders.includes(folder)}
                                onChange={() => handleFolderHide(folder)}
                              />
                              {folder.name}
                            </Typography>
                          </Box>
                        )
                    )}
                  </Box>

                  <Box sx={{ flex: "1", padding: "20px" }}>
                    {selectedFolder && (
                      <>
                        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
                          Pages in {selectedFolder.name}
                        </Typography>
                        {selectedFolder.pages.map(
                          (page) =>
                            // This condition ensures we don't render hidden pages in the regular section
                            !hiddenPages.includes(page) && (
                              <Typography key={page.id} className="click-vis">
                                <input
                                  type="checkbox"
                                  className="check-visib"
                                  checked={hiddenPages.includes(page)}
                                  onChange={() => handlePageHide(page)}
                                />
                                {page.name}
                              </Typography>
                            )
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}

      <Box sx={{ display: "flex", gap: "20px" }}>
        {hiddenFolders.length > 0 && (
          <Box sx={{ width: "50%" }}>
            <Typography variant="h6" sx={{ marginBottom: "20px" }}>
              Hidden Folders
            </Typography>
            {hiddenFolders.map((folder) => (
              <Box key={folder.id} className="click-vis">
                <Typography>
                  <input
                    className="check-visib"
                    type="checkbox"
                    checked={true}
                    onChange={() => handleFolderHide(folder)} // Reusing the hide function to unhide
                  />
                  {folder.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}

        {hiddenPages.length > 0 && (
          <Box sx={{ width: "50%" }}>
            <Typography variant="h6" sx={{ marginBottom: "20px" }}>
              Hidden Pages
            </Typography>
            {hiddenPages.map((page) => (
              <Typography key={page.id} className="click-vis">
                <input
                  className="check-visib"
                  type="checkbox"
                  checked={true}
                  onChange={() => handlePageHide(page)} // Reusing the hide function to unhide
                />
                {page.name}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SubDomainsVisibilityPage;
