import { React, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import "../css/features.css";

// Component for individual feature cards in Section 2
function FeatureCard({ imageUrl, title, text }) {
  return (
    <div style={{ padding: "16px" }}>
      <img
        src={imageUrl}
        alt={title}
        style={{ width: "175px", marginBottom: "16px", margin: "0 auto" }}
      />
      <Typography
        variant="h4"
        style={{
          fontSize: "1.5rem",
          lineHeight: "1.33333333",
          marginBottom: "16px",
        }}
      >
        {title}
      </Typography>
      <Typography style={{ fontSize: "1rem", lineHeight: "1.5" }}>
        {text}
      </Typography>
    </div>
  );
}

function FeatureCard2({ imageUrl, title, text, linktext }) {
  return (
    <div style={{ padding: "16px" }}>
      <img
        src={imageUrl}
        alt={title}
        style={{ width: "100%", marginBottom: "32px", margin: "0 auto" }}
      />
      <Typography
        variant="h4"
        style={{
          fontSize: "1.4rem",
          lineHeight: "1.33333333",
          marginBottom: "16px",
          fontWeight: "500",
          height: "55px",
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{ fontSize: "1rem", lineHeight: "1.5", marginBottom: "16px" }}
      >
        {text}
      </Typography>
      <Button style={{ fontSize: "1rem", lineHeight: "1.5" }}>
        {linktext}
      </Button>
    </div>
  );
}

// Component for feature sections with image, title, subheadings, texts, and a link in Section 3
function FeatureSection({
  type,
  imageUrl,
  title,
  subheadings,
  texts,
  linkText,
  review,
  name,
  desig,
}) {
  return (
    <>
      <div className="feat-sec3">
        {type === "0" ? (
          <>
            <div className="feat-sec3-in">
              <img
                src={imageUrl}
                alt={title}
                style={{ width: "100%", margin: "0 auto" }}
              />
            </div>
            <div  className="feat-sec3-in">
              <Typography variant="h4" className="feat-sec3-head">
                {title}
              </Typography>
              {subheadings.map((subheading, index) => (
                <div key={index}>
                  <Typography variant="h5" className="feat-sec3-subhead">
                    {subheading}
                  </Typography>
                  <Typography className="feat-sec3-text">
                    {texts[index]}
                  </Typography>
                </div>
              ))}
              <Button variant="contained" color="primary">
                {linkText}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="feat-sec3-in">
              <Typography variant="h4" className="feat-sec3-head">
                {title}
              </Typography>
              {subheadings.map((subheading, index) => (
                <div key={index}>
                  <Typography variant="h5" className="feat-sec3-subhead">
                    {subheading}
                  </Typography>
                  <Typography className="feat-sec3-text">
                    {texts[index]}
                  </Typography>
                </div>
              ))}
              <Button variant="contained" color="primary">
                {linkText}
              </Button>
            </div>
            <div  className="feat-sec3-in">
              <img
                src={imageUrl}
                alt={title}
                style={{ width: "100%", margin: "0 auto" }}
              />
            </div>
          </>
        )}

        {/* Common Section for Both Types */}
        <div></div>
      </div>
      <div className="feat-sec3-rev">
        <div>
          <img
            className="feat-sec3-rev-img"
            src="https://wac-cdn.atlassian.com/dam/jcr:50a7e354-d354-465d-8d4f-74964c5af46d/Quote-blue.svg?cdnVersion=1186"
          />
        </div>
        <div>
          <div className="feat-sec3-rev-cont">{review}</div>
          <div className="feat-sec3-rev-name">{name}</div>
          <div className="feat-sec3-rev-des">{desig}</div>
        </div>
      </div>
    </>
  );
}

function FeaturesPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsLoading(false), 5000); // 5 seconds, adjust as needed
    window.onload = () => {
      clearTimeout(timeoutId);
      setIsLoading(false);
    };

    return () => {
      clearTimeout(timeoutId);
      window.onload = null;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {/* Section 1 */}
          <section className="feat-section1">
            <Container>
              <Typography
                variant="h1"
                style={{
                  margin: "auto",
                  fontSize: "3rem",
                  lineHeight: "3.5rem",
                  marginBottom: "24px",
                  color: "#253858",
                  textAlign: "center",
                  fontWeight: "500",
                  maxWidth: "1100px",
                }}
              >
                Features for knowledge management & project collaboration
              </Typography>
              <Typography
                style={{
                  color: "#42526e",
                  fontSize: "1.25rem",
                  letterSpacing: ".3px",
                  lineHeight: "1.4",
                  textAlign: "center",
                  marginBottom: "24px",
                }}
              >
                Accpro is your organization’s single source of truth.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ display: "flex", margin: "auto" }}
              >
                Get it for free
              </Button>
            </Container>
          </section>

          {/* Section 2 */}
          <section className="section2">
            <div className="section2-in">
              <Container>
                <Typography variant="h2" className="feat-head2">
                  Intuitive structure makes setup, creation, and discovery easy
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FeatureCard
                      imageUrl="https://wac-cdn.atlassian.com/dam/jcr:ab60e153-af9f-4dec-a28c-c701a7e51f7e/Pages.svg?cdnVersion=1186"
                      title="Pages"
                      text="Pages are documents where people create, edit, and discuss their work. "
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FeatureCard
                      imageUrl="https://wac-cdn.atlassian.com/dam/jcr:0907d993-bcc9-4726-a6e8-70a1bf04f511/Whiteboards_Icon.svg?cdnVersion=1186"
                      title="Whiteboards"
                      text="Whiteboards are canvases where teams can visualize work"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FeatureCard
                      imageUrl="https://wac-cdn.atlassian.com/dam/jcr:50ab7a1f-c12e-4514-b7f8-9bc29dd77ab4/Spaces.svg?cdnVersion=1186"
                      title="Spaces"
                      text="Spaces are areas that contain pages for individuals, teams, and strategic projects. "
                    />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </section>

          {/* Section 3 */}
          <section className="feat-section3">
            <Container style={{ maxWidth: "1400px" }}>
              <FeatureSection
                type="0"
                imageUrl="https://wac-cdn.atlassian.com/dam/jcr:2bee924a-d529-4695-8b66-163d8d6ce62e/Brainstorming_2x.png?cdnVersion=1186"
                title="Brainstorm ideas and visualize work with whiteboards"
                subheadings={["Sticky notes", "Convert to Jira", "Smart links"]}
                texts={[
                  "Run engaging team meetings with shapes, votes, timers, and more, all within your single source of truth",
                  "Automatically turn your sticky notes into Jira issues with the click of a button to move work along faster",
                  "Embed content from other tools to bring all your work together in one view and reduce information silos",
                ]}
                linkText="Get it for free"
                review="Accpro is a daily tool for our company. I trust that any notes, documentation…that go into Accpro will be visible to everyone on our team."
                name="Brooke Purvis"
                desig="Product Manager, Crema"
              />

              <FeatureSection
                type="1"
                imageUrl="https://wac-cdn.atlassian.com/dam/jcr:15412aef-4cad-4d08-a579-5a841e17ea6d/Share_2x.png?cdnVersion=1186"
                title="Create, collaborate, and comment on pages & project plans"
                subheadings={[
                  "Permissions",
                  "Home & personalized feed",
                  "Announcements and blogs",
                ]}
                texts={[
                  "Keep employees informed with open access to information they need, but share and protect sensitive content with permission settings",
                  "Get back to recent spaces, in-progress work, and drafts and stay updated with activity and popular feeds on your personal homepage",
                  "Spread the latest news and bring everyone together with blogs that encourage transparency and inclusion",
                ]}
                linkText="Get it for free"
                review="If there’s something you need to know or want others to know, it goes on Accpro."
                name="Katie Burke"
                desig="Chief People Officer, HubSpot"
              />

              <FeatureSection
                type="0"
                imageUrl="https://wac-cdn.atlassian.com/dam/jcr:2bee924a-d529-4695-8b66-163d8d6ce62e/Brainstorming_2x.png?cdnVersion=1186"
                title="Share information between teams and the entire company"
                subheadings={["Sticky notes", "Convert to Jira", "Smart links"]}
                texts={[
                  "Run engaging team meetings with shapes, votes, timers, and more, all within your single source of truth",
                  "Automatically turn your sticky notes into Jira issues with the click of a button to move work along faster",
                  "Embed content from other tools to bring all your work together in one view and reduce information silos",
                ]}
                linkText="Get it for free"
                review="Accpro is a daily tool for our company. I trust that any notes, documentation…that go into Accpro will be visible to everyone on our team."
                name="Brooke Purvis"
                desig="Product Manager, Crema"
              />

              <FeatureSection
                type="0"
                imageUrl="https://wac-cdn.atlassian.com/dam/jcr:371da7f0-40c9-4eb0-9f03-46aa48265e55/Build_2x.png?cdnVersion=1186"
                title="Build a knowledge base for documentation & product requirements"
                subheadings={[
                  "Advanced search & content tree",
                  "Best practice templates",
                  "Jira integration",
                ]}
                texts={[
                  "Organize and find pages easily with advanced search, labels, and an intuitive content hierarchy",
                  "Start with a blank page or choose from over 75 customizable templates for every team–from strategy and planning docs to reports",
                  "Connect plans to development work with powerful Jira integrations including issue-tracking and dynamically updating roadmaps",
                ]}
                linkText="Get it for free"
                review="Solve a documentation mess...Accpro is our linchpin for everything."
                name="Evan Lerer"
                desig="Dir. of Engineering, Redfin"
              />
            </Container>
          </section>
          {/* Section 3 */}
          <section className="feat-section4">
            <Typography variant="h2" className="feat-head2">
              Scale with confidence and security
            </Typography>
            <Typography
              className="feat-sec3-text"
              style={{
                textAlign: "center",
                maxWidth: "600px",
                margin: "auto",
                paddingBottom: "64px",
              }}
            >
              Get peace of mind with enterprise-grade solutions for scaling
              Accpro across your entire organization.
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard2
                  imageUrl="https://wac-cdn.atlassian.com/dam/jcr:98fc7cc4-78eb-46a6-9bf0-87f2fe27ef37/Privacy@2x.png?cdnVersion=1186"
                  title="Privacy, Encryption & Compliance"
                  text="GDPR and Privacy Shield compliant using encryption in transit and at rest to protect your team’s data. Verify security with SOC2, SOC3, ISO 27001, ISO 27018, PCI DSS, and more."
                  linktext="Explore"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard2
                  imageUrl="https://wac-cdn.atlassian.com/dam/jcr:b8bfefbd-527c-4e6f-b980-566f57b5bbfa/CONMKT-1978%20Confluence%20Features%20Page%20with%20Access%20DRD.png?cdnVersion=1186"
                  title="Atlassian Access"
                  text="Get enhanced administration and security across your Atlassian products with SAML SSO, enforced 2-step verification, automated user provisioning, and more."
                  linktext="Read more"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard2
                  imageUrl="https://wac-cdn.atlassian.com/dam/jcr:1096e243-0dd6-4c5e-9c3f-45ea4efff526/CONMKT-1978%20Confluence%20Features%20Page%20with%20Premium%20DRD.png?cdnVersion=1186"
                  title="Accpro Cloud Premium"
                  text="More powerful Accpro capabilities for your growing team with analytics-driven insights, advanced admin controls, unlimited storage, a 99.9% uptime SLA, 24/7 Premium support and more."
                  linktext="Read more"
                />
              </Grid>
            </Grid>
          </section>

          <section className="feat-section5">
            <Typography className="feat-sec5-h2">
              Make quick decisions, gain alignment, and transform how your team
              works together.
            </Typography>
            <Button style={{ fontSize: "1rem", lineHeight: "1.5" }}>
              get it free
            </Button>
          </section>
        </div>
      )}
    </>
  );
}

export default FeaturesPage;
