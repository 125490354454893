import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/script.css";
import { SendRequest } from "../api";
import DeleteSvg from "../svg/delete";
import Papa from "papaparse";
import Websocket from "react-websocket";
import Cookies from "js-cookie";
import {
  Button,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Input,
  Divider,
  CircularProgress,
} from "@mui/material";

import AlertContext from "../components/AlertContext";
import ScriptComponent from "../components/ScriptComponent";
import OutputComponent from "../components/OutputComponent";
import OutputTableComponent from "../components/OutputTableComponent";
import ScreenCaptureComponent from "../components/ScreenCaptureComponent";


function ScriptPage({ isAdmin }) {
  // const SOCKET_URL = "ws://127.0.0.1:8000/ws/test/";
  const SOCKET_URL = "wss://accpro-backend.up.railway.app/ws/test/";

  const token = Cookies.get("token");
  const SOCKET_URL_WITH_TOKEN = `${SOCKET_URL}?token=${token}&type=0`;

  const { setAlert } = useContext(AlertContext);

  const { scriptid } = useParams();
  const [data, setData] = useState([]);
  const [fields, setFields] = useState([]);
  const [workerStatus, setWorkerStatus] = useState(-1);
  const [statusText, setStatusText] = useState("Run");
  const [view, setView] = useState("0");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isScreenCaptureEnabled, setScreenCaptureEnabled] = useState(false);
  const [isTestModeEnabled, setTestModeEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fieldUpdate, setFieldUpdate] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (fieldUpdate) {
      saveData(1);
    }
  }, [fieldUpdate]);


  const handleInputChange = (e, rowIndex, cellIndex) => {
    // Clone the current data
    const updatedData = [...data];

    // Update the specific cell with the new value
    updatedData[rowIndex][cellIndex] = e.target.value;

    // Update the state
    setData(updatedData);
  };

  const addNewRow = () => {
    const updatedData = [...data];
    // console.log(updatedData);
    if (
      fields &&
      updatedData.length > 0 &&
      updatedData[updatedData.length - 1].every((str) => str === "")
    ) {
      // Last list in updatedData consists of all empty strings
      setTimeout(() => {
        // console.log("Koooiiii->>>>",inputRefs.current)
        const lastRowIndex = updatedData.length - 1;
        const index = lastRowIndex * fields.length;
        if (inputRefs.current[index]) {
          inputRefs.current[index].focus();
        }
      }, 0);
      return;
    }
    // console.log(updatedData)
    if (fields.length > 0) {
      const emptyStringsArray = new Array(fields.length).fill("");
      updatedData.push(emptyStringsArray);
      setData(updatedData);

      setTimeout(() => {
        // console.log("Koooiiii->>>>",inputRefs.current)
        const lastRowIndex = updatedData.length - 1;
        const index = lastRowIndex * fields.length;
        if (inputRefs.current[index]) {
          inputRefs.current[index].focus();
        }
      }, 0);
    } else {
      setAlert("Add some fields.", "error");
    }
  };

  const deleteRow = (e, rowIndexOrIndices) => {
    // If rowIndexOrIndices is a number, wrap it in an array
    const indicesToDelete = Array.isArray(rowIndexOrIndices)
      ? rowIndexOrIndices
      : [rowIndexOrIndices];

    const updatedData = data.filter(
      (_, index) => !indicesToDelete.includes(index)
    );

    setData(updatedData);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (isAdmin) {
      parseCSV(file);
      return;
    }
    if (fields.length > 0) {
      parseCSV(file);
    } else {
      setAlert("Add some fields.", "error");
    }
  };

  const runTest = async () => {
    if (workerStatus === 0) {
      return; // do nothing
    } else if (workerStatus === 1) {
      setIsLoading(true);
      const response = await SendRequest(
        `/api/stop_worker/${scriptid}/`,
        "POST"
      );
      if (response.error) {
        setAlert("Error cancelling: " + response.error, "error");
      } else {
        setWorkerStatus(3);
        setStatusText("Error : Rerun");
      }
      setIsLoading(false);
      return;
    }
    if (fields.length < 1) {
      setAlert("Add some fields.", "error");
      return;
    }
    setIsLoading(true);
    let requestData = {};
    if (selectedRows && selectedRows.length > 0) {
      requestData.selectedData = selectedRows.map((index) => data[index]);
    }
    requestData.screen_capture = isScreenCaptureEnabled;
    requestData.test_mode = isTestModeEnabled;

    const response = await SendRequest(
      `/api/create_worker/${scriptid}/`,
      "POST",
      requestData
    );
    // console.log(response);
    if (response.error) {
      setAlert("Error running: " + response.error, "error");
    } else {
      setWorkerStatus(0);
      setStatusText("Queued");
    }
    setIsLoading(false);
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        // console.log("Parsed Result: ", result);

        // 1. Validate the data
        const csvHeaders = result.meta.fields; // This gives headers from parsed CSV
        const fieldNames = fields.map((field) => field.name); // Extracting names from fields array
        const missingFields = fieldNames.filter(
          (name) => !csvHeaders.includes(name)
        );

        // console.log(isAdmin);

        // if (!isAdmin) {
        //   if (missingFields.length) {
        //     setAlert(
        //       "Error: Missing fields - " + missingFields.join(", "),
        //       "error"
        //     );
        //     return;
        //   }
        // }

        if (false) {
          // 2. Reformat the data and set it

          const formattedData = result.data.map((row) =>
            fieldNames.map((name) => row[name] || "")
          );
          // 3. Merge with current data
          let updatedData = [...data, ...formattedData];
          // 4. Remove rows with all empty strings
          updatedData = updatedData.filter(
            (row) => !row.every((cell) => cell === "")
          );
          // 5. Update the state
          setData(updatedData);
        } else {
          const transformData = (result) => {
            // Assuming the first row in 'data' is the correct format and has all columns
            const expectedLength = Object.keys(result.data[0]).length;

            const transformedData = result.data
              .map((row) => Object.values(row))
              .filter((row) => row.length === expectedLength);

            return transformedData;
          };

          const formattedData = transformData(result);

          // console.log("FORMATTED DATA BEFORE  ",formattedData);
          let updatedData = [...formattedData];

          updatedData = updatedData.filter(
            (row) => !row.every((cell) => cell === "")
          );

          const transformedHeaders = csvHeaders.map((header, index) => ({
            id: index.toString(),
            name: header,
          }));
          setFields(transformedHeaders);
          setData(updatedData);
          setFieldUpdate(true);
        }
      },
      header: true,
    });
  };

  const handleSocketMessage = async (data) => {
    // console.log("DATA got", data);
    data = JSON.parse(data);
    const command = data["command"];
    if (command == "test_status") {
      const s = data["message"];
      // console.log(s);
      setWorkerStatus(s);
      setStatusText(
        s == "0"
          ? "Queued"
          : s == "1"
          ? "Running : Cancel"
          : s == "2"
          ? "Completed : Rerun"
          : s == "3"
          ? "Error : Rerun"
          : "Run"
      );

      // console.log(workerStatus);
      if (s == "2" || s == "3") {
        setView("1");
      }
    }
  };

  const saveData = async (type) => {
    if (data.length < 1) {
      setAlert("No data found. Add some data.", "error");
      return;
    }
    if (fields.length < 1) {
      setAlert("No fields found. Add some fields.", "error");
      return;
    }
    if (workerStatus === 0 || workerStatus === 1) {
      setAlert("A work is already going on. please wait...", "info");
      return;
    }
    setIsLoading(true);
    try {
      const dat1 = {
        data: data,
        fields: [],
      };
      if (type == 1) {
        dat1.fields = fields;
      }
      const response = await SendRequest(
        `/api/saveentry/${scriptid}/`,
        "PUT",
        dat1
      );

      if (response.error) {
        // Handle the error,  show an error message to the user

        setAlert("Error saving data: " + response.error, "error");
      } else {
        // Handle the success scenario
        setAlert("Data saved successfully!", "info");
      }
    } catch (err) {
      // Handle unexpected errors
      setAlert("Unexpected error: " + err.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchPageData = async () => {
      setScriptLoaded(false);
      setIsLoading(true);
      const response = await SendRequest(
        `/api/scriptentries/${scriptid}/`,
        "GET"
      );
      // console.log(response);

      //   if (response.error) {
      //     alert(response.error);
      //     setScriptLoaded(true);
      //   } else {
      setData(response.data);
      const s = response.status;
      setWorkerStatus(s);
      setStatusText(
        s == "0"
          ? "Queued"
          : s == "1"
          ? "Running : Cancel"
          : s == "2"
          ? "Completed : Rerun"
          : s == "3"
          ? "Error : Rerun"
          : "Run"
      );
      // console.log("SHOWING FIELDS ", response.script.fields);
      setFields(response.script.fields);
      setScriptLoaded(true);
      setIsLoading(false);
      //   }
    };

    if (scriptid) {
      fetchPageData();
    }
  }, [scriptid]);

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1000",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}


      <Paper elevation={3} className="script-main">
        <Websocket
          url={SOCKET_URL_WITH_TOKEN}
          onMessage={handleSocketMessage}
          onConnect={console.log("WEBSOCKET CONNECTED")}
          onError={(e) => {
            console.log(e);
          }}
          onClose={(e) => {
            console.log(e);
          }}
        />


          <ToggleButtonGroup
            exclusive
            value={view}
            onChange={(e, newValue) => setView(newValue)}
            className="script-left"
          >
            <ToggleButton
              value="0"
              className={`script-left-in ${
                view === "0" ? "script-active" : ""
              }`}
            >
              Script
            </ToggleButton>
            <ToggleButton
              value="1"
              className={`script-left-in ${
                view === "1" ? "script-active" : ""
              }`}
            >
              Output
            </ToggleButton>
            <ToggleButton
              value="2"
              className={`script-left-in ${
                view === "2" ? "script-active" : ""
              }`}
            >
              Output Table
            </ToggleButton>
            <ToggleButton
              value="3"
              className={`script-left-in ${
                view === "3" ? "script-active" : ""
              }`}
            >
              Screen captures
            </ToggleButton>
          </ToggleButtonGroup>


        <div className="script-right">
          {view === "0" && (
            <div className="">
              <div className="d-flex gap-3 mb-3 flex-wrap">
                <Button className="table-save" onClick={() => saveData(0)}>
                  Save
                </Button>

                <Button className="table-save" onClick={runTest}>
                  {statusText}
                  {
                    (console.log("WorkerStatus:", workerStatus),
                    (workerStatus == "0" || workerStatus == "1") && (
                      <CircularProgress size={24} />
                    ))
                  }
                </Button>

                <Input type="file" accept=".csv" onChange={handleFileChange} />
              </div>
              <Divider />
              <ScriptComponent
                data={data}
                setData={setData}
                fields={fields}
                handleInputChange={handleInputChange}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                deleteRow={deleteRow}
                addNewRow={addNewRow}
                inputRefs={inputRefs}
                isScreenCaptureEnabled={isScreenCaptureEnabled}
                setScreenCaptureEnabled={setScreenCaptureEnabled}
                isTestModeEnabled={isTestModeEnabled}
                setTestModeEnabled={setTestModeEnabled}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          )}
          {view === "1" && (
            <OutputComponent
              status={workerStatus}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {view === "2" && (
            <OutputTableComponent
              status={workerStatus}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {view === "3" && (
            <ScreenCaptureComponent
              status={workerStatus}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </Paper>
    </>
  );
}

export default ScriptPage;
