import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BASE_INLINE_WIDGET_TOOLS from "../constants"; // Import your constants here
import { SendRequest } from "../api";

const InlineToolsWidget = ({
  setShowReport,
  customWidgets,
  customWidgetEntries,
  setCustomWidgetEntries,
  cursorPosition,
  showInlineTools,
  searchText,
  pages,
  scripts,
  params,
  editor,
  handlePublish,
  setIsLoading,
  setAlert,
  pageid,
  id,
  folderid,
  setShowInlineTools,
  users,
  replaceText,
  openKanbanPopup,
  setOpenKanbanPopup,
  createChart,
}) => {
  const [filteredTools, setFilteredTools] = useState([]);
  const [childMapping, setChildMapping] = useState({});
  const [activeMenus, setActiveMenus] = useState([]);


  useEffect(() => {
    // If showInlineTools is false, reset the activeMenus
    if (!showInlineTools) {
      setActiveMenus([]);
    }
  }, [showInlineTools]);
  

  useEffect(() => {
    // console.log("KOOOOOKKIIIII", customWidgets);
    const mapping = {};

    const findParentId = (category) => {
      const parentTool = BASE_INLINE_WIDGET_TOOLS.find(
        (tool) => tool.heading === category
      );
      return parentTool ? parentTool.id : null;
    };

    // Your existing logic for BASE_INLINE_WIDGET_TOOLS
    BASE_INLINE_WIDGET_TOOLS.forEach((tool) => {
      if (!mapping[tool.parentId]) {
        mapping[tool.parentId] = [];
      }
      mapping[tool.parentId].push(tool);
    });

    // Scripts mapping
    const scriptCategories = new Set();
    scripts.forEach((script) => {
      scriptCategories.add(script.category_name);
    });

    scriptCategories.forEach((category) => {
      const categoryItem = {
        id: `script_category_${category}`,
        parentId: findParentId("Scripts"),
        heading: category,
        subheading: "Script category",
        image: "/images/page.png",
        action_name: "",
        data_id: "",
        type: 1,
      };

      if (!mapping[categoryItem.parentId]) {
        mapping[categoryItem.parentId] = [];
      }
      mapping[categoryItem.parentId].push(categoryItem);

      const filteredScripts = scripts.filter(
        (script) => script.category_name === category
      );

      filteredScripts.forEach((script) => {
        const typ = script.type == undefined ? 1 : 0;
        const scriptItem = {
          id: `script_${script.id}`,
          parentId: `script_category_${category}`,
          heading: script.title,
          subheading: script.description,
          image: "/images/page.png",
          action_name: "script",
          data_id: script.id,
          type: typ,
        };
        if (!mapping[scriptItem.parentId]) {
          mapping[scriptItem.parentId] = [];
        }
        mapping[scriptItem.parentId].push(scriptItem);
      });
    });

    // Params mapping
    params.forEach((param) => {
      const paramItem = {
        id: `param_${param.id}`,
        parentId: findParentId("Parameters"),
        heading: param.name,
        subheading: `Add parameter ${param.name} to the page.`,
        image: "/images/page.png",
        action_name: "param",
        data_id: param.id,
        type: 1,
      };
      if (!mapping[paramItem.parentId]) {
        mapping[paramItem.parentId] = [];
      }
      mapping[paramItem.parentId].push(paramItem);
    });

    // Pages mapping
    pages.forEach((page) => {
      const pageItem = {
        id: `page_${page.id}`,
        parentId: findParentId("Pages"),
        heading: page.name,
        subheading: `Add a link to ${page.name}`,
        image: "/images/page.png",
        action_name: "page",
        data_id: page.id,
        type: 1,
      };
      if (!mapping[pageItem.parentId]) {
        mapping[pageItem.parentId] = [];
      }
      mapping[pageItem.parentId].push(pageItem);
    });

    // customWidgets header mapping
    customWidgets.forEach((widget) => {
      if (widget.level === 1) {
        const widgetItem = {
          id: `customitemwidget_${widget.id}`,
          parentId: findParentId("Item level"),
          heading: widget.name,
          subheading: `Widget for ${widget.name}`,
          image: "/images/page.png",
          action_name: "widget",
          data_id: widget.id,
          type: 1,
        };
        if (!mapping[widgetItem.parentId]) {
          mapping[widgetItem.parentId] = [];
        }
        mapping[widgetItem.parentId].push(widgetItem);
      } else {
        const widgetExists = customWidgetEntries.some(
          (entry) => entry.custom_widget.id === widget.id
        );
        if (!widgetExists) {
          const widgetItem = {
            id: `customheaderwidget_${widget.id}`,
            parentId: findParentId("Header level"),
            heading: widget.name,
            subheading: `Widget for ${widget.name}`,
            image: "/images/page.png",
            action_name: "widget_header",
            data_id: widget.id,
            type: 1,
          };
          if (!mapping[widgetItem.parentId]) {
            mapping[widgetItem.parentId] = [];
          }
          mapping[widgetItem.parentId].push(widgetItem);
        }
      }
    });

    setChildMapping(mapping);

    const rootTools = mapping[null] || [];
    setFilteredTools(
      rootTools.filter(
        (item) =>
          !searchText ||
          item.heading.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, scripts, params, pages, customWidgets]);

  const onItemEnter = (id) => {
    setActiveMenus((prevState) => [...prevState, id]);
  };

  const onItemLeave = () => {
    setActiveMenus((prevState) => {
      const newActiveMenus = [...prevState];
      newActiveMenus.pop();
      return newActiveMenus;
    });
  };

  const menuItemHoverStyle = {
    boxShadow: "inset 2px 0px 0px #0052CC",
    backgroundColor: "#EBECF0",
  };

  const createToC = () => {
    // console.log("ENTERING TOC");
    editor.execCommand("mceInsertToc");
    // replaceText("");
  };

  const handleParamClick = (name) => {
    replaceText(`{{${name}}}`);
  };

  const handlePageClick = (name, pid) => {
    const link = `<a href='/project/${id}/${folderid}/${pid}/'>${name}</a>`;
    replaceText(link);
  };

  const createScript = async (scriptId, scriptName) => {
    // console.log("CHECKING pageid", pageid);
    if (!pageid) {
      setAlert("Publish page first.", "info");
      return;
    }
    setIsLoading(true);
    // console.log(scriptId, " in createscript");
    const data = { script: scriptId, project: id };
    // console.log("BEFORE CREATING SCRIPT - ", data);
    const endpoint = `/api/createentry/`;
    const response = await SendRequest(endpoint, "POST", data);
    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      const link = `<div class="script-but" contenteditable="false" ><div style="background: cornflowerblue;padding: 2px 10px 2px 10px;border-radius: 5px;width:fit-content;"><a  class="script-link" style="text-decoration: none;cursor: pointer;" href="/script/${response.id}/">Script - ${scriptName}</a><button data-id="${response.id}" class="del-script">x</button></div><div class="script-content" data-id="${response.id}" ><iframe class="script-iframe" src="/script/${response.id}/" width="1100" height="500"></iframe></div></div>`;
      replaceText(link);
      handlePublish(null);
    }
  };

  const createCustomWidgetEntry = async (id, type) => {
    if (!pageid) {
      setAlert("Publish page first.", "info");
      return;
    }
    setIsLoading(true);

    const data = { custom_widget: id, page: parseInt(pageid) };
    const endpoint = `/api/customwidgetentry/`;
    const response = await SendRequest(endpoint, "POST", data);
    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      if (type === 1) {
        const users1 = users.map((user) => user.email).join(",");
        const choices = response.custom_widget.choices.join(",");
        const element = `<div class="custom-widget"  data-id="${response.id}" contenteditable="false" style="background: #bbb;padding: 2px 10px 2px 10px;border-radius: 5px;width:fit-content;"><div>${response.custom_widget.name} <button data-id="${response.id}" class="del-custom-widget">x</button></div> <div class="custom-widget-content"   data-id="${response.id}" ><iframe id="custom-widget-component-${response.id}" width="100%" height="70px" src="/widgetentry/${pageid}/?widgetentryid=${response.id}&amp;customwidgetid=${id}&amp;pageid=${pageid}&amp;type=${response.custom_widget.type}&amp;choices=${choices}&amp;selected_choice=null&amp;numeric_value=null&amp;users=${users1}" style="padding: 0px; margin: 0px; border: medium; overflow: hidden;"></iframe></div></div>`;
        replaceText(element);
        handlePublish(null);
      } else {
        setCustomWidgetEntries((prevEntries) => [...prevEntries, response]);
        replaceText("");
        setIsLoading(false);
      }
    }
  };

  const handleItemClick = (actionName, dataId, heading, event) => {
    event.stopPropagation();
    if (!actionName) return;
    setActiveMenus([]);
    setShowInlineTools(false);
    if (actionName === "toc") {
      createToC();
    } else if (actionName === "script") {
      createScript(dataId, heading);
    } else if (actionName === "param") {
      handleParamClick(heading);
    } else if (actionName === "page") {
      handlePageClick(heading, dataId);
    } else if (actionName === "gantt") {
      // console.log("gantt", dataId);
      createChart("gantt");
    } else if (actionName === "kanban") {
      // console.log("kanban", dataId);
      // createChart("kanban");
      setOpenKanbanPopup(true);
    } else if (actionName === "scheduler") {
      // console.log("scheduler", dataId);
      createChart("scheduler");
    } else if (actionName === "widget") {
      // console.log("widget", dataId);
      createCustomWidgetEntry(dataId, 1);
    } else if (actionName === "widget_header") {
      createCustomWidgetEntry(dataId, 0);
    } else if (actionName === "report") {
      setShowReport(true);
    } else {
      // console.log("no action");
    }
  };
  const renderSubMenu = (parentId, level = 0) => {
    const isVisible = activeMenus.includes(parentId);
    const children = childMapping[parentId] || [];

    // console.log("children.length === 0", children, children.length === 0);

    const defaultStyles = {
      position: "absolute",
      left: "100%",
      top: "0",
      zIndex: level,
      backgroundColor: "#FFFFFF",
      borderRadius: "3px",
      boxShadow:
        "0 0 1px rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25)",
      width: "280px",
    };



    const conditionalStyles = (level === 1 || parentId == 2 || parentId == 3) ? {
      maxHeight: "300px",
      overflowX: "hidden",
      overflowY: "auto",
    } : {};
    
    const combinedStyles = { ...defaultStyles, ...conditionalStyles };

    return (
      isVisible && (
        <div
          style={combinedStyles}
        >
          {children.map((subTool) => (
            <div
              key={subTool.id}
              onMouseEnter={() => onItemEnter(subTool.id)}
              onMouseLeave={onItemLeave}
              onClick={
                subTool.type !== 0
                  ? (e) =>
                      handleItemClick(
                        subTool.action_name,
                        subTool.data_id,
                        subTool.heading,
                        e
                      )
                  : null
              }
              style={{
                ...(activeMenus.includes(subTool.id) ? menuItemHoverStyle : {}),
                display: "flex",
                gap: "10px",
                alignItems: "center",
                padding: "12px 12px 11px",
                position: "relative",
              }}
            >
              {subTool.type === 1 ? (
                <>
                  <img
                    src={subTool.image}
                    alt={subTool.heading}
                    style={{ width: "30px", height: "30px" }}
                  />
                  <div>
                    <div>{subTool.heading}</div>
                    <div
                      style={{
                        fontSize: "0.75rem",
                        color: "#6B778C",
                        marginTop: "3px",
                      }}
                    >
                      {subTool.subheading}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    maxWidth: "350px",
                    textAlign: "center",
                    margin: "auto",
                    marginTop: "20px",
                    background: "rgba(255, 255, 255, 0.3)",
                    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                    backdropFilter: "blur(3.5px)",
                    borderRadius: "10px",
                    border: "1px solid rgba(255, 255, 255, 0.18)",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      margin: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/card.gif"
                      alt={subTool.heading}
                      style={{ width: "30px", height: "30px", margin: "auto" }}
                    />
                  </div>

                  <div style={{ textAlign: "center" }}>{subTool.heading}</div>
                </div>
              )}
              {renderSubMenu(subTool.id, level + 1)}
            </div>
          ))}
        </div>
      )
    );
  };

  if (!showInlineTools) {
    // console.log("SHOW INLINE TOOLS IS NULL -> NOT SHOWING");
    return null;
  } else {
    // console.log("SHOW INLINE TOOLS IS NOT NULL -> SHOWING");
    // console.log("filteredTools", filteredTools);
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: `${cursorPosition.left}px`,
          top: `${cursorPosition.top + 40}px`,
          zIndex: 1000,
          backgroundColor: "#FFFFFF",
          borderRadius: "3px",
          boxShadow:
            "0 0 1px rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25)",
          width: "280px",
          // maxHeight: "380px",
          cursor: "pointer",
          // overflowY: "scroll",
        }}
        contentEditable={false}
      >
        {filteredTools.map((tool) => (
          <div
            key={tool.id}
            onMouseEnter={() => onItemEnter(tool.id)}
            onMouseLeave={onItemLeave}
            onClick={(e) =>
              handleItemClick(tool.action_name, tool.data_id, tool.heading, e)
            }
            style={{
              ...(activeMenus.includes(tool.id) ? menuItemHoverStyle : {}),
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "12px 12px 11px",
              position: "relative",
            }}
          >
            <img
              src={tool.image}
              alt={tool.heading}
              style={{ width: "30px", height: "30px" }}
            />
            <div>
              <div>{tool.heading}</div>
              <div
                style={{
                  fontSize: "0.75rem",
                  color: "#6B778C",
                  marginTop: "3px",
                }}
              >
                {tool.subheading}
              </div>
            </div>
            {renderSubMenu(tool.id)}
          </div>
        ))}
      </div>
    </>
  );
};

export default InlineToolsWidget;
