function PlusSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path
        d="M13 11V7a1 1 0 00-2 0v4H7a1 1 0 000 2h4v4a1 1 0 002 0v-4h4a1 1 0 000-2h-4z"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}
export default PlusSvg;
