import React, { useState, useEffect, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { SendRequest } from "../api"; // path to api.js
import AlertContext from "../components/AlertContext";
import LoadingComponent from "./LoadingComponent";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

function DashboardTutorial({ isSuperAdmin, isAdmin }) {
  // console.log('HEREE super', isSuperAdmin)
  const [content, setContent] = useState("");
  const [editor, setEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    async function fetchTutorialContent() {
      const response = await SendRequest("/api/gettutorial/", "GET");
      setContent(response.content); // Assuming the html content is in the 'htmlContent' property

      if (!isAdmin) {
        setIsLoading(false);
      }
    }

    fetchTutorialContent();
  }, []);
  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handlePublish = async () => {
    setIsLoading(true);
    const data = {
      content: editor.getContent(),
    };

    const response = await SendRequest(`/api/publishtutorial/`, "PUT", data);
    // console.log(response);
    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      setAlert("Published", "info");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1000",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <div className="dash-tut">
        {/* <h4 className="dash-tut-head">Tutorial</h4> */}
        {isAdmin ? (
          <div>
            <button
              className="btn btn-primary ml-auto mb-2 d-flex"
              onClick={() => {
                handlePublish();
              }}
            >
              Save
            </button>
            <Editor
              apiKey="h0gqv0mzagujl8t69x4r8ddby08gg6klo79sxk4uu9kwzqzl"
              init={{
                placeholder: "Type your content here...",
                extended_valid_elements: "button[onclick|class|data-id]",
                setup: (editor) => {},
                height: 500,
                menubar: false,
                quickbars_selection_toolbar: "bold italic underline",
                plugins:
                  "tableofcontents quickbars link uploadimage autolink charmap codesample emoticons image lists checklist searchreplace table visualblocks wordcount superscript subscript textcolor colorpicker hr paste pageembed media",
                selector: "textarea",
                toc_header: "div",
                toolbar:
                  "undo redo | blocks | formatselect | bold italic underline strikethrough  code subscript superscript | align |\
                | forecolor backcolor | bullist numlist outdent indent | checklist | emoticons charmap",

                //
              }}
              onInit={(evt, editor) => {
                setEditor(editor);
                // console.log("EDITOR LOADED");
                setIsLoading(false);
              }}
              onError={() => {
                alert("Error initializing the editor.");
                setIsLoading(false);
              }}
              value={content}
              onEditorChange={handleEditorChange}
            />
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100%" }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </>
  );
}

export default DashboardTutorial;
