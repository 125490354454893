import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { SendRequest } from "../api";
import { Box, CircularProgress } from "@mui/material";
import AlertContext from "../components/AlertContext"; // Importing AlertContext

function AcceptUser() {
  const { projectid, userid } = useParams(); // Getting projectid and userid from URL
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext); // Using AlertContext
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SendRequest(
          `/api/accept/${projectid}/${userid}/`,
          "GET"
        );
        if (response.error) {
          setLoading(false);
          setAlert(response.error, "error"); // Displaying error alert
          //   window.alert(response.error); // Using window.alert for simplicity
          navigate("/"); // Navigate to error URL
        } else {
          setLoading(false);
          setAlert(
            "You have been added to the project. Click on OK to login.",
            "info"
          ); // Displaying success alert
          //   window.alert("You have been added to the project. Click on OK to login.");
          navigate("/login/"); // Navigate to success URL
        }
      } catch (error) {
        setLoading(false);
        console.error("Request failed:", error);
        setAlert("An error occurred. Please try again.", "error");
        // window.alert("An error occurred. Please try again.");
        navigate("/"); // Navigate to error URL
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectid, userid, setAlert, navigate]); // Adding navigate to dependencies

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1400",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <div></div>
    </>
  );
}

export default AcceptUser;
