import React from "react";
import { Button, Typography, Grid, Container, Box } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "../css/landing.css";
import ArrowButton from "./ArrowButton";
import Section1ImageTiles from "./Section1ImageTiles";

function LandingPageSection1() {
  return (
    <Container sx={{ maxWidth: "inherit !important" }}>
      <Grid container spacing={3} direction="column" alignItems="center">
        {/* Heading */}
        <div className="land-sec11">
          <div className="land-sec11in">
            <Grid item>
              <Typography
                variant="h1"
                style={{
                  fontWeight: 600,
                  fontSize: "4.5rem",
                  lineHeight: "85px",
                  color: "rgb(51, 51, 51)",
                  maxWidth: "1400px",
                }}
                className="land-head1"
              >
                A platform built for a new way of working
              </Typography>
            </Grid>

            {/* Get Started Button */}
            <Grid
              item
              style={{
                marginBottom: "50px",
                marginTop: "50px",
              }}
            >
              <ArrowButton buttonText="Get Started" />
            </Grid>

            {/* Additional Text */}
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "0.8125rem",
                  lineHeight: "18px",
                  color: "#676879",
                  marginTop: "40px",
                }}
              >
                No credit card needed &nbsp; ✦ &nbsp; Unlimited time on Free
                plan
              </Typography>
            </Grid>
          </div>
        </div>
        {/* Full width image */}
        <Grid item xs={12}>
          <img
            src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/NaamaGros/HP_tests/HP_asset_white_bg.png"
            alt="Description"
            style={{ width: "100%" }}
          />
        </Grid>

        {/* Trusted by */}
        <Grid item>
          <Typography variant="h2" className="land-trust">
            Trusted by 180,000+ customers worldwide
          </Typography>
        </Grid>

        {/* Company logos */}
        <Grid
          item
          container
          spacing={2}
          className="align-items-center land-trust-imgs"
          sx={{ maxWidth: "1400px !important;" }}
        >
          {[
            "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/canva.png",
            "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/coca_cola.png",
            "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/oxy.png",
            "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/lionsgate.png",
          ].map((logo, index) => (
            <Grid item key={index} xs={6} sm={4} md={3}>
              <img
                src={logo}
                alt={`Company Logo ${index + 1}`}
                style={{ width: "100%" }}
                className="land-trust-img"
              />
            </Grid>
          ))}
        </Grid>

        {/* Description */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="p-4"
          sx={{ maxWidth: "1400px !important;" }}
        >
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h2" className="land-work-os">
              The Work OS that lets you shape workflows, your way
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className="land-work-os1">
              Boost your team’s alignment, efficiency, and productivity by
              customizing any workflow to fit your needs.
            </Typography>
            <Box mt={2}>
              <ArrowButton buttonText="Get Started" />
            </Box>
          </Grid>
        </Grid>

        {/* Tiled Images */}
        <Section1ImageTiles />
      </Grid>
    </Container>
  );
}

export default LandingPageSection1;
