import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SendRequest } from "../api";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  BarController,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Register Chart.js components
Chart.register(
  ArcElement,
  PieController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
);

function ReportPage() {
  const { reportid } = useParams();
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReport = async () => {
      const response = await SendRequest(`/api/report/${reportid}/`, "GET");
      console.log("here -> ", response);
      if ("error" in response) {
        setError(response.error);
      } else if (response.output.length == 0) {
        setError("Report not generated...");
      } else if (
        (response.type == 3 && response.output[0].length == 0) ||
        (response.type == 2 && response.output.length == 1) ||
        (response.type == 1 && response.output[0].length == 1)
      ) {
        setError("** NO DATA TO SHOW **");
      } else {
        setReportData(response);
      }
    };

    fetchReport();
  }, [reportid]);

  const renderTable = (outputs) =>
    outputs.map((output, tableIndex) => (
      <TableContainer
        key={tableIndex}
        component={Paper}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {output[0].map((header, idx) => (
                <TableCell key={idx}>
                  <span style={{ fontWeight: "bold" }}>{header}</span>&nbsp;[
                  {reportData["data"][tableIndex][idx]["output"]}]
                  {reportData["data"][tableIndex][idx]["filter"] !== "" ? (
                    <span>
                      {" "}
                      ({reportData["data"][tableIndex][idx]["filter"]})
                    </span>
                  ) : (
                    <span> (ALL)</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {output.slice(1).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {cell.split(" -> ")[1] ? (
                      <>
                        {cell.split(" -> ")[0]}
                        <span className="report-count-cell">
                          {" "}
                          {cell.split(" -> ")[1]}{" "}
                        </span>
                      </>
                    ) : (
                      <span className="report-count-cell">
                        {cell.split(" -> ")[0]}
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ));

  const generateRandomColor = () => {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  };
  const generateBackgroundColors = (length) => {
    return Array.from({ length }, () => generateRandomColor());
  };

  const renderPieChart = (data) => {
    // console.log("HERE--->>", data);
    const labels = data.output[0]
      .slice(1)
      .map((row) => row[0].split(" -> ")[0]);
    const values = data.output[0]
      .slice(1)
      .map((row) => parseInt(row[0].split(" -> ")[1], 10));

    const backgroundColors = generateBackgroundColors(labels.length);

    const chartData = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: backgroundColors,
        },
      ],
    };

    return <Pie data={chartData} />;
  };

  const renderBarChart = (chartData) => {
    // Process your specific data structure
    const data = chartData["output"][0]; // Assuming chartData is the array structure you provided
    // console.log("CHART DATA", data);
    // Extract x-axis labels (x_widget_selected_choice_name)
    const xLabels = data.map((item) => item.x_widget_selected_choice_name);

    // Collect all unique selected names for the datasets
    const allSelectedNames = [
      ...new Set(data.flatMap((item) => item.data.map((d) => d.selected_name))),
    ];

    // Map selected names to datasets
    const datasets = allSelectedNames.map((name) => {
      return {
        label: name,
        backgroundColor: generateRandomColor(),
        data: data.map((item) => {
          const entry = item.data.find((d) => d.selected_name === name);
          return entry ? entry.count : 0;
        }),
      };
    });

    // Chart.js data structure
    const barChartData = {
      labels: xLabels,
      datasets: datasets,
    };

    return <Bar data={barChartData} />;
  };

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "25px",
          color: "red",
        }}
      >
        {error}
      </div>
    );
  }

  if (!reportData) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1
        style={{ textTransform: "capitalize", borderBottom: "1px solid black" }}
      >
        {reportData.name}
      </h1>
      <div style={{ height: "500px" }}>
        {reportData.type === 1 && !error && renderTable(reportData.output)}
        {reportData.type === 2 && !error && renderPieChart(reportData)}
        {reportData.type === 3 && !error && renderBarChart(reportData)}
      </div>
    </div>
  );
}

export default ReportPage;
