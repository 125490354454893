import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Box, ImageList, ImageListItem } from "@mui/material";

function OutputComponent({ status, isLoading, setIsLoading }) {
  const [error, setError] = useState([]);
  const [output, setOutput] = useState([]);
  const { scriptid } = useParams();

  useEffect(() => {
    const fetchPageData = async () => {
      setIsLoading(true);
      const response = await SendRequest(
        `/api/scriptentries/${scriptid}/`,
        "GET"
      );

      if (status == "3") {
        setError(response.error);
      } else {
        setOutput(response.output);
      }

      setIsLoading(false);
    };

    if (status == "3" || status == "2") {
      fetchPageData();
    }
  }, [status]);

  return (
    <Box className="script-right-in">
      {error && status == "3" && (
        <Box className="error-div">
          <Typography variant="h6" className="error-tit">
            :ERROR:
          </Typography>
          <Typography variant="body1" className="error-cont-out">
            {error}
          </Typography>
        </Box>
      )}
      {status != "3" && (
        <Box>
          {output.length === 0 ? (
            <Typography variant="h6" className="no-data">
              No output
            </Typography>
          ) : (
            output.map((o) => (
              <Box key={o.id} className="each-out">
                <Typography variant="body2" className="out-created">
                  {o.created_date}
                </Typography>
                <ImageList rowHeight={180} className="img-div-out">
                  {o.output_images.map((image) => (
                    <ImageListItem key={image.id}>
                      <img src={image.image} alt="description" />
                    </ImageListItem>
                  ))}
                </ImageList>
                <Box>
                  {o.output_texts.map((text, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      className="txt-div-out"
                    >
                      {text.text}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))
          )}
        </Box>
      )}
    </Box>
  );
}

export default OutputComponent;
