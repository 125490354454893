import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  List,
  ListItem,
  Box,
  Typography,
  Link as MuiLink,
  Alert,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

const PageItem = ({
  pageId,
  pageName,
  id,
  folderId,
  date,
  pageImage,
  type,
}) => {
  // console.log(pageImage);
  return (
    <MuiLink
      component={RouterLink}
      to={`/project/${id}/${folderId}/${pageId}/?type=${type}`}
      underline="none"
    >
      <Box
        component="div"
        className="project-item"
        display="flex"
        alignItems="center"
        gap={2}
        mb={2}
      >
        {/* <FolderSvg /> */}
        <img className="all-page-img" src={pageImage} />
        <Typography className="project-name">{pageName}</Typography>
      </Box>
    </MuiLink>
  );
};

function ProjectRightAllPages({ type, folderData }) {
  const { id, folderid } = useParams(); // Extract the folderId from the URL

  return (
    <Box>
      <List className="project-list">
        {!folderData || folderData.length === 0 ? (
          <ListItem>
            <Alert severity="info">No pages</Alert>
          </ListItem>
        ) : (
          folderData.map((page) => (
            <PageItem
              key={page.id}
              pageId={page.id}
              pageName={page.name}
              pageImage={page.type ? page.type.image : "/images/page.png"}
              id={id}
              folderId={folderid}
              date=""
              type={type}
            />
          ))
        )}
      </List>
    </Box>
  );
}

export default ProjectRightAllPages;
