import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import LoadingComponent from "../components/LoadingComponent";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, CircularProgress } from '@mui/material';

function ScriptDocPage() {
  const { scriptid } = useParams();
  const [docContent, setDocContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await SendRequest(`/api/scripts/${scriptid}/`, "GET");
        // console.log(response);
        if (response && response.doc) {
          setDocContent(response);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [scriptid]); // The empty array means this useEffect will run once when the component mounts

  return (
    <Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="doc-cont">
          <Typography variant="h4" gutterBottom>
            {docContent.title}
          </Typography>
          <Box className="doc-contin" dangerouslySetInnerHTML={{ __html: docContent.doc }} />
        </Box>
      )}
    </Box>
  );
}

export default ScriptDocPage;
