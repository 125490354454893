function DeleteSvg() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0234 3C13.6803 3 15.0234 4.34315 15.0234 6V7H19.0234C19.5757 7 20.0234 7.44772 20.0234 8C20.0234 8.51284 19.6374 8.93552 19.1401 8.99328L19.0234 9H18.0234V19C18.0234 20.0543 17.2076 20.9181 16.1727 20.9945L16.0234 21H8.02344C6.91887 21 6.02344 20.1045 6.02344 19V9H5.02344C4.47115 9 4.02344 8.55228 4.02344 8C4.02344 7.48716 4.40948 7.06447 4.90681 7.00671L5.02344 7H9.02344V6C9.02344 4.40232 10.2724 3.09637 11.8472 3.00512L12.0234 3ZM16.0234 9H8.02344V19H16.0234V9ZM10.5234 11C11.0363 11 11.4589 11.386 11.5167 11.8834L11.5234 12V16C11.5234 16.5522 11.0757 17 10.5234 17C10.0106 17 9.58793 16.6139 9.53017 16.1165L9.52344 16V12C9.52344 11.4477 9.97115 11 10.5234 11ZM13.5234 11C14.0363 11 14.4589 11.386 14.5167 11.8834L14.5234 12V16C14.5234 16.5522 14.0757 17 13.5234 17C13.0106 17 12.5879 16.6139 12.5302 16.1165L12.5234 16V12C12.5234 11.4477 12.9712 11 13.5234 11ZM12.0234 5C11.4712 5 11.0234 5.44772 11.0234 6V7H13.0234V6C13.0234 5.48716 12.6374 5.06447 12.1401 5.00671L12.0234 5Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
export default DeleteSvg;
