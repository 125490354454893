import React, { useState, useEffect } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function ReviewCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    // Automatically change reviews every 10 seconds
    const interval = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleCircleClick = (index) => {
    setCurrentReview(index);
  };

  const reviews = [
    {
      reviewText:
        "accpro.uk has given me the confidence because when I walk into the room I know that when upper management asks for a certain piece of information I’m able to quickly provide it for them.",
      designation:
        "Kenny Wallace | Vendor Tooling Engineer, Nissan North America",
      companyLogo:
        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/coca_cola.png",
      user:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/telefonica_image.png",
    },
    {
      reviewText:
        "accpro.uk has given me the confidence because when I walk into the room I know that when upper management asks for a certain piece of information I’m able to quickly provide it for them.”",
      designation: "CFO, Company B",
      companyLogo:
        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/canva.png",
      user:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/zippo_image.png",
    },
    {
      reviewText:
        "accpro.uk has given me the confidence because when I walk into the room I know that when upper management asks for a certain piece of information I’m able to quickly provide it for them.",
      designation: "CTO, Company C",
      companyLogo:
        "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/lionsgate.png",
      user:
        "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/reviews/oscar_image.png",
    },
    // Add more reviews here
  ];

  if (isMobile) {
    const maxReviewTextHeight = 500;

    return (
      <Carousel
        autoPlay
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        dynamicHeight={false} // Disable dynamic height
        selectedItem={currentReview}
        onChange={(index) => setCurrentReview(index)}
      >
        {reviews.map((review, index) => (
          <div
            key={index}
            style={{
              height: "650px", // Set a fixed height for the review container
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "50px",
              maxWidth: "350px",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, #6161FF 0%, #6161FF 25%, #5034FF 100%)",
                borderRadius: "16px",
                padding: "44px",
                color: "white",
                maxWidth: "480px",
                width: "480px",
                overflow: "hidden", // Hide overflowing text
                maxHeight: `650px`, // Limit the height of the review text
                maxWidth: "350px",
              }}
            >
              <img
                src={review.user}
                alt="User Image"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0 auto 20px",
                  display: "block",
                }}
              />
              <Typography
                style={{
                  letterSpacing: "-0.5px",
                  fontSize: "22px",
                  lineHeight: "1.6",
                }}
              >
                {review.reviewText}
              </Typography>
              <Typography variant="subtitle1">{review.designation}</Typography>
              <img
                src={review.companyLogo}
                alt="Company Logo"
                style={{
                  width: "100px",
                  height: "auto",
                  marginTop: "10px",
                  display: "block",
                  margin: "20px auto 0",
                }}
              />
            </div>

            {/* Circle Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {reviews.map((_, circleIndex) => (
                <div
                  key={circleIndex}
                  style={{
                    width: "11px",
                    height: "11px",
                    border:
                      circleIndex === index ? "1px solid #6161FF" : "1px solid",
                    borderRadius: "50%",
                    background:
                      circleIndex === index ? "#6161FF" : "transparent",
                    margin: "0 5px",
                    cursor: "pointer",
                    transition: "padding 0.2s ease-in-out",
                    padding: "4px",
                  }}
                  className="review-bubble"
                  onClick={() => handleCircleClick(circleIndex)}
                />
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    );
  } else {
    return (
      <>
        <div style={{ marginBottom: "100px", marginRight: "120px" }}>
          <div
            style={{
              width: "100%",
              height: "320px",
              background:
                "linear-gradient(180deg, #6161FF 0%, #6161FF 25%, #5034FF 100%)",
              borderRadius: "16px",
              color: "white",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
              padding: "44px",
              position: "relative",
              transition: "all 0.3s",
            }}
          >
            <Typography
              style={{
                letterSpacing: "-0.5px",
                fontSize: "22px",
                lineHeight: "1.6",
              }}
            >
              {reviews[currentReview].reviewText}
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
              {reviews[currentReview].designation}
            </Typography>
            <img
              src={reviews[currentReview].companyLogo}
              alt="Company Logo"
              style={{ width: "100px", height: "auto", marginTop: "10px" }}
            />
          </div>

          {/* Circle Buttons */}
          <div
            style={{
              display: "flex",
              marginTop: "24px",
              justifyContent: "center",
              alignItems: "center",
              height: "16px",
            }}
          >
            {reviews.map((_, index) => (
              <div
                key={index}
                style={{
                  width: "11px",
                  height: "11px",
                  border:
                    index === currentReview ? "1px solid #6161FF" : "1px solid",
                  borderRadius: "50%",
                  background:
                    index === currentReview ? "#6161FF" : "transparent",
                  margin: "0 5px",
                  cursor: "pointer",
                  transition: "padding 0.2s ease-in-out",
                  padding: "4px",
                }}
                className="review-bubble"
                onClick={() => handleCircleClick(index)}
              />
            ))}
          </div>
        </div>

        {/* User Image Container */}
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            width: "200px",
            height: "200px",
            // border: "2px solid gray",
            borderRadius: "16px",
            backgroundImage: `url(${reviews[currentReview].user})`,
            backgroundSize: "cover",
          }}
        />
      </>
    );
  }
}

export default ReviewCard;
