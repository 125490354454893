import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { SendRequest } from "../api"; // assuming this is your request function
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GanttChartComponent from "./GanttChartComponent";
import SchedulerChartComponent from "./SchedulerChartComponent";
import KanbanChartComponent from "./KanbanChartComponent";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import AlertContext from "../components/AlertContext";

let root = null;

function ProjectRightPage() {
  const { id, folderid, pageid } = useParams();
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customWidgetEntries, setCustomWidgetEntries] = useState([]);
  const [users, setUsers] = useState([]);
  const { setAlert } = useContext(AlertContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  const navigate = useNavigate();

  const editPage = () => {
    // navigate(`/edit/${id}/${folderid}/${pageid}/`);
    window.location.href = `/edit/${id}/${folderid}/${pageid}/`;
  };

  const fetchUser = async () => {
    const response = await SendRequest(`/api/getusers/${id}/`, "GET");
    return response;
  };

  useEffect(() => {
    const initializeUsers = async () => {
      // console.log("RUNNING initializeUsers");
      const response = await fetchUser();
      setUsers(response.users);
    };

    initializeUsers();
  }, [id]);

  useEffect(() => {
    const fetchCustomWidgetEntries = async () => {
      try {
        const response = await SendRequest(
          `/api/customwidgetentry/?pageid=${pageid}`,
          "GET"
        );
        // console.log(response);
        if (response.error) {
          setAlert(response.error, "error");
        } else {
          setCustomWidgetEntries(response.results);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    };
    fetchCustomWidgetEntries();
  }, [pageid]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SendRequest(`/api/pages/${pageid}/`, "GET");
        setContent(response.content);
        setTitle(response.name);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, pageid]);

  useEffect(() => {
    if (content) {
      // console.log("CONTENT CHANGED....");

      // CREATING CHART IFRAME

      const chartElements = document.querySelectorAll(".chart");
      // console.log(chartElements);
      chartElements.forEach((chartElement, index) => {
        const chartId = Number(chartElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `chart-component-${chartId}`;
        iframe.width = "100%";
        iframe.height = "620px";

        // Construct the iframe URL
        let iframeURL = `/chart/${id}/${chartId}/`;
        iframe.src = iframeURL;

        // Use querySelector to find the .chart-div element within chartElement
        let chartDiv = chartElement.querySelector(".chart-div");
        // console.log("CREATING CHART IFRAME", chartDiv);
        if (chartDiv) {
          chartDiv.appendChild(iframe);
        } else {
          console.error("No .chart-div found in chartElement", chartElement);
        }
      });

      // SCRIPTS

      const scriptElements = document.querySelectorAll(".script-content");
      // console.log(scriptElements);
      scriptElements.forEach((scriptElement, index) => {
        const scriptId = Number(scriptElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `script-component-${scriptId}`;
        iframe.width = "1100";
        iframe.height = "500";

        // Construct the iframe URL
        let iframeURL = `/script/${scriptId}/`;
        iframe.src = iframeURL;

        scriptElement.appendChild(iframe);
      });

      // Reports

      const reportElements = document.querySelectorAll(".report-content");
      // console.log(reportElements);
      reportElements.forEach((reportElement, index) => {
        const reportId = Number(reportElement.getAttribute("data-id")); // Convert to number
        // Create a new iframe element
        const iframe = document.createElement("iframe");
        iframe.id = `report-component-${reportId}`;
        iframe.width = "1100";
        iframe.height = "500";

        // Construct the iframe URL
        let iframeURL = `/report/${reportId}/`;
        iframe.src = iframeURL;
        reportElement.appendChild(iframe);
      });
    }
  }, [content]);

  useEffect(() => {
    if (content) {
      // CUSTOM WIDGETS
      const widgetElements = document.querySelectorAll(
        ".custom-widget-content"
      );
      // console.log("HERE WIDGETS --===>>>> ", widgetElements);
      widgetElements.forEach((widgetElement, index) => {
        const widgetId = Number(widgetElement.getAttribute("data-id")); // Convert to number
        const widgetData = customWidgetEntries.find(
          (item) => item.id === widgetId
        ); // Find the corresponding widget data

        if (widgetData) {
          // console.log("HMMM HEREE -> ", widgetData);

          // Create a new iframe element
          const iframe = document.createElement("iframe");
          iframe.id = `custom-widget-component-${widgetId}`;
          iframe.width = "100%"; // Adjust width as needed
          iframe.height = "70px"; // Adjust height as needed

          // Construct the iframe URL
          let iframeURL = `/widgetentry/${pageid}/?`;
          iframeURL += `widgetentryid=${widgetId}`;
          iframeURL += `&customwidgetid=${widgetData.custom_widget.id}`;
          iframeURL += `&pageid=${pageid}`;
          iframeURL += `&type=${widgetData.custom_widget.type}`;
          iframeURL += `&choices=${widgetData.custom_widget.choices.join(",")}`;
          iframeURL += `&selected_choice=${widgetData.selected_choice}`;
          iframeURL += `&numeric_value=${widgetData.numeric_value}`;
          iframeURL += `&users=${users.map((user) => user.email).join(",")}`;
          iframe.src = iframeURL;
          iframe.style = `
          padding: 0px;
          margin: 0px;
          border: medium;
          overflow: hidden;`;

          widgetElement.appendChild(iframe);
        }
      });
    }
  }, [content, customWidgetEntries]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            bottom: "0",
            zIndex: "1000",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      <Box>
        <div className="proj-page-in">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h5">{title}</Typography>
            {type === "0" && (
              <IconButton onClick={editPage}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
          <Box dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Box>
    </>
  );
}

export default ProjectRightPage;
