import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SendRequest } from "../api";
import LoadingComponent from "../components/LoadingComponent";
import FolderSvg from "../svg/folder";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from "@mui/material";
import "../css/projects.css";
import ProjectSvg from "../svg/project";
const ProjectItem = ({ projectId, projectName, date }) => {
  return (
    <ListItem
      component={RouterLink}
      to={`/project/${projectId}`}
      className="project-item d-flex align-items-center gap-2 mb-2"
    >
      <ListItemIcon >
        {/* <FolderSvg /> */}
        <ProjectSvg/>
      </ListItemIcon>
      <ListItemText primary={projectName} className="project-name" />
    </ListItem>
  );
};

function AllProjectsPage() {
  const [allProjects, setAllProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAllProjects = async () => {
      setIsLoading(true);
      try {
        const response = await SendRequest("/api/projects/", "GET");
        // console.log("ALL projects = ", response);
        setAllProjects(response["results"]);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch all projects:", error);
        setIsLoading(false);
      }
    };

    fetchAllProjects();
  }, []);
  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box>
      <Box className="container mt-5 proj-cont">
        <Typography variant="h5" className="mt-4 mb-4">
          All Projects
        </Typography>

        <List className="project-list">
          {allProjects.length === 0 ? (
            // <Box className="no-data">No projects</Box>
            <Alert severity="info">No projects</Alert>
          ) : (
            allProjects.map((project) => (
              <ProjectItem
                key={project.id}
                projectId={project.id}
                projectName={project.name}
                date=""
              />
            ))
          )}
        </List>
      </Box>
    </Box>
  );
}

export default AllProjectsPage;
