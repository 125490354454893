import React from "react";
import { Link , Box, Typography, Alert } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FolderSvg from "../svg/folder";
import ProjectSvg from "../svg/project";

function EachProjectComponent({ id, name }) {
  return (
    <Box
      component={RouterLink}
      to={`/project/${id}/`}
      sx={{
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        gap: 2,
        alignItems: "center",
        "&:hover": {
          backgroundColor: "rgba(9, 30, 66, 0.04)",
        },
      }}
      className="eachrecent eachproj"
    >
      <ProjectSvg/>
      <Typography variant="h6" className="projtitle">
        {name}
      </Typography>
    </Box>
  );
}

function DashboardProject({ allProjects }) {
  return (
    <Box className="projectcont1">
      <Box className="recentcont">
        <Typography variant="h6" className="projtit">
          Projects
        </Typography>
        {allProjects.length === 0 ? (
          // <Typography variant="body1" className="no-data">
          //   No projects
          // </Typography>
          <Alert severity="info">No projects</Alert>
        ) : (
          allProjects.map((project) => (
            <EachProjectComponent
              key={project.id}
              id={project.id}
              name={project.name}
            />
          ))
        )}

        <Link
          component={RouterLink}
          to="/projects"
          color="primary"
          underline="none"
          className="view-all"
        >
          View all
        </Link>
      </Box>
    </Box>
  );
}

export default DashboardProject;
