import React, { useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
import "../css/header.css";
import { Link as RouterLink } from "react-router-dom";

import PopupComponent from "./PopupComponent";
import { SendRequest } from "../api"; // path to api.js
import Cookies from "js-cookie";
import LoadingComponent from "../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Link,
  Box,
  Hidden,
  Drawer,
  List,
  ListItem,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import AlertContext from "./AlertContext";

function Header({ isSuperAdmin, isLoggedIn, user, isAdmin }) {
  // console.log("IN DASH HEADER , login = ", isLoggedIn);
  const location = useLocation();
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [fieldValues, setFieldValues] = useState({});

  const { setAlert } = useContext(AlertContext);

  const handleNavbarToggle = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    setFieldValues({
      name: user.first_name,
      email: user.email,
      password: "",
    });
  }, [user]);

  // useEffect(() => {
  //   console.log("fieldValues:", fieldValues);
  // }, [fieldValues]);

  useEffect(() => {
    setPopupOpen(false);
    setDropdownOpen(false);
    setNavbarOpen(false);
  }, [location.pathname]);

  const handlePopupToggle = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleFieldChange = (field, value) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [field.toLowerCase()]: value, // Use the field name in lowercase as the key
    }));
  };

  const handleButtonClick = async () => {
    try {
      const response = await SendRequest(`/api/edituser/`, "POST", fieldValues);

      if (response.error) {
        // alert(response.error);
        setAlert(response.error, "error");
      } else {
        setAlert(response.message, "info");
        // alert(response.message);
      }
    } catch (error) {
      setAlert("An unexpected error occurred. Please try again.", "error");
    }
  };


  const createProject = async () => {
    setIsLoading(true);
    let response;

    try {
      response = await SendRequest("/api/projects/", "POST");
      // no error, so redirect
      // console.log(response);
      if (response["error"]) {
        // alert(response["error"]);
        setAlert(response["error"], "error");
      } else {
        window.location.href = `/project/${response.id}`;
        // navigate();
      }
    } catch (error) {
      // alert(error);
      setAlert(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setPopupOpen(false);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await SendRequest("/api/logout/", "POST");
      Cookies.remove("token"); // remove the token cookie
      window.location.href = "/login/";
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <div>
        {isPopupOpen && (
          <PopupComponent
            heading="Profile"
            fields={["text", "email", "password"]}
            placeholders={["Name", "Email", "Password"]}
            values={Object.values(fieldValues)}
            labels={["Name", "Email", "Password"]}
            buttonLabel="Edit Profile"
            showModal={true}
            buttonAction={handleButtonClick}
            handleCloseModal={handlePopupToggle}
            onValueChange={handleFieldChange}
            errors={[]}
          />
        )}
      </div>

      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Drawer anchor="right" open={isNavbarOpen} onClose={handleNavbarToggle}>
          <List>
            <ListItem
              color="primary"
              button
              component={RouterLink}
              to="/dashboard"
            >
              Home
            </ListItem>
            <ListItem
              color="primary"
              button
              component={RouterLink}
              to="/projects"
            >
              Projects
            </ListItem>
            {isSuperAdmin && (
              <ListItem button component={RouterLink} to="/subdomains">
                Subdomains
              </ListItem>
            )}
            {isAdmin && (
              <ListItem button onClick={createProject}>
                Create Project
              </ListItem>
            )}
          </List>
        </Drawer>

        <Toolbar>
          <Link
            component={RouterLink}
            to="/dashboard"
            color="primary"
            underline="none"
          >
            <Avatar
              src="/images/logo.jpg"
              alt="Website Logo"
              style={{ marginRight: 15 }}
            />
          </Link>

          <Hidden smDown>
            <Box flexGrow={1}>
              <Link
                component={RouterLink}
                to="/dashboard"
                color="primary"
                underline="none"
                variant="h6"
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                to="/projects"
                color="primary"
                underline="none"
                variant="h6"
                style={{ marginLeft: 15 }}
              >
                Projects
              </Link>
              {isSuperAdmin && (
                <Link
                  color="primary"
                  underline="none"
                  variant="h6"
                  style={{ marginLeft: 15 }}
                  component={RouterLink}
                  to="/subdomains"
                >
                  Subdomains
                </Link>
              )}
            </Box>

            {isAdmin && (
              <Button color="primary" onClick={createProject}>
                Create Project
              </Button>
            )}
          </Hidden>

          <IconButton
            onClick={handleDropdownToggle}
            color="inherit"
            style={{ marginLeft: "auto" }}
          >
            <Avatar src="/images/avatar.svg" />
          </IconButton>

          <Menu
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isDropdownOpen}
            onClose={() => setDropdownOpen(false)}
          >
            <MenuItem onClick={handlePopupToggle}>My Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          <Hidden mdUp>
            <IconButton edge="end" color="inherit" onClick={handleNavbarToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
