import React, { useState, useEffect, useContext } from "react";
import "../css/contact.css";
import { SendRequest } from "../api"; // path to api.js
import AlertContext from "../components/AlertContext";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  CircularProgress,
} from "@mui/material";

function ContactPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsLoading(false), 5000); // 5 seconds, adjust as needed
    window.onload = () => {
      clearTimeout(timeoutId);
      setIsLoading(false);
    };

    return () => {
      clearTimeout(timeoutId);
      window.onload = null;
    };
  }, []);

  const { setAlert } = useContext(AlertContext);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    phone: "",
    company_name: "",
    company_size: "",
    how_can_help: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await SendRequest("/api/contact/", "POST", formData);

    if (response.error) {
      setAlert(response.error, "error");
    } else {
      setAlert(response.message, "info");
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          spacing={3}
          className="container mt-5"
          sx={{ margin: "auto" }}
        >
          {/* Left side - Form */}

          <Grid item xs={12} md={6} className="left-cont">
            <Typography
              variant="h5"
              sx={{ marginBottom: "10px" }}
              className="cont-head-form"
            >
              Contact our Sales team
            </Typography>

            <form className="cont-form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First name:"
                    variant="outlined"
                    name="first_name"
                    required
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last name:"
                    variant="outlined"
                    name="last_name"
                    required
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Work email:"
                    variant="outlined"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Job title:"
                    variant="outlined"
                    name="job_title"
                    required
                    value={formData.job_title}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone number:"
                    variant="outlined"
                    name="phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Company name:"
                    variant="outlined"
                    name="company_name"
                    required
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel>Company size:</InputLabel> */}
                    <Select
                      name="company_size"
                      value={formData.company_size}
                      required
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select company size
                      </MenuItem>
                      <MenuItem value="1-19">1-19</MenuItem>
                      <MenuItem value="20-49">20-49</MenuItem>
                      <MenuItem value="50-99">50-99</MenuItem>
                      <MenuItem value="100-250">100-250</MenuItem>
                      <MenuItem value="251-1500">251-1500</MenuItem>
                      <MenuItem value="1500+">1500+</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="How can our team help you?"
                    variant="outlined"
                    multiline
                    rows={4}
                    name="how_can_help"
                    placeholder="Tell us more about your team and what work you'd like to manage with accpro.uk"
                    value={formData.how_can_help}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Box mt={3} className="privacy-notice-wrapper">
                <Typography variant="body2" className="privacy-notice">
                  By clicking submit, I acknowledge receipt of the accpro.uk{" "}
                  <a href="/privacy/" target="_blank">
                    Privacy policy
                  </a>
                </Typography>
              </Box>

              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="cont-button"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>

          {/* Right side - Content */}
          <Grid item xs={12} md={6} className="cont-right">
            <Typography variant="h5" className="cont-right-head">
              Align, collaborate, and gain visibility into your work in one
              connected space
            </Typography>

            <Box className="benefits-wrapper">
              <div className="benefit-wrapper">
                <div className="benefit-icon-wrapper">
                  <picture className="picture-component">
                    <img
                      alt="countries icon"
                      className="cont-right-pic-img"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto,w_37/remote_mondaycom_static/img/contact-sales/countries_icon.png"
                    />
                  </picture>
                  <span className="icon-text">
                    Across <b>200+</b> countries
                  </span>
                </div>
                <hr className="benefit-text-divider" />
                <div className="benefit-text-wrapper">
                  Meet with a product consultant to see how accpro.uk can fit
                  your exact business needs
                </div>
              </div>
              <div className="benefit-wrapper">
                <div className="benefit-icon-wrapper">
                  <picture className="jsx-4212101279 picture-component">
                    <img
                      alt="customers icon"
                      className="cont-right-pic-img"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto,w_37/remote_mondaycom_static/img/contact-sales/customers_icon.png"
                    />
                  </picture>
                  <span className="icon-text">
                    <b>180K+</b> paying customers
                  </span>
                </div>
                <hr className="benefit-text-divider" />
                <div className="benefit-text-wrapper">
                  Explore our tailored pricing plans based on your goals and
                  priorities
                </div>
              </div>
              <div className="benefit-wrapper">
                <div className="benefit-icon-wrapper">
                  <picture className="jsx-4212101279 picture-component">
                    <img
                      alt="industries icon"
                      className="cont-right-pic-img"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto,w_37/remote_mondaycom_static/img/contact-sales/industries_icon.png"
                    />
                  </picture>
                  <span className="icon-text">
                    Serving <b>200+</b> industries
                  </span>
                </div>
                <hr className="benefit-text-divider" />
                <div className="benefit-text-wrapper">
                  Boost productivity from day one by building your team's ideal
                  workflow
                </div>
              </div>
            </Box>

            <blockquote className="blockquote">
              <p>
                “accpro.uk Work OS saves us about 1,850 hrs of staff time and
                somewhere in the range of $50,000 a month.”
              </p>
              <footer className="blockquote-footer">
                Stefana Muller, Senior Director
              </footer>
            </blockquote>

            <Box className="social">
              <img
                className="social-img"
                alt="Wix"
                src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/wix.png"
              />
              <img
                className="social-img"
                alt="Oxy"
                src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/oxy.png"
              />
              <img
                className="social-img"
                alt="Mars Wrigley"
                src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/mars_wrigley.png"
              />
              <img
                className="social-img"
                alt="Canva"
                src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/canva.png"
              />
              <img
                className="social-img"
                alt="Coca Cola"
                src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/img/customers/logos-v2/coca_cola.png"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ContactPage;
