import React from "react";

function Section1ImageTiles() {
  const Video = ({ poster, source }) => (
    <div
      className="react-player-video-component"
      style={{ width: "100%", height: "100%" }}
    >
      <video
        preload="auto"
        poster={poster}
        playsInline=""
        webkit-playsinline=""
        x5-playsinline=""
        style={{ width: "100%", height: "100%" }}
      >
        <source src={source}></source>
      </video>
    </div>
  );

  return (
    <div className="tile-container">
      <div className="column1 tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto,q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Timeline_column.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured images/Home Page - 2022 Rebrand/Masonry/final/Timeline_column.mp4"
        />
      </div>
      <div className="column2 tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Status_column.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Status_column.mp4"
        />
      </div>
      <div className="battery tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Battery.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Battery.mp4"
        />
      </div>
      <div className="integration tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Integration.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Integration.mp4"
        />
      </div>
      <div className="pie tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Pie.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Pie.mp4"
        />
      </div>
      <div className="phone tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/MobileApp.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/MobileApp.mp4"
        />
      </div>
      <div className="automation tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Automation.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Automation.mp4"
        />
      </div>
      <div className="comment tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Talk.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Talk.mp4"
        />
      </div>
      <div className="dashboard tilein">
        <Video
          poster="https://dapulse-res.cloudinary.com/image/fetch/f_auto/q_auto/https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Dash.jpg"
          source="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Masonry/final/Dash.mp4"
        />
      </div>
    </div>
  );
}

export default Section1ImageTiles;
