import "../css/header.css";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Box,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Header({ isLoggedIn }) {
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <RouterLink
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/images/logo.jpg"
              alt="Website Logo"
              style={{ maxWidth: "50px", marginRight: "10px" }}
            />
          </RouterLink>

          <Box flexGrow={1} />

          <Box display={{ xs: "none", md: "flex" }} sx={{alignItems: "center"}}>
            <Link
              component={RouterLink}
              to="/features"
              color="primary"
              underline="none"
              variant="h6"
              sx={{ mr: 2 }}
            >
              Features
            </Link>
            <Link
              component={RouterLink}
              to="/resources"
              color="primary"
              underline="none"
              variant="h6"
              sx={{ mr: 2 }}
            >
              Resources
            </Link>

            <Link
              component={RouterLink}
              to="/contact"
              color="primary"
              underline="none"
              variant="h6"
              sx={{ mr: 2 }}
            >
              Contact
            </Link>

            {!isLoggedIn ? (
              <Link
                component={RouterLink}
                to="/login"
                color="primary"
                underline="none"
                variant="h6"
                sx={{ mr: 2 }}
              >
                Login
              </Link>
            ) : (
              <Link
                component={RouterLink}
                to="/dashboard"
                color="primary"
                underline="none"
                variant="h6"
                sx={{ mr: 2 }}
              >
                Dashboard
              </Link>
            )}
            <Button
              component={RouterLink}
              to="/get-started"
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Box>

          <Box display={{ xs: "block", md: "none" }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleNavbarToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile */}
      <Drawer anchor="right" open={isNavbarOpen} onClose={handleNavbarToggle}>
        <List>
          <ListItem
            button
            component={RouterLink}
            to="/features"
            onClick={handleNavbarToggle}
          >
            <ListItemText primary="Features" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to="/resources"
            onClick={handleNavbarToggle}
          >
            <ListItemText primary="Resources" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to="/contact"
            onClick={handleNavbarToggle}
          >
            <ListItemText primary="Contact" />
          </ListItem>
          {!isLoggedIn ? (
            <ListItem
              button
              component={RouterLink}
              to="/login"
              onClick={handleNavbarToggle}
            >
              <ListItemText primary="Login" />
            </ListItem>
          ) : (
            <ListItem
              button
              component={RouterLink}
              to="/dashboard"
              onClick={handleNavbarToggle}
            >
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}
          <ListItem
            button
            component={RouterLink}
            to="/get-started"
            onClick={handleNavbarToggle}
          >
            <ListItemText primary="Get Started" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default Header;
