import React, { useState, useEffect, useContext } from "react";
import DeleteSvg from "../svg/delete";
import AlertContext from "../components/AlertContext";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PlaylistPlay from "@mui/icons-material/PlaylistPlay";

function ScriptComponent({
  data,
  setData,
  fields,
  handleInputChange,
  deleteRow,
  addNewRow,
  selectedRows,
  setSelectedRows,
  inputRefs,
  isScreenCaptureEnabled,
  setScreenCaptureEnabled,
  isTestModeEnabled,
  setTestModeEnabled,
  isLoading,
  setIsLoading,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [hiddenFields, setHiddenFields] = useState([]);
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "v") {
        pasteTableFromClipboard();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const pasteTableFromClipboard = async () => {
    var clipboardData;
    try {
      clipboardData = await navigator.clipboard.readText();
    } catch {
      setAlert("Pasting not supported in this browser.", "error");
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(clipboardData, "text/html");
    const table = doc.querySelector("table");

    if (table) {
      // const headers = [];
      // // console.log(table)
      // const headerCells = table.querySelectorAll("thead tr th");
      // headerCells.forEach((cell) => {
      //   headers.push(cell.innerText.trim());
      // });

      const rows = [];
      const bodyRows = table.querySelectorAll("tbody tr");
      bodyRows.forEach((row) => {
        const rowData = [];
        const cells = row.querySelectorAll("td");
        cells.forEach((cell) => {
          rowData.push(cell.innerText.trim());
        });
        rows.push(rowData);
      });

      // Now you have the headers and rows from the pasted table.
      // Update your component's state or props accordingly.
      // console.log(headers, rows);
      if (data.length !== rows.length) {
        setAlert("Number of rows mismatch.", "error");
        return;
      }

      if (data[0] && rows[0] && data[0].length !== rows[0].length) {
        setAlert("Number of columns mismatch.", "error");
        return;
      }
      setData(rows);
    } else {
      setAlert("No table found to paste.", "error");
    }
  };

  const handleCheckboxChange = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows((prev) => prev.filter((index) => index !== rowIndex));
    } else {
      setSelectedRows((prev) => [...prev, rowIndex]);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addNewRow();
    }
  };
  const deleteSelected = () => {
    // Inform the parent component to update its state
    deleteRow(null, selectedRows);
  };

  const toggleFieldVisibility = (fieldId) => {
    if (hiddenFields.includes(fieldId)) {
      setHiddenFields((prev) => prev.filter((id) => id !== fieldId));
    } else {
      setHiddenFields((prev) => [...prev, fieldId]);
    }
  };

  const handleSelectAllChange = () => {
    // console.log("HEREEC LCOCOCOOC");
    if (!selectAll) {
      setSelectedRows([...Array(data.length).keys()]);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  return (
    <div className="script-right-in">
      <div className="d-flex gap-2 flex-wrap justify-content-left align-items-center mb-3 script-tools">
        <Checkbox checked={selectAll} onClick={handleSelectAllChange} />

        <IconButton className="delete-mass" onClick={deleteSelected}>
          <DeleteIcon />
        </IconButton>

        <div className="table-new-row-ab">
          <IconButton className="table-new-row" onClick={addNewRow}>
            <AddIcon />
          </IconButton>
        </div>

        <IconButton
          className="dropdown-togg"
          onClick={() => setDropdownVisibility(!isDropdownVisible)}
        >
          {isDropdownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>

        <div className="additional-sections">
          <FormControlLabel
            control={
              <Checkbox
                checked={isScreenCaptureEnabled}
                onChange={() =>
                  setScreenCaptureEnabled(!isScreenCaptureEnabled)
                }
              />
            }
            // label={<CameraAltIcon />}
            label="Screen capture"
          />

          <FormControlLabel
          // label={<PlaylistPlay />}
          label="Test Mode"
            control={
              <Checkbox
                checked={isTestModeEnabled}
                onChange={() => setTestModeEnabled(!isTestModeEnabled)}
              />
            }
            
          />
        </div>
      </div>

      <div className={`dropdown-content ${isDropdownVisible ? "visible" : ""}`}>
        {hiddenFields.length === 0 ? (
          <span className="hid-lab">No hidden fields</span>
        ) : (
          fields.map(
            (field) =>
              hiddenFields.includes(field.id) && (
                <FormControlLabel
                  key={field.id}
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => toggleFieldVisibility(field.id)}
                    />
                  }
                  label={field.name}
                />
              )
          )
        )}
      </div>

      <TableContainer component={Paper} className="table-responsive">
        <Table className="script-table">
          <TableHead className="script-table-head">
            <TableRow className="script-table-tr-th">
              <TableCell>Actions</TableCell>
              {fields.map(
                (field) =>
                  !hiddenFields.includes(field.id) && (
                    <TableCell className="script-table-th" key={field.id}>
                      {field.name}
                      <Checkbox
                        checked={false}
                        onChange={() => toggleFieldVisibility(field.id)}
                      />
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow className="script-table-tr" key={rowIndex}>
                <TableCell sx={{ display: "flex" }}>
                  <Checkbox
                    onChange={() => handleCheckboxChange(rowIndex)}
                    checked={selectedRows.includes(rowIndex)}
                  />
                  <IconButton
                    className="delete-row"
                    onClick={(e) => deleteRow(e, rowIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                {row.map(
                  (cell, cellIndex) =>
                    !hiddenFields.includes(fields[cellIndex].id) && (
                      <TableCell key={cellIndex}>
                        <input
                          className="script-table-td"
                          value={cell}
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, cellIndex)
                          }
                          onKeyPress={handleKeyPress}
                          ref={(el) =>
                            (inputRefs.current[
                              rowIndex * fields.length + cellIndex
                            ] = el)
                          }
                        />
                      </TableCell>
                    )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ScriptComponent;
