import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/edit.css";
import { SendRequest } from "../api";

import AlertContext from "../components/AlertContext";

import {
  Button,
  TextField,
  FormControlLabel,
  Container,
  Grid,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import EditorTop from "../components/EditorTop";
import EditorBottom from "../components/EditorBottom";

function EditPage() {
  const [scriptCategories, setScriptCategories] = useState([]);

  const [isTemplate, setIsTemplate] = useState(false);
  const [pages, setPages] = useState([]);
  const [params, setParams] = useState([]);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [scripts, setScripts] = useState([]);
  const [customWidgets, setCustomWidgets] = useState([]);
  const [editor, setEditor] = useState(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pagesLoaded, setPagesLoaded] = useState(false);
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedPagesLoaded, setSelectedPagesLoaded] = useState(false);
  const [customWidgetEntries, setCustomWidgetEntries] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentAuthor, setCurrentAuthor] = useState("");
  const [userAllowedToResolve, setUserAllowedToResolve] = useState("Admin");
  const [isSaved, setIsSaved] = useState(true);

  const stateChangeCounter = useRef(0);
  const usersRef = useRef([]);
  const [selectedStatusFromChild, setSelectedStatusFromChild] = useState(null);
  const [selectedTypeFromChild, setSelectedTypeFromChild] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { id, folderid, pageid } = useParams();

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    const initializeUsers = async () => {
      // console.log("RUNNING initializeUsers");
      const response = await fetchUser();
      setUsers(response.users);
      setCurrentAuthor(response.current_user);
      setUserAllowedToResolve(response.admin);
    };

    initializeUsers();
  }, [id]);

  const fetchUser = async () => {
    const response = await SendRequest(`/api/getusers/${id}/`, "GET");
    return response;
  };

  useEffect(() => {
    const fetchCustomWidgetEntries = async () => {
      try {
        const response = await SendRequest(
          `/api/customwidgetentry/?pageid=${pageid}`,
          "GET"
        );
        // console.log(response);
        if (response.error) {
          setAlert(response.error, "error");
        } else {
          setCustomWidgetEntries(response.results);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    };

    const fetchScriptCategories = async () => {
      try {
        const response = await SendRequest(`/api/getscriptcategories/`, "GET");
        // console.log(response);
        if (response.error) {
          setAlert(response.error, "error");
        } else {
          setScriptCategories(response.categories);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    };

    fetchCustomWidgetEntries();
    fetchScriptCategories();
  }, [pageid]);

  useEffect(() => {
    // console.log("SCRIPT CAT CHANGED", scriptCategories);
  }, [scriptCategories]);

  const handleMakeTemplate = async () => {
    //  console.log('CLICKED')

    setIsLoading(true);
    const response = await SendRequest(
      `/api/maketemplate/${id}/${pageid}/`,
      "POST"
    );

    if (response.error) {
      // alert(response.error);
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      // console.log("YES GOOT", response);
      setIsTemplate(response.is_template);
      setIsLoading(false);
    }
  };

  window.deleteParameter = async (event, id) => {
    setIsLoading(true);
    const data = { action: "-1", id: id };
    const response = await SendRequest(
      `/api/parameter/${pageid}/`,
      "POST",
      data
    );

    if (response.error) {
      // alert(response.error);
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      event.target.parentElement.remove();
      handlePublish(2);
    }
  };

  window.deleteChart = async (event, id) => {
    setIsLoading(true);
    const response = await SendRequest(`/api/charts/${id}/`, "DELETE");

    if (response.error) {
      // alert(response.error);
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      event.target.parentElement.parentElement.remove();
      handlePublish(null);
    }
  };

  window.deleteScript = async (event, id) => {
    setIsLoading(true);
    const response = await SendRequest(`/api/scriptentries/${id}/`, "DELETE");

    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      event.target.parentElement.parentElement.remove();
      handlePublish(null);
    }
  };

  window.deleteReport = async (event, id) => {
    setIsLoading(true);
    const response = await SendRequest(`/api/report/${id}/`, "DELETE");
    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      event.target.parentElement.parentElement.remove();
      handlePublish(null);
    }
  };

  window.deleteCustomWidget = async (event, id, type = 1) => {
    setIsLoading(true);
    const response = await SendRequest(
      `/api/customwidgetentry/${id}/`,
      "DELETE"
    );

    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      if (type == 1) {
        event.target.parentElement.parentElement.remove();
        handlePublish(null);
      }
      setCustomWidgetEntries((prevEntries) =>
        prevEntries.filter((widget) => widget.id !== id)
      );

      setIsLoading(false);
    }
  };

  // TODO : if no problem , remove later
  // const handleStatusChange = (status) => {
  //   setSelectedStatusFromChild(status);
  // };

  // const handleTypeChange = (type) => {
  //   setSelectedTypeFromChild(type);
  // };

  const navigate = useNavigate();

  const fetchParamData = async () => {
    const response1 = await SendRequest(`/api/params/${pageid}/`, "GET");
    // console.log("PARAMS ->>> ", response1.result);
    if (response1.error) {
      setAlert(response1.error, "error");
      setParamsLoaded(true);
    } else {
      setParams(response1.result);
      setParamsLoaded(true);
    }
  };

  useEffect(() => {
    const fetchPageData = async () => {
      setPagesLoaded(false);

      try {
        // Start both requests simultaneously
        const [response, response1] = await Promise.all([
          SendRequest(`/api/pages/${pageid}/`, "GET"),
          SendRequest(`/api/customwidgets/`, "GET"),
        ]);

        // Process the response of the first request
        if (response.error) {
          setAlert(response.error, "error");
        } else {
          setTitle(response.name);
          setContent(response.content);
          setIsTemplate(response.is_template);
        }

        // Process the response of the second request
        if (!response1.error && response1.results) {
          setCustomWidgets(response1.results);
        } else {
          console.error("Error fetching custom widgets:", response1.error);
        }
      } catch (error) {
        // Handle any error that occurred during the requests
        console.error("Error during fetching data:", error);
      } finally {
        // Set pages as loaded after processing both responses
        setPagesLoaded(true);
      }
    };

    if (pageid) {
      fetchPageData();
    }
  }, [pageid]);

  const handlePublish = async (typee) => {
    // console.log("PUBLISHING ====>>>>>>");
    setIsLoading(true);
    const stat = selectedStatusFromChild ? selectedStatusFromChild.id : null;
    const typ = selectedTypeFromChild ? selectedTypeFromChild.id : null;
    const pageIds = selectedPages.map((page) => page.id);

    let contentDom = editor.dom.getRoot();
    let clonedContentDom = contentDom.cloneNode(true);
    let elementsToClear = clonedContentDom.querySelectorAll(
      ".custom-widget-content, .chart-div, .script-content, .report-content"
    );

    // console.log("elements to clear --> ", elementsToClear);
    elementsToClear.forEach((el) => (el.innerHTML = ""));
    let serializer = new XMLSerializer();
    let cleanedContent = serializer.serializeToString(clonedContentDom);
    // console.log("cleaned content", cleanedContent);

    const data = {
      name: title,
      content: cleanedContent,
      folderid: folderid,
      status_id: stat,
      type_id: typ,
      referenced_pages: pageIds,
    };

    var response;
    if (pageid) {
      response = await SendRequest(`/api/pages/${pageid}/`, "PUT", data);
    } else {
      response = await SendRequest("/api/pages/", "POST", data);
    }
    // console.log(response);

    if (response.error) {
      setAlert(response.error, "error");
      setIsLoading(false);
    } else {
      // handle successful response
      if (pageid) {
        // console.log(typee);
        if (typee == 0) {
          setAlert("Published", "info");
        } else if (typee == 1) {
          setAlert("Parameter added", "info");
        } else if (typee == 2) {
          setAlert("Parameter deleted", "info");
        } else {
        }
        setIsLoading(false);
        setIsSaved(true);
      } else {
        navigate(`/project/${id}/${folderid}/${response.id}/?type=0`);
      }
    }
  };

  useEffect(() => {
    // Increment the counter each time the effect runs
    stateChangeCounter.current += 1;

    // Skip the effect for the first two state changes
    // console.log(selectedPages, "sel pages");
    if (
      // (selectedPages.length == 1 && stateChangeCounter.current > 1) ||
      editorLoaded
      // && stateChangeCounter.current > 2
    ) {
      // console.log("hmm... RUNNINGGGGG---->>>>>>>>>>>>>>>>>>>..");
      async function updateReferences() {
        setIsLoading(true);
        // Send request to save reference pages
        const response = await SendRequest(
          `/api/savereferencepages/${pageid}/`,
          "POST",
          { pages: selectedPages }
        );

        if (response && response.error) {
          setAlert(response.error, "error");
          setIsLoading(false);
        } else if (response) {
          // Get current content of the TinyMCE editor
          const currentContent = editor.getContent();
          // Create a DOM parser to manipulate the content
          const parser = new DOMParser();
          const doc = parser.parseFromString(currentContent, "text/html");
          // Select all elements with classname "reference-item"
          const referenceItems = doc.querySelectorAll(".reference-item");
          // Loop through each reference item
          referenceItems.forEach((refElement) => {
            // Get the data-ref-id attribute
            const refId = refElement.getAttribute("data-ref-id");
            // Find the corresponding item from the response using the refId
            const reference = response.references.find(
              (item) => item.name === refId
            );
            // Get the child of refElement with class "ref-cont"
            const refContElement = refElement.querySelector(".ref-cont");
            // console.log("HEYYY HOOOOOiiii -> ", reference);
            if (reference) {
              if (refContElement) {
                // Replace the innerHTML of refContElement with reference content
                refContElement.innerHTML = reference["content"];
              }
            } else {
              // If reference not found, show an error message
              if (refContElement) {
                refContElement.innerHTML = "Error : Parameter not found!!";
              }
            }
          });

          // Set the modified content back to the TinyMCE editor
          editor.setContent(doc.documentElement.innerHTML);

          // Run handlePublish with null parameter
          handlePublish(null);
        }
      }

      // Call the updateReferences function
      updateReferences();
      if (pageid) {
        fetchParamData();
      }
    }
  }, [selectedPages]);

  useEffect(() => {
    const fetchPageData = async () => {
      setScriptsLoaded(false);
      const response1 = await SendRequest(`/api/scripts/`, "GET");
      // console.log("THIS IS SCRIPTS => ", response1);
      if (response1.error) {
        setAlert(response1.error, "error");
        setScriptsLoaded(true);
      } else {
        setScripts(response1.results);
        setScriptsLoaded(true);
      }
    };

    fetchPageData();
    if (pageid) {
      fetchParamData();
    }
  }, []);

  const handleEditorChange = (content) => {
    setContent(content);
    setIsSaved(false);
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1400",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <Container className="container-fluid vh-100 justify-content-center edit-page">
        <EditorTop
          scriptCategories={scriptCategories}
          scripts={scripts}
          setScripts={setScripts}
          setAlert={setAlert}
          users={users}
          setCustomWidgets={setCustomWidgets}
          setIsLoading={setIsLoading}
          title={title}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
          setSelectedPagesLoaded={setSelectedPagesLoaded}
          pages={pages}
          setPages={setPages}
          handleMakeTemplate={handleMakeTemplate}
          pageid={pageid}
          isTemplate={isTemplate}
          editor={editor}
          isSaved={isSaved}
          handlePublish={handlePublish}
          customWidgets={customWidgets}
          customWidgetEntries={customWidgetEntries}
        />

        <EditorBottom
          setCustomWidgetEntries={setCustomWidgetEntries}
          users={users}
          usersRef={usersRef}
          customWidgetEntries={customWidgetEntries}
          selectedPages={selectedPages}
          params={params}
          paramsLoaded={paramsLoaded}
          selectedPagesLoaded={selectedPagesLoaded}
          scriptsLoaded={scriptsLoaded}
          scripts={scripts}
          //
          title={title}
          setTitle={setTitle}
          editor={editor}
          setAlert={setAlert}
          setIsLoading={setIsLoading}
          handlePublish={handlePublish}
          pages={pages}
          setEditorLoaded={setEditorLoaded}
          setEditor={setEditor}
          content={content}
          handleEditorChange={handleEditorChange}
          customWidgets={customWidgets}
          userAllowedToResolve={userAllowedToResolve}
          currentAuthor={currentAuthor}
        />
      </Container>
    </>
  );
}

export default EditPage;
