import "../css/footer.css";

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Container, Grid, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
function Footer() {
  const location = useLocation();
  // console.log("HEY LOC , ", location);
  const allowedPaths = ["/", "/contact", "/features", "/resources","/terms","/privacy"];

  if (!allowedPaths.includes(location.pathname)) return null;

  return (
    <Container component="footer" >
      <Typography textAlign="center" sx={{marginBottom:"20px"}}>All Rights Reserved © accpro</Typography>

      <Grid container justifyContent="center" spacing={2} sx={{marginBottom:"10px"}}>
        <Grid item>
          <Link component={RouterLink} to="/terms">
            Terms
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to="/privacy">
            Privacy Policy
          </Link>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <IconButton
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/facebook.svg" alt="Facebook" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/twitter.svg" alt="Twitter" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/linkedin.svg" alt="Linked In" />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/youtube.svg" alt="Youtube" />
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
