import React, { useEffect, useState, useRef } from "react";
import { SendRequest } from "../api";
import Button from "@mui/material/Button";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";

function KanbanChartComponent({ data, setLoading, project_id }) {
  const [updatedKanbanData, setUpdatedKanbanData] = useState(data.chart_data);
  const [widgetData, setWidgetData] = useState(data.status_id);
  const kanbanRef = useRef(null);

  const saveToServer = async () => {
    try {
      setLoading(true);

      const idToChoiceMap = Object.entries(widgetData).reduce(
        (acc, [key, value]) => {
          acc[value] = key;
          return acc;
        },
        {}
      );

      // console.log("updated kanban", updatedKanbanData);

      const currentKanbanData = kanbanRef.current.kanbanData;

      let newKanbanData = currentKanbanData.map((card) => {
        const choiceId = card.StatusId;
        // console.log("choiceId", choiceId);
        const choiceKey = idToChoiceMap[choiceId];
        if (choiceKey !== undefined) {
          return { ...card, SelectedChoice: choiceKey };
        }
        return card;
      });

      const response = await SendRequest(`/api/charts/${data.id}/`, "PUT", {
        chart_data: newKanbanData,
        project_id: project_id,
      });
      if (response.error) {
        alert("Something went wrong");
      } else {
        // console.log("Data saved successfully");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleActionComplete = (args) => {
    if (
      args.requestType === "cardChanged" ||
      args.requestType === "cardAdded" ||
      args.requestType === "cardRemoved"
    ) {
      setUpdatedKanbanData([...kanbanRef.current.kanbanData]);
    }
  };

  return (
    <>
      <Button onClick={saveToServer} color="primary">
        Save
      </Button>

      <KanbanComponent
        ref={kanbanRef}
        keyField="StatusId"
        id="kanban"
        allowDragAndDrop={true}
        dataSource={updatedKanbanData}
        actionComplete={handleActionComplete}
        cardSettings={{
          headerField: "Name",
          selectionType: "Multiple",
        }}
      >
        <ColumnsDirective>
          {Object.entries(widgetData).map(([status, id]) => (
            <ColumnDirective key={id} headerText={status} keyField={id} />
          ))}
        </ColumnsDirective>
      </KanbanComponent>
    </>
  );
}

export default KanbanChartComponent;
