import React, { useEffect, useState, useContext } from "react";
import { SendRequest } from "../api"; // Assuming you've this utility function
import PopupComponent from "./PopupComponent"; // Assuming you've this component
import { useParams } from "react-router-dom";

import AlertContext from "./AlertContext";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import LoadingComponent from "./LoadingComponent";

function ProjectUsers() {
  const [users, setUsers] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [valuesInvite, setValuesInvite] = useState([""]);
  const [errorsInvite, setErrorsInvite] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Replace with actual project id
  const { id } = useParams();

  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await SendRequest(`/api/getusers/${id}/`, "GET");
      setUsers(response.users);
      // console.log(response.users);
      setIsLoading(false);
    };

    fetchUsers();
  }, []);

  const handleInviteChange = (name, value) => {
    let updatedValues = [...valuesInvite];
    updatedValues[0] = value;

    if (!updatedValues[0]) {
      setErrorsInvite(["Email cannot be empty"]);
    } else {
      setErrorsInvite([]);
    }

    setValuesInvite(updatedValues);
  };

  const inviteUser = async () => {
    setIsLoading(true);
    const data = { email: valuesInvite[0], project: id };
    const response = await SendRequest(`/api/adduser/`, "POST", data);

    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      // Assuming the response contains the invited user's details
      if (response.user) {
        // Add the new user to the users list
        setUsers((prevUsers) => [...prevUsers, response.user]);
      } else {
        setAlert("User added but no user details were returned.", "info");
      }
    }
    setIsLoading(false);
  };

  const handleInviteAgain = async (userId) => {
    // console.log(userId);
    // return
    setIsLoading(true);
    try {
      const response = await SendRequest(
        `/api/invite/${id}/${userId}/`,
        "POST"
      );

      if (response.error) {
        setAlert(response.error, "error");
      } else {
        setAlert(response.message, "info");
      }
    } catch (error) {
      setAlert("An unexpected error occurred.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveUser = async (userId) => {
    setIsLoading(true);
    try {
      const response = await SendRequest(
        `/api/removeuser/${id}/${userId}/`,
        "DELETE"
      );

      if (response.error) {
        setAlert(response.error, "error");
      } else {
        // If successful, remove the user from the local `users` state
        const updatedUsers = users.filter((user) => user.id !== userId);
        setUsers(updatedUsers);
      }
    } catch (error) {
      setAlert("An unexpected error occurred.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const invitePopupData = {
    heading: "Invite users",
    fields: ["email"],
    placeholders: ["Type user email."],
    labels: ["Email"],
    values: valuesInvite,
    buttonLabel: "Invite",
    buttonAction: () => {
      inviteUser();
      setShowInviteModal(false);
    },
    showModal: showInviteModal,
    handleCloseModal: () => {
      setShowInviteModal(false);
    },
    onValueChange: handleInviteChange,
    errors: errorsInvite,
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInviteModal(true)}
        >
          Add user
        </Button>
        <PopupComponent {...invitePopupData} />
      </Box>

      {users.length === 0 ? (
        <Alert severity="info">No users</Alert>
      ) : (
        <Box sx={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="warning"
                      size="small"
                      sx={{ mr: 2 }}
                      onClick={() => handleInviteAgain(user.id)}
                    >
                      Invite Again
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => handleRemoveUser(user.id)}
                    >
                      Remove User
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Container>
  );
}
export default ProjectUsers;
