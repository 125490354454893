import React, { useState, useEffect, useRef } from "react";

import { SendRequest } from "../api";
import {
  Select,
  FormControl,
  MenuItem,
  Input,
  Box,
  CircularProgress,
} from "@mui/material";

function CustomWidgetEntryComponent() {
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const widgetEntryId = urlParams.get("widgetentryid");
  const pageId = urlParams.get("pageid");
  const customwidgetId = urlParams.get("customwidgetid");
  const type = parseInt(urlParams.get("type"));
  const choices = urlParams.get("choices").split(",");
  const selected = urlParams.get("selected_choice") || "";
  const numericValueFromUrl = urlParams.get("numeric_value") || 0;
  const usersParam = urlParams.get("users");
  const usersFromUrl = usersParam
    ? usersParam.split(",").map((email) => ({ id: email, email: email }))
    : [];

  // console.log("usersFromUrl", usersFromUrl);

  const [selectedChoice, setSelectedChoice] = useState(
    selected === "null" ? "" : selected
  );
  const [numericValue, setNumericValue] = useState(
    numericValueFromUrl === "null" ? 0 : numericValueFromUrl
  );

  useEffect(() => {
    document.body.style = "background-color: #bbb;overflow: hidden;";
    document.documentElement.style = "background-color: #bbb;overflow: hidden;";

    return () => {
      document.body.style = ""; // Reset to original style
      document.documentElement.style = ""; // Reset to original style
    };
  }, []); // Empty dependency array ensures this runs once when component mounts and once when it unmounts

  const handleDropdownOpen = () => {
    // Get the iframe using the window property and adjust its height
    window.frameElement.style.height = "200px";
  };

  const handleDropdownClose = () => {
    window.frameElement.style.height = "70px";
  };

  const debounceTimeout = useRef(null);

  const handleAPIUpdate = async () => {
    let data = { page: pageId, custom_widget: customwidgetId };
    if (type === 0 && selectedChoice !== selected) {
      data.selected_choice = selectedChoice;
    } else if (type === 1 && numericValue !== numericValueFromUrl) {
      data.numeric_value = numericValue;
    } else if (type === 2 && selectedChoice !== selected) {
      data.selected_choice = selectedChoice;
    } else {
      return; // No change detected
    }
    setIsLoading(true);

    try {
      const response = await SendRequest(
        `/api/customwidgetentry/${widgetEntryId}/`,
        "PUT",
        data
      );
      // console.log("Update response:", response);
    } catch (error) {
      console.error("Error updating widget entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDropdownChange = async (e) => {
    const newValue = e.target.value;
    setSelectedChoice(newValue);

    // Update the data immediately using the newValue
    let data = { page: pageId, custom_widget: customwidgetId };
    if (type === 0) {
      data.selected_choice = newValue;
    } else if (type === 2) {
      data.selected_choice = newValue;
    } else {
      return;
    }

    setIsLoading(true);
    try {
      const response = await SendRequest(
        `/api/customwidgetentry/${widgetEntryId}/`,
        "PUT",
        data
      );
      // console.log("Update response:", response);
    } catch (error) {
      console.error("Error updating widget entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    // console.log("setNumericValue" , e.target.value)
    const value = e.target.value === '' ? 0 : e.target.value;
    setNumericValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      handleAPIUpdate();
    }, 1000); // 300ms debounce time
  };

  return (
    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1400",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      {type === 0 && (
        <Select
          value={selectedChoice}
          onChange={handleDropdownChange}
          displayEmpty
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          style={{
            position: "static",
            maxWidth: "270px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "inherit",
                maxWidth: "270px",
                overflowWrap: "anywhere",
                textWrap: "pretty",
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Select a choice
          </MenuItem>
          {choices.map((choice) => (
            <MenuItem
              key={choice}
              value={choice}
              style={{
                maxWidth: "270px",
                overflowWrap: "anywhere",
                textWrap: "pretty",
              }}
            >
              {choice}
            </MenuItem>
          ))}
        </Select>
      )}

      {type === 1 && (
        <Input
          type="number"
          pattern="\d*"
          value={numericValue}
          onChange={handleInputChange}
          style={{ margin: "5px" }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      )}

      {type === 2 && (
        <Select
          value={selectedChoice}
          onChange={handleDropdownChange}
          displayEmpty
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          style={{
            position: "static",
            maxWidth: "270px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "inherit",
                maxWidth: "270px",
                overflowWrap: "anywhere",
                textWrap: "pretty",
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            Select a user
          </MenuItem>
          {usersFromUrl.map((user) => (
            <MenuItem
              key={user.id}
              value={user.email}
              style={{
                maxWidth: "270px",
                overflowWrap: "anywhere",
                textWrap: "pretty",
              }}
            >
              {user.email}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
}

export default CustomWidgetEntryComponent;
