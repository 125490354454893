import React, { useEffect, useState } from "react";

import "./App.css";

import {
  CssBaseline,
  Container,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { SendRequest } from "./api";
import AlertDialog from "./components/AlertDialog";
import AlertContext from "./components/AlertContext";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";
import LoginPage from "./pages/LoginPage";
import FeaturesPage from "./pages/FeaturesPage";
import ContactPage from "./pages/ContactPage";
import ResourcesPage from "./pages/ResourcesPage";
import LandingPage from "./pages/LandingPage";
import GetStarted from "./pages/GetStartedPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import DashboardPage from "./pages/DashboardPage";
import AllProjectsPage from "./pages/AllProjectsPage";
import ProjectPage from "./pages/ProjectPage";
import EditPage from "./pages/EditPage";
import ScriptPage from "./pages/ScriptPage";
import ScriptDocPage from "./pages/ScriptDocPage";

import "bootstrap/dist/css/bootstrap.min.css";
import TemplatesPage from "./pages/TemplatesPage";
import NotFoundPage from "./pages/NotFoundPage";
import LoadingComponent from "./components/LoadingComponent";
import SubDomainsPage from "./pages/SubDomainsPage";
import SubDomainsVisibilityPage from "./pages/SubDomainsVisibilityPage";
import ReportPage from "./pages/ReportPage";

import { registerLicense } from "@syncfusion/ej2-base";
import CustomWidgetEntryComponent from "./components/CustomWidgetEntryComponent";
import ChartPage from "./pages/ChartPage";
import AcceptUser from "./pages/AcceptUser";

registerLicense(
  "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5ad0BiWX5YcHVWR2NU"
);

function App() {
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [subDomain, setSubdomain] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await SendRequest("/api/check_logged_in/", "GET");
        // console.log("USERRR ->>> ", response);
        if (response.isLoggedIn) {
          setIsLoggedIn(true);
          setUser(response.user);
        }
        if (response.is_admin) {
          setIsAdmin(true);
        }
        // console.log('HEREE super', isSuperAdmin)
        if (response.user.is_superuser) {
          setIsSuperAdmin(true);
        }
      } catch (error) {
        setIsLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };

    checkLoggedIn();
  }, []);

  const setAlert = (message, type) => {
    setAlertState({
      open: true,
      message,
      type,
    });
  };

  const handleClose = () => {
    setAlertState((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    const domain = window.location.host;
    setIsSubdomain(domain.split(".").length > 1);
    setSubdomain(domain.split(".")[0]);
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3473e8", // This sets the primary color used by components like Link
      },
      secondary: {
        main: "#ef943a", // This will be the background color of the button
        contrastText: "#ffff", // This will be the text color of the button
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            backgroundColor: "#ef943a", // button's background color
            color: "#ffff", // button's text color
            "&:hover": {
              backgroundColor: "#d58039", // slightly darker shade for hover effect
            },
          },
        },
      },
    },
    typography: {
      fontFamily: "Poppins, Arial, sans-serif", // Set Poppins as the default font
      fontWeight: "400",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <AlertContext.Provider value={{ setAlert }}>
          <AlertDialog
            open={alertState.open}
            type={alertState.type}
            message={alertState.message}
            onClose={handleClose}
          />

          {loading ? (
            <LoadingComponent />
          ) : (
            <div>
              <MainHeader
                isSubdomain={isSubdomain}
                subdomain={subDomain}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                user={user}
                isLoggedIn={isLoggedIn}
              />
              <div className="main">
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/features" element={<FeaturesPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/resources" element={<ResourcesPage />} />
                  <Route path="/get-started" element={<GetStarted />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route
                    path="/dashboard"
                    element={
                      <DashboardPage
                        isAdmin={isAdmin}
                        user={user}
                        isLoggedIn={isLoggedIn}
                        isSuperAdmin={isSuperAdmin}
                      />
                    }
                  />

                  <Route path="/projects" element={<AllProjectsPage />} />
                  <Route path="/subdomains" element={<SubDomainsPage />} />
                  <Route
                    path="/subdomains/:subdomainid/"
                    element={<SubDomainsVisibilityPage />}
                  />
                  <Route
                    path="/project/:id/:folderid?/:pageid?"
                    element={
                      <ProjectPage
                        isAdmin={isAdmin}
                        isSuperAdmin={isSuperAdmin}
                      />
                    }
                  />
                  <Route
                    path="/edit/:id/:folderid?/:pageid?"
                    element={<EditPage />}
                  />
                  <Route
                    path="/script/:scriptid/"
                    element={<ScriptPage isAdmin={isAdmin} />}
                  />
                  <Route
                    path="/scriptdoc/:scriptid/"
                    element={<ScriptDocPage />}
                  />
                  <Route
                    path="/templates/:folderid/"
                    element={<TemplatesPage />}
                  />
                  <Route
                    path="/widgetentry/:pageid/"
                    element={<CustomWidgetEntryComponent />}
                  />
                  <Route path="/report/:reportid/" element={<ReportPage />} />
                  <Route path="/chart/:project_id/:chartid/" element={<ChartPage />} />

                  <Route path="/acceptuser/:projectid/:userid/" element={<AcceptUser />} />
                  <Route path="/404/" element={<NotFoundPage />} />
                </Routes>
              </div>

              <Footer />
            </div>
          )}
        </AlertContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
