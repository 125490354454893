import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProjectRightOverviewPage from "./ProjectRightOverviewPage";
import ProjectRightAllPages from "./ProjectRightAllPages";
import ProjectRightPage from "./ProjectRightPage";
import ProjectUsers from "./ProjectUsers";
import Container from "@mui/material/Container";

function ProjectRight({ isAdmin, type, dictionary, mainDictionary }) {
  const location = useLocation();
  const { id, folderid, pageid } = useParams();
  const [allPages, setAllPages] = useState([]);

  const isCustomPage = folderid && pageid;
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const isUsers = pathSegments[pathSegments.length - 1] === "users";
  const isAllPages = folderid && !pageid && !isUsers;
  const isOverview = !folderid && !pageid && !isUsers;

  useEffect(() => {
    if (type == 0) {
      const folder = dictionary.find((item) => item.id.toString() === folderid);
      if (folder) {
        setAllPages(folder.pages);
      } else {
        setAllPages([]);
      }
    } else {
      const folder = mainDictionary.find(
        (item) => item.id.toString() === folderid
      );
      if (folder) {
        setAllPages(folder.pages);
      } else {
        setAllPages([]);
      }
    }
  }, [dictionary, folderid , type]);

  return (
    <Container className="mt-2 proj-cont">
      {isUsers && <ProjectUsers />}
      {isOverview && <ProjectRightOverviewPage />}
      {isCustomPage && <ProjectRightPage />}
      {isAllPages && <ProjectRightAllPages type={type} folderData={allPages} />}
    </Container>
  );
}

export default ProjectRight;
