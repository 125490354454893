function RenameSvg() {
  return (
    <svg
      role="presentation"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6L6.85074 6.00549C5.81588 6.08183 5 6.94564 5 8V16L5.00549 16.1493C5.08183 17.1841 5.94564 18 7
                  18H11L11.1166 17.9933C11.614 17.9355 12 17.5128 12 17C12 16.4477 11.5523 16 11 16H7V8H11L11.1166 7.99327C11.614
                  7.93551 12 7.51284 12 7C12 6.44772 11.5523 6 11 6H7Z"
        fill="currentColor"
      ></path>
      <path
        d="M14 6C13.4477 6 13 5.55228 13 5C13 4.44772 13.4477 4 14 4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523
                  6 18 6H17V18H18C18.5523 18 19 18.4477 19 19C19 19.5523 18.5523 20 18 20H14C13.4477 20 13 19.5523 13 19C13 18.4477
                  13.4477 18 14 18H15V6H14Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
export default RenameSvg;
