import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { SendRequest } from "../api";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
} from "@syncfusion/ej2-react-schedule";

function SchedulerChartComponent({ data, setLoading }) {
  const [updatedScheduleData, setUpdatedScheduleData] = useState(
    data.chart_data
  );
  const [chartid, setChartId] = useState(data.id);
  const scheduleRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [participants, setParticipants] = useState([{ name: "", email: "" }]);
  const [mailSent, setMailSent] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    const anyInvalidParticipant = participants.some(
      (p) => !p.name.trim() || !p.email.trim()
    );
    const isTitleAndLinkValid = title.trim() && link.trim();

    setIsValid(!anyInvalidParticipant && isTitleAndLinkValid);
  }, [participants, title, link]);

  const addParticipant = () => {
    setParticipants([...participants, { name: "", email: "" }]);
  };

  const removeParticipant = (index) => {
    const newParticipants = [...participants];
    newParticipants.splice(index, 1);
    setParticipants(newParticipants);
  };

  const handleSendMail = async () => {
    setLoading(true);
    const response = await SendRequest(`/api/sendmail/`, "POST", {
      participants: participants,
      title: title,
      link: link,
    });

    if (response && response.error) {
      console.log("Error:", response.error);
    } else {
      setMailSent(true);
      setOpenDialog(false);
    }
    setLoading(false);
  };

  const onActionComplete = (e) => {
    if (
      e.requestType === "eventCreated" ||
      e.requestType === "eventChanged" ||
      e.requestType === "eventRemoved"
    ) {
      // console.log("Event changed");
      setUpdatedScheduleData([...scheduleRef.current.eventSettings.dataSource]);
    }

    if (e.requestType === "eventCreated") {
      // console.log("Event Added");
      setOpenDialog(true);
    }
  };

  const saveToServer = async () => {
    try {
      setLoading(true);
      const response = await SendRequest(`/api/charts/${chartid}/`, "PUT", {
        chart_data: updatedScheduleData,
      });
      if (response.error) {
        alert("Something went wrong");
      }
      // console.log("Data saved successfully");
    } catch (error) {
      console.log("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary"
        onClick={saveToServer}
        color="primary"
      >
        Save
      </Button>

      {mailSent && <Alert severity="success">Mail sent!</Alert>}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Participants</DialogTitle>
        {!isValid && <Alert severity="error">Please fill all fields!</Alert>}
        <DialogContent>
          <List>
            <ListItem>
              <TextField
                style={{ width: "100%" }}
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                style={{ width: "100%" }}
                label="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />{" "}
            </ListItem>
            {participants.map((participant, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
              >
                <TextField
                  label="Name"
                  value={participant.name}
                  onChange={(e) => {
                    const newParticipants = [...participants];
                    newParticipants[index].name = e.target.value;
                    setParticipants(newParticipants);
                  }}
                />
                <TextField
                  label="Email"
                  value={participant.email}
                  onChange={(e) => {
                    const newParticipants = [...participants];
                    newParticipants[index].email = e.target.value;
                    setParticipants(newParticipants);
                  }}
                />
                {index !== 0 && ( // Only render the delete button if it's not the first participant
                  <IconButton onClick={() => removeParticipant(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
          <Button onClick={addParticipant} disabled={!isValid}>
            Add Participant
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
          <Button onClick={handleSendMail} disabled={!isValid}>
            Send Mail
          </Button>
        </DialogActions>
      </Dialog>

      <ScheduleComponent
        ref={scheduleRef}
        currentView="Month"
        eventSettings={{ dataSource: updatedScheduleData }}
        actionComplete={onActionComplete}
      >
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
      </ScheduleComponent>
    </>
  );
}

export default SchedulerChartComponent;
