import React from "react";
import "../css/404.css";
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <Box>
      <Box className="section404">
        <Typography variant="h2" className="error" gutterBottom>
          Not found
        </Typography>
        <Typography variant="h5" className="page404" gutterBottom>
          Ooops!!! The page you are looking for is not found
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/dashboard" className="back-home404">
          Back to dashboard
        </Button>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
