function MenuSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <g fill="currentColor" fillRule="evenodd">
        <circle cx="5" cy="12" r="2"></circle>
        <circle cx="12" cy="12" r="2"></circle>
        <circle cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
}
export default MenuSvg;
