// LandingPageSection2.jsx
import React from 'react';
import { Typography } from '@mui/material';

function LandingPageSection2() {
  return (
    <div>
      <Typography variant="h2">Section 2 Content Here</Typography>
    </div>
  );
}

export default LandingPageSection2;
