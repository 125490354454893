import React from "react";

function PrivacyPage() {
  return (
    <div>
    <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9 page-content-column">
            <article className="article article--single post-34 post type-post status-publish format-standard hentry category-legal">
              <header className="article__head">
                <h1 className="article__title">Privacy policy</h1>
              </header>
              <div className="article__body">
                <div className="article__entry">
                  <section
                    className="c-block c-margin c-margin--bottom-default c-block b-header-and-text b-header-and-text--page-tos  align"
                    id="b-header-and-text-1"
                  >
                    <div className="container">
                      <header className="c-section-header">
                        <h2 className="c-el c-title c-section-header__title">
                          Thanks for joining accpro.uk
                          <br />
                        </h2>
                      </header>
                      <div className="c-text">
                        <p>
                          These Terms of Service along with any other terms and
                          policies referenced herein, and are incorporated
                          herein by reference and form an integral part hereof,
                          as amended from time to time (these “
                          <strong>Terms</strong>”)&nbsp; constitute a legally
                          binding agreement as of the Effective Date (as defined
                          below), governing your access to, and the use of{" "}
                          <a href="http://www.accpro.uk/">www.accpro.uk </a>
                          and any related website owned or operated by
                          accpro.uk (the&nbsp; “<strong>Sites</strong>”), and
                          the use of, and registration with, accpro.uk Service
                          (defined below) through the Sites,&nbsp; a mobile
                          application or through any other means. These Terms
                          are between accpro.uk Ltd.{" "}
                          <span style={{ fontWeight: 300 }}>
                            (6 Yitzhak Sadeh St., Tel-Aviv 6777506, Israel)
                          </span>{" "}
                          (“<strong>accpro.uk</strong>”, “<strong>us</strong>”,
                          “<strong>we</strong>” or “<strong>our</strong>”) and
                          you, either individually, or on behalf of your
                          employer or any&nbsp; other entity which you represent
                          (“<strong>you</strong>” or “<strong>your</strong>”).{" "}
                          <span style={{ fontWeight: 300 }}>
                            accpro.uk may use its affiliates, including
                            accpro.uk Inc. (246 5th Avenue, Suite 305 New York,
                            NY 10001, USA) and third party service providers to
                            process and/or collect payment from you.
                          </span>
                          In case you represent your employer or another
                          entity,&nbsp; you hereby represent that (i) you have
                          full legal authority to bind your employer or such
                          entity (as&nbsp; applicable) to these Terms; and (ii)
                          after reading and understanding these Terms, you agree
                          to these&nbsp; Terms on behalf of your employer or the
                          respective entity (as applicable), and these Terms
                          shall bind&nbsp; your employer or such entity (as the
                          case may be). PLEASE NOTE THAT YOU ARE DEEMED AS
                          AN&nbsp; AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER OR
                          AN ENTITY (AS APPLICABLE): (I) IF YOU&nbsp; ARE USING
                          YOUR EMPLOYER OR AN ENTITY’S EMAIL ADDRESS IN
                          REGISTERING INTO THE&nbsp; SERVICE; AND (II) IF YOU
                          ARE AN ADMIN (AS DEFINED BELOW).
                        </p>
                        <p>
                          AS ELABORATED IN SECTION 2 BELOW, THERE ARE VARIOUS
                          TYPES OF USERS FOR THE&nbsp; SERVICE, THUS, EXCEPT
                          WHERE INDICATED OTHERWISE “YOU” SHALL REFER TO
                          CUSTOMER&nbsp; AND ALL TYPES OF USERS. YOU ACKNOWLEDGE
                          THAT THESE TERMS ARE BINDING, AND YOU&nbsp; AFFIRM AND
                          SIGNIFY YOUR CONSENT TO THESE TERMS, BY EITHER: (I)
                          CLICKING ON A BUTTON&nbsp; OR CHECKING A CHECKBOX FOR
                          THE ACCEPTANCE OF THESE TERMS; OR (II) REGISTERING
                          TO,&nbsp; USING OR ACCESSING THE SERVICE, SITES OR
                          accpro.uk MOBILE APPLICATION,&nbsp; WHICHEVER IS
                          EARLIER (THE “<strong>EFFECTIVE DATE</strong>”).
                        </p>
                        <p>
                          IF YOU DO NOT AGREE TO COMPLY WITH, AND BE BOUND BY,
                          THESE TERMS OR DO NOT HAVE&nbsp; AUTHORITY TO BIND
                          YOUR EMPLOYER OR ANY OTHER ENTITY (AS APPLICABLE),
                          PLEASE DO&nbsp; NOT ACCEPT THESE TERMS OR ACCESS OR
                          USE THE SERVICE OR THE SITES OR accpro.uk&nbsp;
                          MOBILE APPLICATION.
                        </p>
                        <p>&nbsp;</p>
                        <h3>1. Our Service.</h3>
                        <h4>1.1. Our Service.</h4>
                        <p>
                          The accpro.uk platform is a cloud-based visual work
                          management tool that&nbsp; transforms the way teams
                          work together, with the aim to build a culture of
                          transparency, ownership and&nbsp; accountability,
                          inclusive of any and all functionalities, application
                          programming interface and tools&nbsp; offered as part
                          of accpro.uk platform, offered online and via a
                          mobile application (the “<strong>Service</strong>”).{" "}
                          <a href="https://accpro.uk/l/legal/apple-distributor-requirements-and-usage-rules/">
                            Specific Terms
                          </a>{" "}
                          may apply to You or to some of the Service, such
                          specific terms are incorporated herein&nbsp; by
                          reference and form an integral part hereof.
                        </p>
                        <h4>
                          1.2. Modification or Discontinuation of the Service.
                        </h4>
                        <p>
                          We may add, modify or discontinue any feature,&nbsp;
                          functionality or any other tool, within the Service
                          and/or Sites, at our own discretion and without
                          further&nbsp; notice, however, if we make any material
                          adverse change in the core functionality of the
                          Service, then we will notify you by posting an
                          announcement on the Sites and/or via the Service or by
                          sending you an&nbsp; email.
                        </p>
                        <h4>
                          1.3. No Contingency on Future Releases and
                          Improvements.
                        </h4>
                        <p>
                          You hereby acknowledge that your purchase of the
                          Service and/or Third Party Services (as defined below)
                          hereunder are not contingent on&nbsp; the delivery by
                          us of any future release of any functionality or
                          feature, including without limitation, the continuance
                          of: (i) a certain Service beyond its current
                          Subscription Term; or (ii) Third Party Services,&nbsp;
                          or dependent on any public comments we make, orally or
                          in writing, regarding any future functionality or
                          feature.
                        </p>
                        <h4>1.4. Ability to Accept Terms.</h4>
                        <p>
                          If you, access and use the Sites and/or the Service,
                          you represent and warrant that you are at least 16
                          years old. The Sites and/or Service are only intended
                          for individuals aged sixteen (16) years or older. We
                          reserve the right to request proof of age at any stage
                          so that we can verify compliance with this paragraph.
                        </p>
                        <h4>1.5. Technical Support and Committed Uptime.</h4>
                        <p>
                          To the extent you purchased an enterprise tier
                          subscription, you will be entitled to premium
                          technical support and an uptime commitment, by
                          accpro.uk, in accordance with the Service Level
                          Agreement available at{" "}
                          <a href="https://accpro.uk/terms/sla/">
                            https://accpro.uk/terms/sla/
                          </a>
                          , as may be updated by accpro.uk from time to time.
                        </p>
                        <h4>1.6. Additional Services.</h4>
                        <p>
                          Customer may choose to purchase additional services to
                          be provided by accpro.uk, subject to the{" "}
                          <a href="https://accpro.uk/l/legal/monday-com-additional-services-terms/">
                            accpro.uk Additional Services Terms
                          </a>
                          .
                        </p>
                        <p>&nbsp;</p>
                        <h3>2. Account Registration and Administration.</h3>
                        <h4>2.1. Account Registration.</h4>
                        <p>
                          To register to the Service for the first time, you
                          shall create an account with&nbsp; the Service. By
                          creating an account (“<strong>Account</strong>”) and
                          registering to the Service you become, either&nbsp;
                          individually or on behalf of your employer or any
                          entity, on behalf of whom you created the Account,
                          a&nbsp; accpro.uk customer (the “
                          <strong>Customer</strong>”). The first user of the
                          Account is automatically assigned as the&nbsp; Account
                          administrator (the “<strong>Admin</strong>”).
                        </p>
                        <h4>2.2. Your Registration Information.</h4>
                        <p>
                          When creating an Account or when you are added into an
                          Account and creating your user profile (the “
                          <strong>User Profile</strong>”), you: (i) agree to
                          provide us with accurate, complete,&nbsp; and current
                          registration information about yourself; (ii)
                          acknowledge that it is your responsibility to
                          ensure&nbsp; that your password remains confidential
                          and secure; (iii) agree that you are fully responsible
                          for all&nbsp; activities that occur under your User
                          Profile and password, including any integration or any
                          other use of&nbsp; third party products or services
                          (and associated disclosure of data) in connection with
                          the Service; and&nbsp; (iv) undertake to promptly
                          notify us in writing if you become aware of any
                          unauthorized access or use of&nbsp; your Account or
                          User Profile and/or any breach of these Terms. We may
                          assume that any&nbsp; communications we receive under
                          your User Profile have been made by you. Customer will
                          be solely&nbsp; responsible and liable for any losses,
                          damages, liability and expenses incurred by us or a
                          third party,&nbsp; due to any unauthorized usage of
                          the Account by either you or any other User or third
                          party on your&nbsp; behalf.
                        </p>
                        <h4>2.3. User Verification.</h4>
                        <p>
                          You understand and agree that we may require you to
                          provide information that&nbsp; may be used to confirm
                          your identity and help ensure the security of your
                          Account and/or User Profile.&nbsp; In the event that
                          you or the Admin lose access to an Account or
                          otherwise request information about an&nbsp; Account,
                          we reserve the right to request from you or such Admin
                          (as the case may be) any verification&nbsp; we deem
                          necessary before restoring access to or providing
                          information about such Account.
                        </p>
                        <h4>2.4. Account Admins.</h4>
                        <p>
                          The Admin(s) of an Account are, severally and jointly,
                          deemed as the authorized&nbsp; representatives of the
                          Customer, and any decision or action made by any
                          Admin, is deemed as a&nbsp; decision or action of
                          Customer. An Admin may assign or add other members of
                          the Account as Admins,&nbsp; which possess important
                          privileges and controls over the use of the Service
                          and the Account, including,&nbsp; without limitation:
                          (i) control your (and other Users) use of the Account;
                          (ii) purchase, upgrade or&nbsp; downgrade the Service;
                          (ii) create, monitor or modify Users’ actions and
                          permissions; (iii) manage the access to, control,
                          remove, share posts or otherwise change, all or part
                          of the Customer Data (as defined&nbsp; below); and
                          (iv) integrate or disable integration with Third Party
                          Services. You also acknowledge that&nbsp; your Account
                          can become managed by a representative of the entity
                          that owns or controls the email&nbsp; address domain
                          with which your Account was created or registered.
                          Admin rights are further elaborated&nbsp; in our{" "}
                          <a href="https://support.accpro.uk/">Help Center</a>.
                        </p>
                        <h4>2.5. Other Users.</h4>
                        <p>
                          There are several types of Account users, such as
                          guests, viewers and team&nbsp; members, all of whom
                          are defined within the Service and referred to herein
                          as “<strong>Authorized Users</strong>”, and&nbsp;
                          collectively with the Admin, the “
                          <strong>Users</strong>”. The features and
                          functionalities available to the Users are&nbsp;
                          determined by the respective subscription plan
                          governing such Account, and the privileges of each
                          such&nbsp; Authorized User are assigned and determined
                          by the Account Admin(s). For more information on the
                        </p>
                        <p>
                          rights, permissions and definition of each of the
                          various types of Authorized Users, visit this{" "}
                          <a href="https://support.accpro.uk/hc/en-us/articles/360002144900-What-Are-The-Levels-of-Users-In-My-Account-?abcb=97261#">
                            Article in our Help Center
                          </a>
                          .
                        </p>
                        <h4>2.6. Responsibility for Authorized Users.</h4>
                        <p>
                          Customer is solely liable and responsible for
                          understanding&nbsp; the settings, privileges and
                          controls for the Service and for controlling whom
                          Customer permits to&nbsp; become a User and what are
                          the settings and privileges for such User, including
                          without limitation, the&nbsp; right for a User to
                          invite other Users (either paid or unpaid), the right
                          to incur charges on the Account,&nbsp; the right to
                          access, modify or share boards, etc. Customer is
                          responsible for the activities of all of its Users,
                          including Order Forms they may place and how Users use
                          the Customer Data, even if those&nbsp; Users are not
                          from Customer’s organization or domain. Further,
                          Customer acknowledges that any action&nbsp; taken by a
                          User of Customer’s Account, is deemed by us as an
                          authorized action by Customer, hence&nbsp; Customer
                          shall have no claim in this regard.
                        </p>
                        <h3></h3>
                        <h3>3. Your Customer Data.</h3>
                        <h4>3.1. Customer Data.</h4>
                        <p>
                          Customer Data is any data, file attachments, text,
                          images, reports, personal&nbsp; information, or any
                          other content, that is uploaded or submitted,
                          transmitted or otherwise made&nbsp; available, to or
                          through the Service by you or any User and is
                          processed by us on Customer’s behalf (the “
                          <strong>Customer Data</strong>”). For the avoidance of
                          doubt, Anonymous Information (as defined below) is
                          not&nbsp; regarded as Customer Data. Customer retains
                          all right, title, interest and control, in and to the
                          Customer&nbsp; Data, in the form submitted to the
                          Service. Subject to these Terms, Customer grants us a
                          worldwide,&nbsp; royalty-free, limited license to
                          access, use, process, copy, distribute, perform,
                          export, and display the&nbsp; Customer Data, and
                          solely to the extent that reformatting Customer Data
                          for display in the Service&nbsp; constitutes a
                          modification or derivative work, the foregoing license
                          also includes the right to make&nbsp; modifications
                          and derivative works. The afore-mentioned license is
                          hereby granted solely: (i) to maintain&nbsp; and
                          provide you the Service; (ii) to prevent or address
                          technical or security issues and resolve support&nbsp;
                          requests; (iii) to investigate when we have a good
                          faith belief, or have received a complaint alleging,
                          that&nbsp; such Customer Data is in violation of these
                          Terms; (iv) to comply with a valid legal subpoena,
                          request,&nbsp; or other lawful process; and (v) as
                          expressly permitted in writing by you.
                        </p>
                        <h4>
                          3.2. Responsibility for Customer Data Compliance.
                        </h4>
                        <p>
                          You represent and warrant that: (i) you have or&nbsp;
                          have obtained all rights, licenses, consents,
                          permissions, power and/or authority, necessary to
                          grant the&nbsp; rights granted herein, for any
                          Customer Data that you submit, post or display on or
                          through the Service;&nbsp; (ii) the Customer Data is
                          in compliance with, and subject to, our{" "}
                          <a href="https://accpro.uk/l/legal/acceptable-use-policy/">
                            Acceptable Use Policy
                          </a>
                          ; and (iii) the&nbsp; Customer Data you submit, your
                          use of such Customer Data, and our use of such
                          Customer Data, as&nbsp; set forth in these Terms, do
                          not and shall not (a) infringe or violate any patents,
                          copyrights, trademarks&nbsp; or other intellectual
                          property, proprietary or privacy, data protection or
                          publicity rights of any third party;&nbsp; (b) violate
                          any applicable local, state, federal and international
                          laws, regulations and conventions,&nbsp; including
                          those related to data privacy and data transfer and
                          exportation (the “<strong>Laws</strong>”); (c) violate
                          any of&nbsp; your or third party’s policies and terms
                          governing the Customer Data. Other than our security
                          and data&nbsp; protection obligations expressly set
                          forth in Section 6, we assume no responsibility or
                          liability for&nbsp; Customer Data, and you shall be
                          solely responsible for Customer Data and the
                          consequences of using,&nbsp; disclosing, storing, or
                          transmitting it. It is hereby clarified that
                          accpro.uk shall not monitor and/or moderate the
                          Customer Data and there shall be no claim against
                          accpro.uk of not acting so.
                        </p>
                        <h4>3.3. No Sensitive Data.</h4>
                        <p>
                          You shall not submit to the Service any data that is
                          protected under a special&nbsp; legislation and
                          requires a unique treatment, including, without
                          limitations, (i) categories of data&nbsp; enumerated
                          in European Union Regulation 2016/679, Article 9(1) or
                          any similar legislation or regulation&nbsp; in other
                          jurisdiction; (ii) any protected health information
                          subject to the Health Insurance Portability and&nbsp;
                          Accountability Act (“<strong>HIPAA</strong>”), as
                          amended and supplemented, or any similar legislation
                          in other&nbsp; jurisdiction, unless Customer and
                          accpro.uk separately enter into a{" "}
                          <a href="https://accpro.uk/l/terms/hipaa-baa">
                            HIPAA Business Associate Agreement
                          </a>
                          ; and (iii) credit, debit or other payment card data
                          subject to PCI DSS or any other credit card&nbsp;
                          schemes.
                        </p>
                        <p>&nbsp;</p>
                        <h3>4. Public User Submissions.</h3>
                        <h4>4.1. Public User Submissions.</h4>
                        <p>
                          The Sites may have certain features that allow you to
                          submit comments,&nbsp; information, and other
                          materials publicly (collectively, “
                          <strong>Public User Submissions</strong>”) and share
                          such Public&nbsp; User Submissions with other Users,
                          or the public. By submitting Public User Submissions
                          through the&nbsp; Sites, you grant us a license to
                          access, use, copy, reproduce, process, adapt, publish,
                          transmit, host,&nbsp; and display that Public User
                          Submissions for any purpose, business, including
                          without limitation, for&nbsp; publicizing and
                          promoting accpro.uk, the Service and/or the Sites and
                          for any other lawful purpose,&nbsp; in any media
                          format (e.g. in-print, websites. electronically,
                          broadcast), and you hereby waive, or to the&nbsp;
                          extent legally prohibited, assign to accpro.uk, any
                          moral rights in your Public User Submissions.
                        </p>
                        <h4>
                          4.2. Responsibility for Public User Submissions.
                        </h4>
                        <p>
                          You acknowledge and agree that: (i) you have or
                          have&nbsp; obtained all rights, licenses, consents,
                          permissions, power and/or authority, necessary to
                          grant the rights&nbsp; granted herein, for any Public
                          User Submissions that you submit, post or display on
                          or through the&nbsp; Service; (ii) we do not control,
                          and are not responsible for, other content and/or
                          submissions, posted on&nbsp; our Sites and/or Service
                          by others; (iii) by using the Service and/or Sites,
                          you may be exposed to content&nbsp; and/or submissions
                          by other users or site visitors that is offensive,
                          indecent, inaccurate, misleading, or&nbsp; otherwise
                          unlawful; (iv) any Public User Submissions are
                          submitted in accordance with, and subject to&nbsp; our{" "}
                          <a href="https://accpro.uk/terms/aup">
                            Acceptable Use Policy
                          </a>
                          .
                        </p>
                        <p>&nbsp;</p>
                        <h3>5. Intellectual Property Rights; License.</h3>
                        <h4>5.1. Our Intellectual Property.</h4>
                        <p>
                          The Service and Sites, inclusive of materials, such as
                          software,&nbsp; application programming interface,
                          design, text, editorial materials, informational text,
                          photographs,&nbsp; illustrations, audio clips, video
                          clips, artwork and other graphic materials, and names,
                          logos, trademarks&nbsp; and services marks (excluding
                          Customer Data), any and all related or underlying
                          technology and any&nbsp; modifications, enhancements
                          or derivative works of the foregoing (collectively, “
                          <strong>accpro.uk Materials</strong>”), are the
                          property of accpro.uk and its licensors, and may be
                          protected by applicable&nbsp; copyright or other
                          intellectual property laws and treaties. As between
                          you and accpro.uk,&nbsp; accpro.uk retains all
                          right, title and interest, including all intellectual
                          property rights, in and to the&nbsp; accpro.uk
                          Materials.
                        </p>
                        <h4>5.2. Customer Reference.</h4>
                        <p>
                          Customer acknowledges and accepts that accpro.uk has
                          the right to use&nbsp; Customer’s name and logo to
                          identify Customer as a customer of accpro.uk or User
                          of the Service,&nbsp; on accpro.uk’s website,
                          marketing materials or otherwise by public
                          announcements. Customer may&nbsp; revoke such right,
                          at any time, by contacting{" "}
                          <a href="mailto:support@accpro.uk">
                            support@accpro.uk
                          </a>
                          .
                        </p>
                        <h4>5.3. Your Access and Use Rights.</h4>
                        <p>
                          Subject to the terms and conditions of these Terms,
                          and your&nbsp; compliance thereof, and specifically in
                          strict compliance with our{" "}
                          <a href="https://accpro.uk/terms/aup">
                            Acceptable Use Policy
                          </a>
                          , we grant you&nbsp; a limited, worldwide,
                          non-exclusive, non-transferable right to access and
                          use the Service and Sites,&nbsp; during the applicable
                          Subscription Term, solely for Customer’s internal
                          purposes.
                        </p>
                        <h4>5.4. Use Restrictions.</h4>
                        <p>
                          Except as expressly permitted in these Terms, you may
                          not, and shall not allow&nbsp; an Authorized User or
                          any third party to: (i) give, sell, rent, lease,
                          timeshare, sublicense, disclose,&nbsp; publish,
                          assign, market, resell, display, transmit, broadcast,
                          transfer or distribute any portion of the&nbsp;
                          Service or the Sites to any third party, including,
                          but not limited to your affiliates, or use the Service
                          in&nbsp; any service bureau arrangement; (ii)
                          circumvent, disable or otherwise interfere with
                          security-related&nbsp; features of the Sites or
                          Service or features that prevent or restrict use or
                          copying of any content or that&nbsp; enforce
                          limitations on use of the Service or Sites; (iii)
                          reverse engineer, decompile or disassemble,&nbsp;
                          decrypt or, attempt to derive the source code of, the
                          Service or Sites, or any components thereof;
                          (iv)&nbsp; copy, modify, translate, patch, improve,
                          alter, change or create any derivative works of the
                          Service or&nbsp; Sites, or any part thereof; (v) take
                          any action that imposes or may impose (at accpro.uk’s
                          sole &nbsp;discretion) an unreasonable or
                          disproportionately large load on the accpro.uk
                          infrastructure or&nbsp; infrastructure which supports
                          the Sites or Service; (vi) interfere or attempt to
                          interfere with the integrity
                        </p>
                        <p>
                          or proper working of the Service or Sites, or any
                          related activities; (vii) remove, deface, obscure, or
                          alter&nbsp; accpro.uk’s or any third party’s
                          identification, attribution or copyright notices,
                          trademarks, or other&nbsp; proprietary rights affixed
                          to or provided as part of the Service or Sites, or use
                          or display logos of the&nbsp; Service or Sites without
                          accpro.uk’s prior written approval; (viii) use the
                          Service or Sites for&nbsp; competitive purposes,
                          including to develop or enhance a competing service or
                          product; or (ix)&nbsp; encourage or assist any third
                          party (including other Authorized Users) to do any of
                          the foregoing.
                        </p>
                        <h4>5.5. Feedback.</h4>
                        <p>
                          As a User of the Service and/or Sites, you may provide
                          suggestions, comments, feature&nbsp; requests or other
                          feedback to any of accpro.uk Materials, the
                          accpro.uk Service, the API (in case&nbsp; you are the
                          Admin) and/or the Sites (“<strong>Feedback</strong>”).
                          Such Feedback is deemed an integral part of&nbsp;
                          accpro.uk Materials, and as such, it is the sole
                          property of accpro.uk without restrictions or&nbsp;
                          limitations on use of any kind. accpro.uk may either
                          implement or reject such Feedback, without any&nbsp;
                          restriction or obligation of any kind. You (i)
                          represent and warrant that such Feedback is
                          accurate,&nbsp; complete, and does not infringe on any
                          third party rights; (ii) irrevocably assign to
                          accpro.uk&nbsp;any right,&nbsp; title and interest
                          you may have in such Feedback; and (iii) explicitly
                          and irrevocably waive any and all&nbsp; claims
                          relating to any past, present or future moral rights,
                          artists’ rights, or any other similar rights,
                          worldwide, in or to such Feedback.
                        </p>
                        <h4>5.6. API Use.</h4>
                        <p>
                          We may offer an application programming interface that
                          provides additional ways to&nbsp; access and use the
                          Service (“<strong>API</strong>“). Such API is
                          considered a part of the Service, and its use is
                          subject&nbsp; to all these Terms. Without derogating
                          from Sections 5.1 through 5.4 hereof, you may only
                          access and&nbsp; use our API for Customer’s internal
                          business purposes, in order to create interoperability
                          and integration&nbsp; between the Service and other
                          products, services or systems you and/or Customer use
                          internally. When&nbsp; using the API you should follow
                          our relevant developer guidelines. We reserve the
                          right at any time to&nbsp; modify or discontinue,
                          temporarily or permanently, your and/or Customer’s
                          access to the API (or any&nbsp; part of it) with or
                          without notice. The API is subject to changes and
                          modifications, and you are solely&nbsp; responsible to
                          ensure that your use of the API is compatible with the
                          current version.
                        </p>
                        <p>&nbsp;</p>
                        <h3>6. Privacy and Security.</h3>
                        <h4>6.1. Security.</h4>
                        <p>
                          accpro.uk implements reasonable security measures and
                          procedures to assist in&nbsp; protecting your Customer
                          Data. You can learn more on our security measures and
                          procedures on our{" "}
                          <a href="https://accpro.uk/trustcenter/datasecure">
                            Security Page
                          </a>
                          , as updated from time to time.
                        </p>
                        <h4>6.2. Privacy Policy.</h4>
                        <p>
                          As a part of accessing or using the Service and the
                          Sites, we may collect, access,&nbsp; use and share
                          certain Personal Data (as defined in the{" "}
                          <a href="https://accpro.uk/terms/privacy">
                            Privacy Policy
                          </a>
                          ) from, and/or about, you. Please&nbsp; read our{" "}
                          <a href="https://accpro.uk/terms/privacy">
                            Privacy Policy
                          </a>
                          , which is incorporated herein by reference, for a
                          description of such data&nbsp; collection and use
                          practices.
                        </p>
                        <h4>
                          6.3. Data Processing Agreement (“<strong>DPA</strong>
                          ”).
                        </h4>
                        <p>
                          By using the Service, Customer also accepts our{" "}
                          <a href="http://www.accpro.uk/terms/dpa">
                            Data Processing Agreement
                          </a>
                          , which governs the Processing of Personal Data (as
                          both terms are defined in&nbsp; the DPA) on Customer’s
                          behalf, where such Personal Data is subject to the
                          General Data Protection&nbsp; Regulation 2016/679 (the
                          “<strong>GDPR</strong>”).
                        </p>
                        <h4>6.4. Anonymous Information.</h4>
                        <p>
                          Notwithstanding any other provision of these Terms, we
                          may collect, use&nbsp; and publish Anonymous
                          Information (defined below) relating to your use of
                          the Service and/or Sites, and&nbsp; disclose it for
                          the purpose of providing, improving and publicizing
                          our products and services, including&nbsp; the Sites
                          and Service, and for other business purposes. “
                          <strong>Anonymous Information</strong>” means
                          information&nbsp; which does not enable identification
                          of an individual, such as aggregated and analytics
                          information.&nbsp; accpro.uk owns all Anonymous
                          Information collected or obtained by accpro.uk.
                        </p>
                        <p>&nbsp;</p>
                        <h3>7. Third Party Services; Links.</h3>
                        <h4>7.1. Third Party Services.</h4>
                        <p>
                          The Service enables you to engage and procure certain
                          third party services,&nbsp; products, apps and tools
                          in connection with the Service, including, without
                          limitation, third party&nbsp; applications and widgets
                          offered via our integrations offering or which you
                          decide to connect through&nbsp; our API, as part of
                          the Service (collectively, “
                          <strong>Third Party Services</strong>”).
                        </p>
                        <h4>7.2. Independent Relationship.</h4>
                        <p>
                          You acknowledge and agree that regardless of the
                          manner in which&nbsp; such Third Party Services may be
                          offered to you, we merely act as an intermediary
                          platform between&nbsp; you and such Third Party
                          Services, and we do not, in any way, endorse any such
                          Third Party Services,&nbsp; or shall be in any way
                          responsible or liable with respect to any such Third
                          Party Services. Your&nbsp; relationship with such
                          Third Party Services and any terms governing your
                          payment for, and use of, such&nbsp; Third Party
                          Services, including without limitation, the
                          collection, processing and use of your data by
                          such&nbsp; Third Party Services, are subject to a
                          separate contractual arrangement between you and the
                          provider&nbsp; of a Third Party Service (the “
                          <strong>Third Party Agreement</strong>”). We are not a
                          party to, or responsible, in any&nbsp; manner, for the
                          compliance by you or by the provider of the Third
                          Party Service with the Third Party&nbsp; Agreement.
                        </p>
                        <h4>
                          7.3. Integration with a Third Party Service and your
                          Customer Data.
                        </h4>
                        <p>
                          Through the Service you and any&nbsp; other Authorized
                          User within the Account, may enable an integration of
                          your Account, including, boards&nbsp; within your
                          Account (or a portion thereof), with Third Party
                          Services, which will allow an exchange,&nbsp;
                          transmission, modification or removal of data between
                          us and the Third Party Service, including
                          without&nbsp; limitation, the Customer Data, the scope
                          of which is determined by the applicable actions set
                          by such&nbsp; integration. You hereby acknowledge that
                          any access, collection, transmission, processing,
                          storage or&nbsp; any other use of data, including the
                          Customer Data, by a Third Party Service, is governed
                          by the Third&nbsp; Party Agreement, including any
                          applicable privacy policy, and accpro.uk is not
                          responsible for any&nbsp; access, collection,
                          transmission, processing, storage or any other use of
                          data, including the Customer&nbsp; Data, by the Third
                          Party Service or for such Third Party Service privacy
                          and security actions, inactions&nbsp; or general
                          practices. By integrating and/or using the Third Party
                          Services, you acknowledge and agree&nbsp; that: (a)
                          you are solely responsible for your compliance with
                          applicable privacy restrictions, laws and&nbsp;
                          regulations, including your use of the Third Party
                          Service and other data activities you may conduct
                          or&nbsp; may permit third parties, including the Third
                          Party Service, to conduct; (b) the activities and use
                          of the&nbsp; data by you and any other Users within
                          the Account, may result in a modification and/or
                          removal of data,&nbsp; either in the Account (i.e.
                          Customer Data) and in the integrated Third Party
                          Service. We shall have no&nbsp; obligation of any
                          kind, for any such modification and/or removal of
                          data, either in the Account with us&nbsp; and/or the
                          integrated Third Party Service.
                        </p>
                        <h4>7.4. Use Conditions and Limitations.</h4>
                        <p>
                          Both accpro.uk and a Third Party Service may impose,
                          each&nbsp; at its sole discretion, additional
                          conditions or limitations on your access and use of
                          certain Third Party&nbsp; Services, including without
                          limitation, imposing a limited quota on the number of
                          actions or other uses&nbsp; (as the case may be). Such
                          additional conditions or limitations shall be
                          indicated wherever relevant&nbsp; within the Service
                          or the Third Party Service or otherwise notified to
                          you or to any other relevant User of&nbsp; the
                          Account.
                        </p>
                        <h4>7.5. accpro.uk Marketplace.</h4>
                        <p>
                          accpro.uk may make available Third Party Services
                          through the&nbsp; accpro.uk Marketplace. Your use of
                          the accpro.uk Marketplace is subject to the
                          accpro.uk{" "}
                          <a href="https://accpro.uk/l/terms/marketplace">
                            Marketplace Terms of Service
                          </a>
                          .
                        </p>
                        <h4>7.6. Payment for Third Party Services.</h4>
                        <p>
                          Third Party Services may be offered free of charge or
                          for a&nbsp; certain fee, either charged directly by
                          the Third Party Service or by accpro.uk. Wherever the
                          Third&nbsp; Party Service requires a payment, it shall
                          be indicated next to the offering of the Third Party
                          Service,&nbsp; unless such price is included within
                          the Subscription Plan (as defined below) for the
                          Service. Whenever&nbsp; accpro.uk charges Customer on
                          behalf of itself and not as an agent on behalf of the
                          Third Party&nbsp; Service, the payment terms,
                          including the payment of fees, renewal and refund
                          policy, are governed by&nbsp; Sections 8 and 9 herein.
                          Whenever accpro.uk charges Customer on behalf of the
                          Third Party
                        </p>
                        <p>
                          Services, then Customer acknowledges that accpro.uk
                          serves only as an intermediary role in&nbsp;
                          facilitating or collecting the applicable fees and
                          taxes from Customer, for the Third Party Service, thus
                          all payment related issues, including the payment of
                          fees, renewal and refund policy, are governed by&nbsp;
                          the Third Party Agreement.
                        </p>
                        <h4>7.7. Change of Fees.</h4>
                        <p>
                          Customer acknowledges that accpro.uk and any Third
                          Party Service, may&nbsp; change the fees for the Third
                          Party Service from time to time, including imposing a
                          new charge on a&nbsp; Third Party Service that was
                          provided for free.
                        </p>
                        <h4>7.8. Discontinuation of a Third Party Service.</h4>
                        <p>
                          Each of accpro.uk and the Third Party Service&nbsp;
                          reserves the right to discontinue the use or suspend
                          the availability of any Third Party Service, for
                          any&nbsp; reason and with no obligation to provide any
                          explanation or notice. Such discontinuation may result
                          in&nbsp; the inability to utilize certain features and
                          actions of the Third Party Service along with our
                          Service.
                        </p>
                        <h4>7.9. Links.</h4>
                        <p>
                          The Sites, Service and/or any Third Party Services may
                          contain links to third party websites&nbsp; that are
                          not owned or controlled by us (the “
                          <strong>Links</strong>”). You acknowledge that we have
                          no control over,&nbsp; and assume no responsibility
                          for the content, privacy policies, or practices of,
                          any third party websites.&nbsp; You: (i) are solely
                          responsible and liable for your use of and linking to
                          third party websites and any content&nbsp; that you
                          may send or post to a third-party website; and (ii)
                          expressly release us from any and all liability&nbsp;
                          arising from your, and in case of a Customer, all
                          Users’, use of any third party website. Accordingly,
                          we&nbsp; encourage you to read the terms and
                          conditions and privacy policy of each third party
                          website that you&nbsp; may choose to visit.
                        </p>
                        <h4>7.10. Limitations of Liability.</h4>
                        <p>
                          accpro.uk BEARS NO RESPONSIBILITY AND/OR LIABILITY
                          FOR&nbsp; ANY LINKS OR THIRD PARTY SERVICES, INCLUDING
                          WITHOUT LIMITATION, SUCH THIRD PARTY&nbsp; SERVICE’S
                          OPERABILITY OR INTEROPERABILITY WITH OUR SERVICE,
                          SECURITY, ACCURACY,&nbsp; RELIABILITY, DATA PROTECTION
                          AND PROCESSING PRACTICES AND THE QUALITY OF ITS&nbsp;
                          OFFERINGS, AS WELL AS ANY ACTS OR OMMISSIONS BY THIRD
                          PARTIES. BY ACCESSING&nbsp; AND/OR USING THE THIRD
                          PARTY SERVICES, YOU ACKNOWLEDGE THAT YOUR ACCESS
                          AND&nbsp; USE OF THE THIRD PARTY SERVICES ARE AT YOUR
                          SOLE DISCRETION AND RISK, AND YOU ARE&nbsp; SOLELY
                          RESPONSIBLE FOR ENSURING SUCH THIRD PARTY SERVICE’S
                          OPERATION AND&nbsp; PRACTICES AND ITS RESPECTIVE THIRD
                          PARTY AGREEMENT, MEET YOUR NEEDS.
                        </p>
                        <p>&nbsp;</p>
                        <h3>8. Subscription Term, Renewal and Fees Payment.</h3>
                        <h4>8.1. Order Form.</h4>
                        <p>
                          Our order form may be completed and placed in various
                          ways, among which, an&nbsp; online form or in-product
                          screens or any other mutually agreed upon offline form
                          delivered by Customer&nbsp; or any of the other Users
                          to accpro.uk, including via mail, email or any other
                          electronic or physical&nbsp; delivery mechanism (the “
                          <strong>Order Form</strong>”). Such Order Form will
                          list, at the least, the Service ordered,&nbsp;
                          subscription plan, term and the associated fees.
                        </p>
                        <h4>8.2. Subscription Term.</h4>
                        <p>
                          The Service is provided on a subscription basis for
                          the term specified in your &nbsp;Order Form, in
                          accordance with the respective subscription plan
                          purchased under such Order Form &nbsp;(the “
                          <strong>Subscription Term</strong>” and the “
                          <strong>Subscription Plan</strong>”, respectively, and
                          collectively the “<strong>Subscription</strong>”).
                        </p>
                        <h4>8.3. Subscription Fees.</h4>
                        <p>
                          In consideration for the provision of the Service
                          (except for Trial Service),&nbsp; Customer shall pay
                          us the applicable fees per the purchased Subscription,
                          as set forth in the applicable&nbsp; Order Form (the “
                          <strong>Subscription Fees</strong>”). Unless indicated
                          otherwise, Subscription Fees are stated in US&nbsp;
                          dollars. Customer hereby authorizes us, either
                          directly or through our payment processing service{" "}
                          <span style={{ fontWeight: 300 }}>
                            or our affiliates
                          </span>
                          , to&nbsp; charge such Subscription Fees via
                          Customer’s selected payment method, upon due date.
                          Unless&nbsp; expressly set forth herein, the
                          Subscription Fees are non-cancelable and
                          non-refundable. We reserve&nbsp; the right to change
                          the Subscription Fees at any time, upon notice to
                          Customer if such change may&nbsp; affect Customer’s
                          existing subscriptions upon renewal. In the event of
                          failure to collect the Fees owed
                        </p>
                        <p>
                          by Customer, we may, at our sole discretion (but shall
                          not be obligated to) retry to collect at a later
                          time,&nbsp; and/or suspend or cancel the Account,
                          without notice.
                        </p>
                        <h4>8.4. Taxes.</h4>
                        <p>
                          The Subscription Fees are exclusive of any and all
                          taxes (including without limitation, value&nbsp; added
                          tax, sales tax, use tax, excise, goods and services
                          tax, etc.), levies, or duties, which may be&nbsp;
                          imposed in respect of these Terms and the purchase or
                          sale, of the Service hereunder (the “
                          <strong>Taxes</strong>”),&nbsp; except for Taxes
                          imposed on our income. If Customer is located in a
                          jurisdiction which requires&nbsp; Customer to deduct
                          or withhold Taxes or other amounts from any amounts
                          due to us, please notify us,&nbsp; in writing,
                          promptly and we shall join efforts to avoid any such
                          Tax withholding, provided, however, that in any case,
                          Customer shall bear the sole responsibility and
                          liability to pay such Tax and such Tax should&nbsp; be
                          deemed as being added on top of the Subscription Fees,
                          payable by Customer.
                        </p>
                        <h4>8.5. Subscription Upgrade.</h4>
                        <p>
                          During the Subscription Term, Customer may upgrade its
                          Subscription&nbsp; Plan by either: (i) adding
                          Authorized Users; (ii) upgrading to a higher type of
                          Subscription Plan; (iii)&nbsp; adding add-on features
                          and functionalities; and/or (iv) upgrading to a longer
                          Subscription Term&nbsp; (collectively, “
                          <strong>Subscription Upgrades</strong>”). Some
                          Subscription Upgrades or other changes may be&nbsp;
                          considered as a new purchase, hence will restart the
                          Subscription Term and some won’t, as indicated&nbsp;
                          within the Service and/or the Order Form. Upon a
                          Subscription Upgrade, Customer will be billed for the
                          applicable increased amount of Subscription Fees, at
                          our then-current rates (unless indicated
                          otherwise&nbsp; in an Order Form), either: (1)
                          prorated for the remainder of the then-current
                          Subscription Term, or (2)&nbsp; whenever the
                          Subscription Term is being restarted due to the
                          Subscription Upgrade, then the&nbsp; Subscription Fees
                          already paid by Customer will be reduced from the new
                          upgraded Subscription Fees,&nbsp; and the difference
                          shall be due and payable by Customer upon the date on
                          which the Subscription&nbsp; Upgrade was made.
                        </p>
                        <h4>8.6. Adding Users.</h4>
                        <p>
                          Customer acknowledges that unless it disabled these
                          options: (i) Users within the&nbsp; same email domain
                          will be able to automatically join the Account; and
                          (ii) Users within Customer’s&nbsp; Account may invite
                          other persons to be added to the Account as Users
                          (collectively, “<strong>Users Increase</strong>
                          ”).&nbsp; For further information on these options and
                          how to disable them, visit our{" "}
                          <a href="https://support.accpro.uk/">Help Center</a>.
                          Unless agreed&nbsp; otherwise in an Order Form, any
                          changes to the number of Users within a certain
                          Account, shall be billed&nbsp; on a prorated basis for
                          the remainder of the then-current Subscription Term.
                          We will bill Customer, either&nbsp; upon the Users
                          Increase or at the end of the applicable month, as
                          communicated to Customer.
                        </p>
                        <h4>8.7. Excessive Usage.</h4>
                        <p>
                          We shall have the right, including without limitation
                          where we, at our sole&nbsp; discretion, believe that
                          Customer and/or any of its Users, have misused the
                          Service or otherwise use&nbsp; the Service in an
                          excessive manner compared to the anticipated standard
                          use (at our sole discretion),&nbsp; to offer the
                          Subscription in different pricing and/or impose
                          additional restrictions as for the upload,&nbsp;
                          storage, download and use of the Service, including,
                          without limitation, restrictions on Third Party&nbsp;
                          Services, network traffic and bandwidth, size and/or
                          length of content, quality and/or format of
                          content,&nbsp; sources of content, volume of download
                          time, etc.
                        </p>
                        <h4>8.8. Billing.</h4>
                        <p>
                          As part of registering, or submitting billing
                          information, to the Service, Customer agrees to&nbsp;
                          provide us with updated, accurate and complete billing
                          information, and Customer authorizes us (either&nbsp;
                          directly or through our affiliates,{" "}
                          <span style={{ fontWeight: 300 }}>
                            including accpro.uk Inc.
                          </span>{" "}
                          or other third parties) to charge, request and
                          collect&nbsp; payment (or otherwise charge, refund or
                          take any other billing actions) from Customer’s
                          payment&nbsp; method or designated banking account,
                          and to make any inquiries that we{" "}
                          <span style={{ fontWeight: 300 }}>
                            (or our affiliates and/or third-parties acting on
                            our behalf)
                          </span>{" "}
                          may consider necessary to&nbsp; validate Customer’s
                          designated payment account or financial information,
                          in order to ensure prompt&nbsp; payment, including for
                          the purpose of receiving updated payment details from
                          Customer’s credit card company or banking account
                          (e.g., updated expiry date or card number as may be
                          provided to us by&nbsp; Customer’s credit card
                          company).
                        </p>
                        <h4>8.9. Subscription Auto-Renewal.</h4>
                        <p>
                          In order to ensure that Customer will not experience
                          any interruption&nbsp; or loss of services, Customer’s
                          Subscription includes an automatic renewal option by
                          default, according&nbsp; to which, unless Customer
                          disables the auto-renewal option or cancels its
                          Subscription prior to its&nbsp; expiration, the
                          Subscription will automatically renew upon the end of
                          the then applicable Subscription
                        </p>
                        <p>
                          Term, for a renewal period equal in time to the
                          original Subscription Term (excluding extended
                          periods)&nbsp; and, unless otherwise notified to
                          Customer, at the same price (subject to applicable Tax
                          changes and&nbsp; excluding any discount or other
                          promotional offer provided for the first Subscription
                          Term). Accordingly,&nbsp; unless either Customer or us
                          cancel the Subscription prior to its expiration, we
                          will attempt to&nbsp; automatically charge Customer
                          the applicable Subscription Fees upon or immediately
                          prior to the&nbsp; expiration of the then applicable
                          Subscription Term. If Customer wishes to avoid such
                          auto-renewal,&nbsp; Customer shall cancel its
                          Subscription (or disable the auto-renewal option),
                          prior to its expiration, at any&nbsp; time through the
                          Account settings or by contacting our our{" "}
                          <a href="https://accpro.uk/contact-us/">
                            Customer Success
                          </a>{" "}
                          team. Except as expressly&nbsp; set forth in these
                          Terms, in case a Customer cancels its Subscription,
                          during a Subscription Term, the&nbsp; Subscription
                          will not renew for an additional period, but Customer
                          will not be refunded or credited for&nbsp; any unused
                          period within the Subscription Term.
                        </p>
                        <h4>8.10. Discounts and Promotions.</h4>
                        <p>
                          Unless expressly stated otherwise in a separate
                          legally binding&nbsp; agreement, if Customer received
                          a special discount or other promotional offer,
                          Customer acknowledges that upon renewal of its
                          Subscription, accpro.uk will renew such Subscription,
                          at the full applicable&nbsp; Subscription Fee at the
                          time of renewal.
                        </p>
                        <h4>8.11. Credits.</h4>
                        <p>
                          Any credits that may accrue to Customer’s Account, for
                          any reason (the “<strong>Credits</strong>”),
                          will&nbsp; expire and be of no further force and
                          effect, upon the earlier of: (i) the expiration or
                          termination of the&nbsp; applicable Subscription under
                          the Account for which such Credits were given; or (ii)
                          in case such Credits&nbsp; accrued for an Account with
                          a Trial Subscription (as defined below) that was not
                          upgraded to a&nbsp; Subscription Plan, then upon the
                          lapse of 90 days of such Credits’ accrual. Unless
                          specifically indicated&nbsp; otherwise, Credits may be
                          used to pay for the Services only and not for any
                          Third Party Service or other&nbsp; payment of
                          whatsoever kind. Whenever fees are due for any
                          Services, accrued Credits will be first&nbsp; reduced
                          against the Subscription Fees and the remainder will
                          be charged from Customer’s respective&nbsp; payment
                          method. Credits shall have no monetary value (except
                          for the purchase of Services under the&nbsp; limited
                          terms specified herein) nor exchange value, and will
                          not be transferable or refundable.
                        </p>
                        <h4>8.12. Payment through Reseller.</h4>
                        <p>
                          If Customer purchased a Service from a reseller or
                          distributor&nbsp; authorized by us (“
                          <strong>Reseller</strong>”), then to the extent there
                          is any conflict between these Terms and the agreement
                          entered between Customer and the respective Reseller,
                          including any purchase order&nbsp; (“
                          <strong>Reseller Agreement</strong>”), then, as
                          between Customer and accpro.uk, these Terms shall
                          prevail. Any&nbsp; rights granted to Customer and/or
                          any of the other Users in such Reseller Agreement
                          which are not&nbsp; contained in these Terms, apply
                          only in connection with the Reseller. In that case,
                          Customer must seek&nbsp; redress or realization or
                          enforcement of such rights solely with the Reseller
                          and not accpro.uk. For&nbsp; clarity, Customer’s and
                          its Users’ access to the Service is subject to our
                          receipt from Reseller of the&nbsp; payment of the
                          applicable Fees paid by Customer to Reseller. Customer
                          hereby acknowledges that at&nbsp; any time, at our
                          discretion, the billing of the Subscription Fees may
                          be assigned to us, such that&nbsp; Customer shall pay
                          us directly the respective Subscription Fees.
                        </p>
                        <p>&nbsp;</p>
                        <h3>9. Refund Policy; Chargeback.</h3>
                        <h4>9.1. Refund Policy.</h4>
                        <p>
                          If Customer is not satisfied with its initial purchase
                          of a Service, Customer may terminate such Service by
                          providing us a written notice, within 30 days of
                          having first ordered such&nbsp; Services (the “
                          <strong>Refund Period</strong>”). In the event that
                          Customer terminates such initial purchase of a
                          Service,&nbsp; within the Refund Period, we will
                          refund Customer the prorata portion of any unused and
                          unexpired Subscription Fees pre-paid by Customer in
                          respect of such terminated period of the Subscription,
                          unless&nbsp; such other sum is required by applicable
                          law, in the same currency we were originally paid
                          (the&nbsp; “<strong>Refund</strong>”). The Refund is
                          applicable only to the initial purchase of the Service
                          by Customer and does not&nbsp; apply to any additional
                          purchases, upgrades, modification or renewals of such
                          Service. Please note that&nbsp; we shall not be
                          responsible to Refund any differences caused by change
                          of currency exchange rates or&nbsp; fees that Customer
                          was charged by third parties, such as wire transfer
                          fees. After the Refund Period,&nbsp; the Subscription
                          Fees are non-refundable and non-cancellable. To the
                          extent permitted by law, if we find&nbsp; that a
                          notice of cancellation has been given in bad faith or
                          in an illegitimate attempt to avoid payment for
                          Services actually received and enjoyed, we reserve our
                          right to reject Customer’s Refund request. Subject to
                          the foregoing, upon termination by Customer under this
                          Section 9.1 all outstanding payment&nbsp; obligations
                          shall immediately become due for the used Subscription
                          Term and Customer will promptly&nbsp; remit to
                          accpro.uk any fees due to accpro.uk under these
                          Terms.
                        </p>
                        <h4>9.2. Non-Refundable Services.</h4>
                        <p>
                          Certain Services may be non-refundable. In such event
                          we will identify&nbsp; such Services as
                          non-refundable, and Customer shall not be entitled,
                          and we shall not be under any&nbsp; obligation, to
                          terminate the Service and give a Refund.
                        </p>
                        <h4>9.3. Chargeback.</h4>
                        <p>
                          If, at any time, we record a decline, chargeback or
                          other rejection of a charge of any&nbsp; due and
                          payable Subscription Fees on Customer’s Account (“
                          <strong>Chargeback</strong>”), this will be considered
                          as&nbsp; a breach of Customer’s payment obligations
                          hereunder, and Customer’s use of the Service may
                          be&nbsp; disabled or terminated and such use of the
                          Service will not resume until Customer re-subscribes
                          for any&nbsp; such Service, and pay any applicable
                          Subscription Fees in full, including any fees and
                          expenses incurred&nbsp; by us and/or any Third Party
                          Service for each Chargeback received (including
                          handling and&nbsp; processing charges and fees
                          incurred by the payment processor), without derogating
                          from any other&nbsp; remedy that may be applicable to
                          us under these Terms or applicable law.
                        </p>
                        <p>&nbsp;</p>
                        <h3>10. Trial Service; Pre-Released Services.</h3>
                        <h4>10.1. Trial Service.</h4>
                        <p>
                          We may offer, from time to time, part or all of our
                          Services on a free, no-obligation&nbsp; trial version
                          (“<strong>Trial Service</strong>”). The term of the
                          Trial Service shall be as communicated to you, within
                          the&nbsp; Service, in an Order Form, unless terminated
                          earlier by either Customer or us, for any reason or
                          for no&nbsp; reason. We reserve the right to modify,
                          cancel and/or limit this Trial Service at any time and
                          without&nbsp; liability or explanation to you. In
                          respect of a Trial Service that is a trial version of
                          the Subscription Plan&nbsp; (the “
                          <strong>Trial Subscription</strong>”), upon
                          termination of the Trial Subscription, we may change
                          the Account web&nbsp; address at any time without any
                          prior written notice.
                        </p>
                        <h4>10.2. Pre-Released Services.</h4>
                        <p>
                          Note that we may offer, from time to time, certain
                          Services in an Alpha or&nbsp; Beta versions (the “
                          <strong>Pre-Released Services</strong>”) and we use
                          best endeavors to identify such Pre-Released&nbsp;
                          Services as such. Pre-Released Services are Services
                          that are still under development, and as such&nbsp;
                          they may be inoperable or incomplete, and may contain
                          bugs, suffer disruptions and/or not operate as&nbsp;
                          intended and designated, more than usual.
                        </p>
                        <h4>
                          10.3. Governing Terms of Trial Service and
                          Pre-Released Services.
                        </h4>
                        <p>
                          The Trial Service and Pre-Released&nbsp; Services are
                          governed by these Terms, provided that notwithstanding
                          anything in these Terms or&nbsp; elsewhere to the
                          contrary, in respect of Trial Service and Pre-Released
                          Services (i) such services are&nbsp; licensed
                          hereunder on as “As-Is”, “With All Faults” “As
                          Available” basis, with no warranties, express or&nbsp;
                          implied, of any kind; (ii) the indemnity undertaking
                          by us set forth in Section 16.2 herein shall not
                          apply;&nbsp; and (iii) IN NO EVENT SHALL THE TOTAL
                          AGGREGATE LIABILITY OF accpro.uk, ITS&nbsp;
                          AFFILIATES OR ITS THIRD PARTY SERVICE PROVIDERS,
                          UNDER, OR OTHERWISE IN CONNECTION&nbsp; WITH, THESE
                          TERMS (INCLUDING THE SITES, THE SERVICE AND THE THIRD
                          PARTY SERVICES),&nbsp; EXCEED US$100. We make no
                          promises that any Trial Service and/or Pre-Released
                          Services will be&nbsp; made available to you and/or
                          generally available.
                        </p>
                        <p>&nbsp;</p>
                        <h3>11. Term and Termination; Suspension.</h3>
                        <h4>11.1. Term.</h4>
                        <p>
                          These Terms are in full force and effect, commencing
                          upon the Effective Date, until the end&nbsp; of the
                          Service underlying the Account, either paid or unpaid,
                          unless terminated otherwise in accordance&nbsp; with
                          these Terms.
                        </p>
                        <h4>11.2. Termination for Cause.</h4>
                        <p>
                          Either Customer or us may terminate the Service and
                          these Terms, upon&nbsp; written notice, in case that
                          (a) the other party is in material breach of these
                          Terms and to the extent,
                        </p>
                        <p>
                          curable, fails to cure such breach, within a
                          reasonable cure period, which shall not be less than
                          10 days&nbsp; following a written notice from by the
                          non-breaching party; or (b) ceases its business
                          operations or&nbsp; becomes subject to insolvency
                          proceedings and the proceedings are not dismissed
                          within 45 days.
                        </p>
                        <h4>11.3. Termination by Customer.</h4>
                        <p>
                          Customer may terminate its Subscription to the Service
                          by cancelling&nbsp; the Service and/or deleting the
                          Account, whereby such termination shall not derogate
                          from Customer’s&nbsp; obligation to pay applicable
                          Subscription Fees except where such termination is
                          made within the Refund&nbsp; Period. In accordance
                          with Section 9 above, unless mutually agreed otherwise
                          by Customer and us in a&nbsp; written instrument, the
                          effective date of such termination will take effect at
                          the end of the then-current&nbsp; Subscription Term,
                          and Customer’s obligation to pay the Subscription Fees
                          throughout the end of such&nbsp; Subscription Term
                          shall remain in full force and effect, and Customer
                          shall not be entitled to a refund for any pre-paid
                          Subscription Fees.
                        </p>
                        <h4>11.4. Effect of Termination of Service.</h4>
                        <p>
                          Upon termination or expiration of these Terms,
                          Customer’s Subscription and all rights granted to you
                          hereunder shall terminate, and we may change the
                          Account’s&nbsp; web address. It is Customer’s sole
                          liability to export the Customer Data prior to such
                          termination or&nbsp; expiration. In the event that
                          Customer did not delete the Customer Data from the
                          Account, we may&nbsp; continue to store and host it
                          until either Customer or we, at our sole discretion,
                          delete such Customer&nbsp; Data, and during such
                          period, Customer shall still be able to make a limited
                          use of the Service in order&nbsp; to export the
                          Customer Data (the “<strong>Read-Only Mode</strong>”),
                          but note that we are not under any obligation to&nbsp;
                          maintain the Read-Only Mode period, hence such period
                          may be terminated by us, at any time, with or&nbsp;
                          without notice to Customer, and subsequently, the
                          Customer Data will be deleted. Customer&nbsp;
                          acknowledges the foregoing and its sole responsibility
                          to export and/or delete the Customer Data prior&nbsp;
                          to the termination or expiration of these Terms, and
                          therefore we shall not have any liability either
                          to&nbsp; Customer, nor to any User or third party, in
                          connection thereto. Unless expressly indicated
                          herein&nbsp; otherwise, the termination or expiration
                          of these Terms shall not relieve Customer from its
                          obligation to&nbsp; pay due Subscription Fees.
                        </p>
                        <h4>11.5. Survival.</h4>
                        <p>
                          Section 2.6 (Responsibility for Authorized Users), 3
                          (Customer Data), 6 (Privacy and&nbsp; Security), 7
                          (Third Party Services), 8 (Subscription Term, Renewal
                          and Fees Payment) in respect of&nbsp; unpaid
                          Subscription Fees, 10.3 (Governing Terms of Trial
                          Services and Pre-Released Services), 11 (Term and
                          Termination; Suspension), 12 (Confidentiality), 13
                          (Warranty Disclaimer), 14 (Limitations of&nbsp;
                          Liability), 16 (Indemnification), 21 (Governing Law
                          and Jurisdiction; Class Action Waiver and Arbitration)
                          and 22 (General Provisions), shall survive the
                          termination or expiration of these Terms, and continue
                          to&nbsp; be in force and effect in accordance with
                          their applicable terms.
                        </p>
                        <h4>11.6. Suspension.</h4>
                        <p>
                          Without derogating from our termination rights above,
                          we may decide to temporarily&nbsp; suspend the Account
                          and/or a User Profile (including any access thereto)
                          and/or our Service, in the&nbsp; following events: (i)
                          we believe, at our sole discretion, that you or any
                          third party, are using the Service&nbsp; in a manner
                          that may impose a security risk, may cause harm to us
                          or any third party, and/or may raise&nbsp; any
                          liability for us or any third party; (ii) we believe,
                          at our sole discretion, that you or any third party,
                          are&nbsp; using the Service in breach of these Terms
                          or applicable Law; (iii) Customer’s payment
                          obligations, in&nbsp; accordance with these Terms, are
                          or are likely to become, overdue; or (iv) Customer’s
                          or any of its&nbsp; Users’ breach of the{" "}
                          <a href="https://accpro.uk/terms/aup">
                            Acceptable Use Policy
                          </a>
                          . The afore-mentioned suspension rights are in
                          addition to&nbsp; any remedies that may be available
                          to us in accordance with these Terms and/or applicable
                          Law.
                        </p>
                        <p>&nbsp;</p>
                        <h3>12. Confidentiality.</h3>
                        <h4>12.1. Confidential Information.</h4>
                        <p>
                          In connection with these Terms and the Service
                          (including the evaluation&nbsp; thereof), each party
                          (“<strong>Disclosing Party</strong>”) may disclose to
                          the other party (“<strong>Receiving Party</strong>”),
                          non-public&nbsp; business, product, technology and
                          marketing information, including without limitation,
                          customers lists&nbsp; and information, know-how,
                          software and any other non-public information that is
                          either identified as&nbsp; such or should reasonably
                          be understood to be confidential given the nature of
                          the information and the&nbsp; circumstances of
                          disclosure, whether disclosed prior or after the
                          Effective Date (the “<strong>Confidential</strong>{" "}
                          <strong>Information</strong>”). For the avoidance of
                          doubt, (i) Customer Data is regarded as Customer’s
                          Confidential&nbsp; Information, and (ii) our Site,
                          Service, Trial Service and/or Pre-Released Services,
                          inclusive of their&nbsp; underlying technology, and
                          their respective performance information, as well as
                          any data, reports and&nbsp; materials we provided to
                          you in connection with your evaluation or use of the
                          Service, are regarded as&nbsp; our Confidential
                          Information. Confidential Information does not include
                          information that (a) is or becomes&nbsp; generally
                          available to the public without breach of any
                          obligation owed to the Disclosing Party; (b) was&nbsp;
                          known to the Receiving Party prior to its disclosure
                          by the Disclosing Party without breach of any&nbsp;
                          obligation owed to the Disclosing Party; (c) is
                          received from a third party without breach of any
                          obligation&nbsp; owed to the Disclosing Party; or (d)
                          was independently developed by the Receiving Party
                          without any&nbsp; use or reference to the Confidential
                          Information.
                        </p>
                        <h4>
                          12.2. Confidentiality Undertakings by the Receiving
                          Party.
                        </h4>
                        <p>
                          The Receiving Party will (i) take at least&nbsp;
                          reasonable measures to prevent the unauthorized
                          disclosure or use of Confidential Information, and
                          limit&nbsp; access to those employees, affiliates,
                          service providers and agents, on a need to know basis
                          and who&nbsp; are bound by confidentiality obligations
                          at least as restrictive as those contained herein; and
                          (ii) not use&nbsp; or disclose any Confidential
                          Information to any third party, except as part of its
                          performance under these&nbsp; Terms and as required to
                          be disclosed to legal or financial advisors to the
                          Receiving Party or in&nbsp; connection with a due
                          diligence process that the Receiving Party is
                          undergoing, provided that any such&nbsp; disclosure
                          shall be governed by confidentiality obligations at
                          least as restrictive as those contained&nbsp; herein.
                        </p>
                        <h4>12.3. Compelled Disclosure.</h4>
                        <p>
                          Notwithstanding the above, Confidential Information
                          may be disclosed&nbsp; pursuant to the order or
                          requirement of a court, administrative agency or other
                          governmental body;&nbsp; provided, however, that to
                          the extent legally permissible, the Receiving Party
                          shall make best efforts to&nbsp; provide prompt
                          written notice of such court order or requirement to
                          the Disclosing Party to enable the&nbsp; Disclosing
                          Party to seek a protective order or otherwise prevent
                          or restrict such disclosure.
                        </p>
                        <p>&nbsp;</p>
                        <h3>13. Warranty Disclaimer.</h3>
                        <p>
                          NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE
                          TO THE CONTRARY AND TO&nbsp; THE FULLEST EXTENT
                          PERMITTED BY APPLICABLE LAW:
                        </p>
                        <p>
                          13.1. EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SITES
                          AND THE SERVICE ARE PROVIDED&nbsp; ON AN “AS IS”,
                          “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, AND
                          WITHOUT WARRANTIES OF&nbsp; ANY KIND. WE AND OUR
                          AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS
                          (INCLUDING,&nbsp; THE THIRD PARTY SERVICE PROVIDERS,
                          HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS&nbsp; AND
                          WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION,
                          WARRANTIES AND/OR&nbsp; REPRESENTATIONS OF
                          MERCHANTABILITY, FUNCTIONALITY, TITLE, FITNESS FOR A
                          PARTICULAR&nbsp; PURPOSE AND NON-INFRINGEMENT, WHETHER
                          EXPRESS, IMPLIED OR STATUTORY.
                        </p>
                        <p>
                          13.2. WE AND OUR VENDORS DO NOT WARRANT, AND EXPRESSLY
                          DISCLAIM ANY WARRANTY&nbsp; OR REPRESENTATION THAT THE
                          SERVICE AND SITES, INCLUDING THE ACCESS THERETO
                          AND&nbsp; USE THEREOF, WILL BE UNINTERRUPTED, TIMELY,
                          SECURED, ERROR FREE, THAT DATA WON’T&nbsp; BE LOST,
                          THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITES
                          AND/OR SERVICE ARE FREE&nbsp; FROM VIRUSES OR OTHER
                          HARMFUL CODE. WE AND OUR VENDORS FURTHER DISCLAIM
                          ANY&nbsp; AND ALL LIABILITY OR RESPONSIBILITY FOR ANY
                          DELAYS, FAILURES, INTERCEPTION,&nbsp; ALTERATION,
                          LOSS, OR OTHER DAMAGES THAT YOU AND/OR YOUR DATA
                          (INCLUDING&nbsp; CUSTOMER DATA) MAY SUFFER, THAT ARE
                          BEYOND OUR CONTROL.
                        </p>
                        <p>
                          13.3. EXCEPT AS EXPRESSLY SET FORTH HEREIN, WE DO NOT
                          WARRANT, AND EXPRESSLY&nbsp; DISCLAIM ANY WARRANTY OR
                          REPRESENTATION (I) THAT OUR SERVICE (OR ANY
                          PORTION&nbsp; THEREOF) IS COMPLETE, ACCURATE, OF ANY
                          CERTAIN QUALITY, RELIABLE, SUITABLE FOR, OR&nbsp;
                          COMPATIBLE WITH, ANY OF YOUR CONTEMPLATED ACTIVITIES,
                          DEVICES, OPERATING&nbsp; SYSTEMS, BROWSERS, SOFTWARE
                          OR TOOLS (OR THAT IT WILL REMAIN AS SUCH AT ANY TIME),
                          OR COMPLY WITH ANY LAWS APPLICABLE TO YOU; AND/OR (II)
                          REGARDING ANY CONTENT,&nbsp; INFORMATION, REPORTS OR
                          RESULTS THAT YOU OBTAIN THROUGH THE SERVICE AND/OR
                          THE&nbsp; SITES.
                        </p>
                        <p>&nbsp;</p>
                        <h3>14. Limitation of Liability.</h3>
                        <p>
                          NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE
                          TO THE CONTRARY AND TO&nbsp; THE FULLEST EXTENT
                          PERMITTED BY APPLICABLE LAW:
                        </p>
                        <p>
                          14.1. IN NO EVENT SHALL EITHER PARTY HERETO AND ITS
                          AFFILIATES, SUBCONTRACTORS,&nbsp; AGENTS AND VENDORS
                          (INCLUDING, THE THIRD PARTY SERVICE PROVIDERS), BE
                          LIABLE&nbsp; UNDER, OR OTHERWISE IN CONNECTION WITH
                          THESE TERMS FOR (I) ANY INDIRECT,&nbsp; EXEMPLARY,
                          SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE
                          DAMAGES; (II) ANY LOSS OF PROFITS, COSTS, ANTICIPATED
                          SAVINGS; (III) ANY LOSS OF, OR DAMAGE TO DATA,
                          USE,&nbsp; BUSINESS, REPUTATION, REVENUE OR GOODWILL;
                          AND/OR (IV) THE FAILURE OF SECURITY&nbsp; MEASURES AND
                          PROTECTIONS, WHETHER IN CONTRACT, TORT OR UNDER ANY
                          OTHER THEORY&nbsp; OF LIABILITY OR OTHERWISE, AND
                          WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF
                          THE&nbsp; POSSIBILITY OF SUCH DAMAGES IN ADVANCE, AND
                          EVEN IF A REMEDY FAILS OF ITS ESSENTIAL&nbsp; PURPOSE.
                        </p>
                        <p>
                          14.2. EXCEPT FOR THE INDEMNITY OBLIGATIONS OF EITHER
                          PARTY UNDER SECTION 16 (INDEMNIFICATION) HEREIN, YOUR
                          PAYMENT OBLIGATIONS HEREUNDER OR BREACH OF OUR{" "}
                          <a href="https://accpro.uk/terms/aup">
                            ACCEPTABLE USE POLICY
                          </a>{" "}
                          BY EITHER YOU OR IN CASE OF A CUSTOMER, ANY OF THE
                          USERS&nbsp; UNDERLYING ITS ACCOUNT, IN NO EVENT SHALL
                          THE TOTAL AGGREGATE LIABILITY OF EITHER&nbsp; PARTY,
                          ITS AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS
                          (INCLUDING, THE ITS&nbsp; THIRD-PARTY SERVICE
                          PROVIDERS), UNDER, OR OTHERWISE IN CONNECTION WITH,
                          THESE TERMS (INCLUDING THE SITES AND THE SERVICE),
                          EXCEED THE TOTAL AMOUNT OF FEES&nbsp; ACTUALLY PAID BY
                          YOU (IF ANY) DURING THE 12 CONSECUTIVE MONTHS
                          PRECEDING THE&nbsp; EVENT GIVING RISE TO SUCH
                          LIABILITY. THIS LIMITATION OF LIABILITY IS CUMULATIVE
                          AND NOT&nbsp; PER INCIDENT.
                        </p>
                        <p>&nbsp;</p>
                        <h3>
                          15. Specific Laws; Reasonable Allocation of Risks.
                        </h3>
                        <h4>15.1. Specific Laws.</h4>
                        <p>
                          Except as expressly stated in these Terms, we make no
                          representations or&nbsp; warranties that your use of
                          the Service is appropriate in your jurisdiction. Other
                          than as indicated herein,&nbsp; you are responsible
                          for your compliance with any local and/or specific
                          applicable Laws, as applicable to&nbsp; your use of
                          the Service.
                        </p>
                        <h4>15.2. Reasonable Allocation of Risks.</h4>
                        <p>
                          You hereby acknowledge and confirm that the
                          limitations of liability and warranty disclaimers
                          contained in these Terms are agreed upon by you and us
                          and we both find&nbsp; such limitations and allocation
                          of risks to be commercially reasonable and suitable
                          for our engagement&nbsp; hereunder, and both you and
                          us have relied on these limitations and risk
                          allocation in determining&nbsp; whether to enter these
                          Terms.
                        </p>
                        <p>&nbsp;</p>
                        <h3>16. Indemnification.</h3>
                        <h4>16.1. By Customer.</h4>
                        <p>
                          Customer hereby agrees to indemnify, defend and hold
                          harmless accpro.uk and&nbsp; its affiliates,
                          officers, directors, employees and agents from and
                          against any and all claims, damages,&nbsp;
                          obligations, liabilities, losses, reasonable expenses
                          or costs (collectively, “<strong>Losses</strong>”)
                          incurred as a result&nbsp; of any third party claim
                          arising from (i) Customer’s and/or any of its Users’,
                          violation of these Terms or&nbsp; applicable Law;
                          and/or (ii) Customer Data, including the use of
                          Customer Data by accpro.uk and/or any of its
                          subcontractors, infringes or violates, any third
                          party’s rights, including, without limitation,&nbsp;
                          intellectual property, privacy and/or publicity
                          rights.
                        </p>
                        <h4>16.2. By accpro.uk.</h4>
                        <p>
                          accpro.uk hereby agrees to defend Customer, its
                          affiliates, officers, directors,&nbsp; and employees,
                          in and against any third party claim or demand against
                          Customer, alleging that Customer’s authorized use of
                          the Service infringes or constitutes misappropriation
                          of any third party’s copyright, trademark or
                          registered US patent (the <strong>“IP Claim</strong>
                          ”), and we will indemnify Customer and hold&nbsp;
                          Customer harmless against any damages and costs
                          finally awarded on such IP Claim by a court of&nbsp;
                          competent jurisdiction or agreed to via settlement we
                          agreed upon, including reasonable attorneys’ fees.
                        </p>
                        <p>
                          accpro.uk’s indemnity obligations under this Section
                          16 shall not apply if: (i) the Service (or any&nbsp;
                          portion thereof) was modified by Customer or any of
                          its Users or any third party, but solely to the
                          extent&nbsp; the IP Claim would have been avoided by
                          not doing such modification; (ii) if the Service is
                          used in&nbsp; combination with any other service,
                          device, software or products, including, without
                          limitation, Third&nbsp; Party Services, but solely to
                          the extent that such IP Claim would have been avoided
                          without such &nbsp;combination; and/or (iii) any IP
                          Claim arising or related to, the Customer Data or to
                          any events giving&nbsp; rise to Customer’s indemnity
                          obligations under Section 16.1 above. Without
                          derogating from the&nbsp; foregoing defense and
                          indemnification obligation, if accpro.uk believes
                          that the Service, or any part&nbsp; thereof, may so
                          infringe, then accpro.uk may in its sole discretion:
                          (a) obtain (at no additional cost to&nbsp; you) the
                          right to continue to use the Service; (b) replace or
                          modify the allegedly infringing part of the&nbsp;
                          Service so that it becomes non-infringing while giving
                          substantially equivalent performance; or (c) if&nbsp;
                          accpro.uk determines that the foregoing remedies are
                          not reasonably available, then accpro.uk&nbsp; may
                          require that use of the (allegedly) infringing Service
                          (or part thereof) shall cease and in such an&nbsp;
                          event, Customer shall receive a prorated refund of any
                          Subscription Fees paid for the unused portion of&nbsp;
                          the Subscription Term. THIS SECTION 16.2 STATES
                          accpro.uk’S SOLE AND ENTIRE LIABILITY&nbsp; AND YOUR
                          EXCLUSIVE REMEDY, FOR ANY INTELLECTUAL PROPERTY
                          INFRINGEMENT OR&nbsp; MISAPPROPRIATION BY accpro.uk
                          AND/OR ITS SERVICE AND UNDERLYING TECHNOLOGY.
                        </p>
                        <h4>16.3. Indemnity Conditions.</h4>
                        <p>
                          The defense and indemnification obligations of the
                          indemnifying party under&nbsp; this Section 16 are
                          subject to: (i) the indemnified party shall promptly
                          provide a written notice of the&nbsp; claim for which
                          an indemnification is being sought, provided that such
                          indemnitee’s failure to do so will&nbsp; not relieve
                          the indemnifying party of its obligations under this
                          Section 16, except to the extent the&nbsp;
                          indemnifying party’s defense is materially prejudiced
                          thereby; (ii) the indemnifying party being given&nbsp;
                          immediate and exclusive control over the defense
                          and/or settlement of the claim, <u>provided</u>,{" "}
                          <u>however </u>that&nbsp; the indemnifying party shall
                          not enter into any compromise or settlement of any
                          such claim that that&nbsp; requires any monetary
                          obligation or admission of liability or any
                          unreasonable responsibility or liability by&nbsp; an
                          indemnitee without the prior written consent of the
                          affected indemnitee, which shall not be&nbsp;
                          unreasonably withheld or delayed; and (iii) the
                          indemnified party providing reasonable cooperation
                          and&nbsp; assistance, at the indemnifying party’s
                          expense, in the defense and/or settlement of such
                          claim and not&nbsp; taking any action that prejudices
                          the indemnifying party’s defense of, or response to,
                          such claim.
                        </p>
                        <p>&nbsp;</p>
                        <h3>17. Third Party Components within Our Service</h3>
                        <p>
                          Our Service includes third party codes and libraries
                          that are subject to third party open source
                          license&nbsp; terms (the “
                          <strong>Open Source Code</strong>” and the “
                          <strong>Open Source Terms</strong>”, respectively).
                          Some of such Open&nbsp; Source Terms determine that to
                          the extent applicable to the respective Open Source
                          Code licensed&nbsp; thereunder, such terms prevail
                          over any conflicting license terms, including these
                          Terms. We use our&nbsp; best endeavors to identify
                          such Open Source Code, within our Service, hence we
                          encourage Customer&nbsp; to familiarize itself with
                          such Open Source Terms. Note that we use best efforts
                          to use only Open Source&nbsp; Codes that does not
                          impose any obligation or affect the Customer Data or
                          related intellectual property&nbsp; (beyond what is
                          stated in the Open Source Terms and herein), on an
                          ordinary use of our Service that&nbsp; does not
                          involve any modification, distribution or independent
                          use of such Open Source Code. Notwithstanding anything
                          to the contrary, we make no warranty or indemnity
                          hereunder with respect to&nbsp; any Open Source Codes.{" "}
                          <span style={{ fontWeight: 300 }}>
                            The following copyright statements and licenses
                            apply to discrete Service components that are
                            distributed with various versions of the accpro.uk
                            products:{" "}
                          </span>
                          <a href="https://view.accpro.uk/234681045-ff95f969dd7ec10a8878aaac7d7a66ab">
                            <span style={{ fontWeight: 300 }}>
                              Open Source List.
                            </span>
                          </a>
                        </p>
                        <p>&nbsp;</p>
                        <h3>18. Export Controls; Sanctions.</h3>
                        <p>
                          The Service may be subject to Israeli, U.S. or foreign
                          export controls, Laws and regulations (the “
                          <strong>Export&nbsp; Controls</strong>”), and you
                          acknowledge and confirm that: (i) you are not located
                          or use, export, re-export or&nbsp; import the Service
                          (or any portion thereof) in or to, any person, entity,
                          organization, jurisdiction or&nbsp; otherwise, in
                          violation of the Export Controls; (ii) you are not:
                          (a) organized under the laws of, operating&nbsp; from,
                          or otherwise ordinarily resident in a country or
                          territory that is the target or comprehensive
                          U.S.&nbsp; economic or trade sanctions (currently,
                          Cuba, Iran, Syria, North Korea, or the Crimea region
                          of Ukraine),&nbsp; (b) identified on a list of
                          prohibited or restricted persons, such as the U.S.
                          Treasury Department’s List of&nbsp; Specially
                          Designated Nationals and Blocked Persons, or (c)
                          otherwise the target of U.S. sanctions.&nbsp; Customer
                          is solely responsible for complying with applicable
                          Export Controls and sanctions which may&nbsp; impose
                          additional restrictions, prohibitions or requirements
                          on the use, export, re-export or import of the&nbsp;
                          Services and/or the Customer Data; and (iii) Customer
                          Data is not controlled under the U.S.
                          International&nbsp; Traffic in Arms Regulations or
                          similar Laws in other jurisdictions, or otherwise
                          requires any special&nbsp; permission or license, in
                          respect of its use, import, export or re-export
                          hereunder.
                        </p>
                        <p>&nbsp;</p>
                        <h3>19. Modifications.</h3>
                        <p>
                          Occasionally we may make changes to these Terms for
                          valid reasons, such as adding new functions or&nbsp;
                          features to the Service, technical adjustments, typos
                          or error fixing, for legal or regulatory reasons or
                          for&nbsp; any other reasons as we deem necessary, at
                          our sole discretion. When we make material changes
                          to&nbsp; these Terms, we’ll provide Customer with
                          notice as appropriate under the circumstances, e.g.,
                          by&nbsp; displaying a prominent notice within the
                          Service or by sending Customer an email. Your
                          continued use&nbsp; of the Service after the changes
                          have been implemented will constitute your acceptance
                          of the changes.
                        </p>
                        <p>&nbsp;</p>
                        <h3>20. Government Use.</h3>
                        <p>
                          If Customer is part of a U.S. Government agency,
                          department or otherwise, either federal, state or
                          local&nbsp; (a “<strong>Government Customer</strong>
                          ”), then Government Customer hereby agrees that the
                          Service under these Terms qualifies as “Commercial
                          Computer Software” and “Commercial Computer
                          Software&nbsp; Documentation”, within the meaning of
                          Federal Acquisition Regulation (“FAR”) 2.101, FAR
                          12.212,&nbsp; Defense Federal Acquisition Regulation
                          Supplement (“DFARS”) 227.7201, and DFARS
                          252.227-7014.&nbsp; Government Customer further agrees
                          that the terms of this Section 20 shall apply to
                          Customer.&nbsp; Government Customer’s technical data
                          and software rights related to the Service include
                          only those&nbsp; rights customarily provided to the
                          public as specified in these Terms in accordance with
                          FAR 12.212,&nbsp; FAR 27.405-3, FAR 52.227-19, DFARS
                          227.7202-1 and General Services Acquisition
                          Regulation&nbsp; (“GSAR”) 552.212-4(w) (as
                          applicable). In no event shall source code be provided
                          or considered to be&nbsp; a deliverable or a software
                          deliverable under these Terms. We grant no license
                          whatsoever to any&nbsp; Government Customer to any
                          source code contained in any deliverable or a software
                          deliverable. If a&nbsp; Government Customer has a need
                          for rights not granted under the Terms, it must
                          negotiate with us to&nbsp; determine if there are
                          acceptable terms for granting those rights, and a
                          mutually acceptable written&nbsp; addendum
                          specifically granting those rights must be included in
                          any applicable agreement. Any&nbsp; unpublished-rights
                          are reserved under applicable copyright laws. Any
                          provisions contained in these&nbsp; Terms that
                          contradict any Law applicable to a Government
                          Customer, shall be limited solely to the extent&nbsp;
                          permitted under such applicable Law.
                        </p>
                        <p>&nbsp;</p>
                        <h3>
                          21. Governing Law and Jurisdiction; Class Action
                          Waiver and Mandatory&nbsp; Arbitration.
                        </h3>
                        <h4>21.1. Governing Law; Jurisdiction.</h4>
                        <p>
                          These Terms and any action related thereto will be
                          governed and&nbsp; interpreted by and under the laws
                          of the State of Israel without giving effect to any
                          conflicts of laws&nbsp; principles that require the
                          application of the law of a different jurisdiction.
                          Courts of competent jurisdiction&nbsp; located in Tel
                          Aviv-Jaffa, Israel, shall have the sole and exclusive
                          jurisdiction and venue over all
                        </p>
                        <p>
                          controversies and claims arising out of, or relating
                          to, these Terms. You and us mutually agree that
                          the&nbsp; United Nations Convention on Contracts for
                          the International Sale of Goods does not apply to
                          these&nbsp; Terms. Notwithstanding the foregoing,
                          accpro.uk reserves the right to seek injunctive
                          relief in any&nbsp; court in any jurisdiction.
                        </p>
                        <h4>21.2. Class Action Waiver.</h4>
                        <p>
                          WHERE PERMITTED UNDER APPLICABLE LAW, YOU AND
                          accpro.uk AGREE THAT EACH PARTY MAY BRING CLAIMS
                          AGAINST THE OTHER PARTY ONLY IN YOUR OR&nbsp; ITS
                          INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
                          MEMBER IN ANY PURPORTED&nbsp; CLASS OR REPRESENTATIVE
                          ACTION. Unless both you and accpro.uk mutually agree,
                          no arbitrator&nbsp; or judge may consolidate more than
                          one person’s claims or otherwise preside over any form
                          of a&nbsp; representative or className proceeding.
                        </p>
                        <h4>21.3. Arbitration.</h4>
                        <p>
                          To the extent permitted under applicable Law, you and
                          accpro.uk hereby&nbsp; irrevocably agree to the
                          following provisions:
                        </p>
                        <h5>
                          21.3.1 <em>Dispute resolution and Arbitration.</em>
                        </h5>
                        <p>
                          Any dispute, claim, or controversy between you and us
                          arising&nbsp; in connection with, or relating in any
                          way to, these Terms (whether based in contract,
                          tort,&nbsp; statute, fraud, misrepresentation, or any
                          other legal theory, and whether the claims arise
                          during&nbsp; for after the termination or expiration
                          of these Terms) will be determined solely by
                          mandatory&nbsp; binding arbitration. In arbitration
                          there is no judge or jury and court review of an
                          arbitration award &nbsp;is limited. However, an
                          arbitrator can award on an individual basis the same
                          damages and relief &nbsp;as a court (including
                          injunctive and declaratory relief or statutory
                          damages) and must follow the &nbsp;terms of these
                          Terms as a court would.
                        </p>
                        <h5>
                          21.3.2 <em>Exception</em>.
                        </h5>
                        <p>
                          Notwithstanding clause 21.3.1 above, you and
                          accpro.uk both agree that nothing herein will be
                          deemed to waive, preclude, or otherwise limit either
                          of our rights, at any time, to&nbsp; seek injunctive
                          relief in a court of law. In addition to the above,
                          notwithstanding clause 21.3.1 above, accpro.uk may
                          file a suit in a court of law against you to address
                          intellectual property&nbsp; infringement claims.
                        </p>
                        <h5>
                          21.3.3 <em>Arbitration Process Rules</em>.
                        </h5>
                        <p>
                          Either you or we may start arbitration proceedings.
                          Any arbitration&nbsp; between you and us will be
                          finally settled under the Rules of Arbitration of the
                          International&nbsp; Chamber of Commerce (the “
                          <strong>ICC</strong>”) then in force (the “
                          <strong>ICC Rules</strong>”) by one arbitrator
                          appointed&nbsp; in accordance with the ICC Rules. The
                          arbitration will take place in Tel Aviv-Jaffa, and
                          shall be&nbsp; conducted in the English language and
                          unless otherwise required by a mandatory law of any
                          jurisdiction, the law to be applied in any arbitration
                          shall be the law of the State of Israel, without&nbsp;
                          regard to choice or conflicts of law principles. The
                          arbitration proceedings shall be conducted&nbsp; on an
                          expedited basis and shall result in an award within no
                          more than 60 days. The arbitration&nbsp; shall be
                          conducted on a confidential basis. The award of the
                          Arbitrator shall be final and binding&nbsp; on the
                          parties. The arbitration award shall be enforceable in
                          any court of competent&nbsp; jurisdiction. Any motion
                          to enforce or vacate an arbitration award under this
                          agreement shall be&nbsp; kept confidential to the
                          maximum extent possible.
                        </p>
                        <h5>
                          21.3.4 <em>Special Statute of Limitation</em>.
                        </h5>
                        <p>
                          Any arbitration must be commenced by filing a demand
                          for&nbsp; arbitration within 2 years after the date
                          the party asserting the claim first knows or
                          reasonably&nbsp; should know of the act, omission, or
                          default giving rise to the claim; and there shall be
                          no right &nbsp;to any remedy for any claim not
                          asserted within that time period. If applicable law
                          prohibits such limitation period for asserting claims,
                          any claim must be asserted within the shortest time
                          period&nbsp; permitted by applicable Law.
                        </p>
                        <h5>
                          21.3.5 <em>Notice; Process</em>.
                        </h5>
                        <p>
                          A party who intends to seek arbitration must first
                          send a written notice of the&nbsp; dispute to the
                          other, by certified mail or Federal Express (signature
                          required), or in the event that&nbsp; we do not have a
                          physical address on file for you, by electronic mail
                          (“<strong>Dispute Notice</strong>”). The&nbsp; Dispute
                          Notice must (i) describe the nature and basis of the
                          claim or dispute; and (ii) set forth&nbsp; the
                          specific relief sought. We agree to use good faith
                          efforts to resolve the claim directly, but if
                        </p>
                        <p>
                          we do not reach an agreement to do so within 30 days
                          after the Dispute Notice is received, you&nbsp; or us
                          may commence an arbitration proceeding. During the
                          arbitration, the amount of any&nbsp; settlement offer
                          made by you or us shall not be disclosed to the
                          arbitrator until after the arbitrator &nbsp;makes a
                          final decision and award, if any. Without derogating
                          from the generality of the&nbsp; confidentiality
                          protection under Section 21.3.3 above, all documents
                          and information disclosed&nbsp; in the course of the
                          arbitration shall be kept strictly confidential by the
                          recipient and shall not be&nbsp; used by the recipient
                          for any purpose other than for purposes of the
                          arbitration or the&nbsp; enforcement of the
                          arbitrator’s decision and award and shall not be
                          disclosed except in&nbsp; confidence to persons who
                          have a need to know for such purposes or as required
                          by applicable&nbsp; Law. Except as required to enforce
                          the arbitrator’s decision and award, neither you nor
                          us shall&nbsp; make any public announcement or public
                          comment or originate any publicity concerning
                          the&nbsp; arbitration, including, but not limited to,
                          the fact that the parties are in dispute, the
                          existence of&nbsp; the arbitration, or any decision or
                          award of the arbitrator.
                        </p>
                        <p>&nbsp;</p>
                        <h3>22. General Provisions.</h3>
                        <h4>22.1. Translated Versions.</h4>
                        <p>
                          These Terms were written in English, and translated
                          into other languages for&nbsp; your convenience. If a
                          translated (non-English) version of these Terms
                          conflicts in any way with their&nbsp; English version,
                          the provisions of the English version shall prevail.
                        </p>
                        <h4>22.2. Force Majeure.</h4>
                        <p>
                          Neither us nor you will be liable by reason of any
                          failure or delay in the performance&nbsp; of its
                          obligations on account of events beyond the reasonable
                          control of a party, which may include&nbsp;
                          denial-of-service attacks, interruption or failure of
                          the Internet or any utility service, failures in
                          third-party&nbsp; hosting services, strikes,
                          shortages, riots, fires, acts of God, war, terrorism,
                          and governmental action.
                        </p>
                        <h4>
                          22.3. Relationship of the Parties; No Third Party
                          Beneficiaries.
                        </h4>
                        <p>
                          The parties are independent contractors.&nbsp; These
                          Terms and the Service provided hereunder, do not
                          create a partnership, franchise, joint venture,&nbsp;
                          agency, fiduciary or employment relationship between
                          the parties. There are no third party
                          beneficiaries&nbsp; to these Terms.
                        </p>
                        <h4>22.4. Notice.</h4>
                        <p>
                          We shall use your contact details that we have in our
                          records, in connection with providing&nbsp; you
                          notices, subject to this Section 22.4. Our contact
                          details for any notices are detailed below. You&nbsp;
                          acknowledge notices that we provide you, in connection
                          with these Terms and/or as otherwise related&nbsp; to
                          the Service, shall be provided as follows: via the
                          Service, including by posting on our Sites or
                          posting&nbsp; in your account, text, in-app
                          notification, e-mail, phone or first className,
                          airmail, or overnight courier. You&nbsp; further
                          acknowledge that an electronic notification satisfies
                          any applicable legal notification&nbsp; requirements,
                          including that such notification will be in writing.
                          Any notice to you will be deemed given&nbsp; upon the
                          earlier of: (i) receipt; or (ii) 24 hours of delivery.
                          Notices to us shall be provided to accpro.uk&nbsp;
                          Ltd., attn: General Counsel, at{" "}
                          <a href="mailto:legal@accpro.uk">legal@accpro.uk</a>{" "}
                          or sent to 52 6 Yitzhak Sadeh St. Tel-Aviv, Israel.
                        </p>
                        <h4>22.5. Assignment.</h4>
                        <p>
                          These Terms, and any and all rights and obligations
                          hereunder, may not be&nbsp; transferred or assigned by
                          you without our written approval, provided that you
                          may assign these Terms&nbsp; to your successor entity
                          or person, resulting from a merger, acquisition, or
                          sale of all or substantially all&nbsp; of your assets
                          or voting rights, except for an assignment to a
                          competitor of accpro.uk, and provided&nbsp; that you
                          provide us with prompt written notice of such
                          assignment and the respective assignee agrees,&nbsp;
                          in writing, to assume all of your obligations under
                          these Terms. We may assign our rights and/or&nbsp;
                          obligations hereunder and/or transfer ownership rights
                          and title in the Service to a third party
                          without&nbsp; your consent or prior notice to you.
                          Subject to the foregoing conditions, these Terms shall
                          bind and inure&nbsp; to the benefit of the parties,
                          their respective successors, and permitted assigns.
                          Any assignment not&nbsp; authorized under this Section
                          22.5 shall be null and void.
                        </p>
                        <h4>22.6. Severability.</h4>
                        <p>
                          These Terms shall be enforced to the fullest extent
                          permitted under applicable Law.&nbsp; If any provision
                          of these Terms is held by a court of competent
                          jurisdiction to be contrary to law, the
                        </p>
                        <p>
                          provision will be modified by the court and
                          interpreted so as best to accomplish the objectives of
                          the&nbsp; original provision to the fullest extent
                          permitted by law, and the remaining provisions of
                          these Terms will&nbsp; remain in effect.
                        </p>
                        <h4>22.7. No Waiver.</h4>
                        <p>
                          No failure or delay by either party in exercising any
                          right under these Terms will&nbsp; constitute a waiver
                          of that right. No waiver under these Terms will be
                          effective unless made in writing&nbsp; and signed by
                          an authorized representative of the party being deemed
                          to have granted the waiver.
                        </p>
                        <p>Last update: April 17, 2022</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                      </div>

                      <hr />
                    </div>
                  </section>

                  <div className="disclaimer"></div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
