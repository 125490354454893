import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import RenameSvg from '../svg/rename';
import DeleteSvg from '../svg/delete';
import PlusSvg from '../svg/plus';

function FolderPopupComponent({ folder, onClose, onEdit, onDelete, onTemplateNew, selectedFolderLocation }) {
  return (
    <Menu
      open={Boolean(selectedFolderLocation)} // Menu will be open if selectedFolderLocation is provided
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        selectedFolderLocation ? { top: selectedFolderLocation, left: 220 } : undefined
      }
    >
      <MenuItem onClick={onTemplateNew}>
        <ListItemIcon>
          <PlusSvg />
        </ListItemIcon>
        <ListItemText primary="Page from template" />
      </MenuItem>

      <MenuItem onClick={onEdit}>
        <ListItemIcon>
          <RenameSvg />
        </ListItemIcon>
        <ListItemText primary="Rename" />
      </MenuItem>

      <MenuItem onClick={onDelete}>
        <ListItemIcon>
          <DeleteSvg />
        </ListItemIcon>
        <ListItemText primary="Delete" />
      </MenuItem>
    </Menu>
  );
}

export default FolderPopupComponent;
