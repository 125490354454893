import {React , useEffect , useState} from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Box,
} from "@mui/material";
import "../css/resources.css";
import { Link } from "react-router-dom";

// Card Component
function ResourceCard({ image, heading, text, linkText, type }) {


  return (
    <Card className={type === "0" ? "res-card" : "res-card-2"}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={heading}
        className={type === "0" ? "res-card-img" : "res-card-img1"}
      />
      <CardContent
        className={type === "0" ? "res-card-cont" : "res-card-cont1"}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="res-card-tit"
        >
          {heading}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="res-card-text"
        >
          {text}
        </Typography>
        <Link
          variant="contained"
          color="primary"
          href={linkText}
          sx={{ width: "100%" }}
        >
          {linkText}
        </Link>
      </CardContent>
    </Card>
  );
}

function ResourceCard2({ image, heading, text, linkText, type }) {
  if (type == "0") {
    return (
      <div className="rescard2-cont ">
        <div className="rescard2-contin">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="rescard2-card-tit"
          >
            {heading}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="rescard2-card-text"
          >
            {text}
          </Typography>
          <Link
            variant="contained"
            color="primary"
            href={linkText}
            sx={{ width: "100%" }}
          >
            {linkText}
          </Link>
        </div>
        <div className="rescard2-contin rescard2-contin-img ">
          <img src={image} alt={heading} className="rescard2-cont-img" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="rescard2-cont">
        <div className="rescard2-contin rescard2-contin-img ">
          <img src={image} alt={heading} className="rescard2-cont-img" />
        </div>

        <div className="rescard2-contin">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="rescard2-card-tit"
          >
            {heading}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="rescard2-card-text"
          >
            {text}
          </Typography>
          <Link
            variant="contained"
            color="primary"
            href={linkText}
            sx={{ width: "100%" }}
          >
            {linkText}
          </Link>
        </div>
      </div>
    );
  }
}

function Card2({ image }) {
  return (
    <Card className="res-card1">
      {/* <a> */}
      <CardMedia
        component="img"
        height="140"
        image={image}
        className="res-card1-img"
      />
      {/* </a> */}
    </Card>
  );
}

function ResourcesPage() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsLoading(false), 5000); // 5 seconds, adjust as needed
    window.onload = () => {
      clearTimeout(timeoutId);
      setIsLoading(false);
    };

    return () => {
      clearTimeout(timeoutId);
      window.onload = null;
    };
  }, []);


  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container style={{ maxWidth: "100%", padding: "0px" }}>
          {/* Section 1 */}
          <section>
            <Typography variant="h1" className="res-head1">
              Accpro Resource Center
            </Typography>
            <Typography className="res-head2">
              Your single destination to get the most out of Accpro, with best
              practices, demos, how-tos, and guides.
            </Typography>
          </section>

          {/* Section 2 */}
          <section className="res-sec2">
            <section className="res-sec2in">
              <Typography variant="h2" className="res-head3">
                Product guides
              </Typography>
              <Grid container spacing={3}>
                {/* Card 1 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:7826d092-80d4-4316-a91d-74c2d1c93ea9/1_Get%20started%20with%20Confluence.svg?cdnVersion=1189"
                    heading="Get started with Accpro"
                    text="All the Accpro basics to help you get going including key features and hosting options. "
                    linkText="Get started"
                    type="0"
                  />
                </Grid>
                {/* Card 2 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:cb4bc211-c1df-4c1b-be44-09d9e24771f4/1_Apps%20and%20integrations.svg?cdnVersion=1189"
                    heading="Apps and integrations"
                    text="Integrate the tools you already use to support your team’s distinct workflows."
                    linkText="Explore integrations"
                    type="0"
                  />
                </Grid>
                {/* Card 3 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:efcc0a3d-56af-4f1b-a671-57043d310a68/1_Best%20practices.svg?cdnVersion=1189"
                    heading="Best practices"
                    text="Get the most out of Accpro with these time-tested tips for project collaboration."
                    linkText="Learn best practices"
                    type="0"
                  />
                </Grid>
              </Grid>
            </section>
          </section>

          {/* Section 3 */}
          <section className="res-sec3">
            <section className="res-sec3in">
              <Typography variant="h2" className="res-head3">
                We work better, together
              </Typography>
              <Typography className="res-head2">
                96% of customers say our integrations are better. Integrate with
                your favorite tools like Microsoft Teams and Slack, and discover
                many more.
              </Typography>
              <Grid container spacing={3} className="res2-card2-cont">
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:c5c9c69d-d6ce-43d2-98f6-5e0fe0f2498e/logos-Jira-blue.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:b30cea8b-b00c-4d2d-af70-1b75d7a554ce/trello-logo.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:fabfffaa-4eff-4e7f-a763-a29f5de3c021/Slack_RGB.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:cf215f9c-fbe5-4449-84fa-cafb47ca76b8/Microsoft_Teams_Logo_2x.png?cdnVersion=1189" />

                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:3a696f17-0629-4a92-895b-cdaf3fdd3d08/figma-logo-full.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:716ebd30-7743-4e96-a32b-621be8de86af/dropbox.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:b90ddb4c-5cb6-48bd-9661-0feaa78a703d/google_drive_wordmark.svg?cdnVersion=1189" />
                <Card2 image="https://wac-cdn.atlassian.com/dam/jcr:61dc7b6e-29f5-4a55-9316-e5662d82462d/box-logo.svg?cdnVersion=1189" />
              </Grid>
              <Link
                variant="contained"
                color="primary"
                className="section-link"
              >
                Go to Marketplace
              </Link>
            </section>
          </section>

          {/* Section 4 */}
          <section style={{ paddingBottom: "64px" }}>
            <Typography variant="h2" className="res-head3">
              See Accpro in action
            </Typography>
            <Typography className="res-head2">
              Seeing is believing! Explore what you can do with Accpro.
            </Typography>
            <Grid
              container
              spacing={3}
              sx={{
                gap: "32px",
                paddingTop: "8px",
                marginBottom: "16px",
                justifyContent: "center",
              }}
            >
              <Link
                variant="contained"
                color="primary"
                className="section-link1"
              >
                More demos, please
              </Link>

              <Link
                variant="contained"
                color="primary"
                className="section-link1"
              >
                Check out our YouTube channel
              </Link>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ maxWidth: "1200px", margin: "auto" }}
            >
              {/* Card 1 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:3e593001-3534-44fe-9c7c-bf883539c4d1/3_How%20to%20get%20started@4x.png?cdnVersion=1189"
                  heading="How to get started"
                  text="Everything you need to get started quickly."
                  linkText="Watch video"
                  type="0"
                />
              </Grid>
              {/* Card 2 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:508b05ea-864f-435a-aec8-518ae69520aa/3_Lets%20get%20to%20work@4x.png?cdnVersion=1189"
                  heading="Let’s get to work"
                  text="Start creating, editing, and sharing with project Spaces and Pages."
                  linkText="Watch video"
                  type="0"
                />
              </Grid>
              {/* Card 3 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:c5e7dd94-f5b6-45b2-944f-ba8ce18aae5b/3_Team%20collaboration@4x.png?cdnVersion=1189"
                  heading="Team collaboration"
                  text="Learn how to add teammates and support better collaboration."
                  linkText="Watch video"
                  type="0"
                />
              </Grid>
            </Grid>
          </section>

          {/* Section 5 */}
          <section style={{ paddingBottom: "64px" }}>
            <Typography variant="h2" className="res-head3">
              Countless use cases, one Accpro
            </Typography>
            <Typography className="res-head2">
              With thousands of integrations and hundreds of templates, Accpro
              is the collaborative knowledge hub for any team.
            </Typography>

            <Grid
              container
              spacing={3}
              sx={{ maxWidth: "1200px", margin: "auto" }}
            >
              {/* Card 1 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:6ea0050c-b092-4f1a-bfe6-e855acd5b64c/4_Knowledge%20hub.svg?cdnVersion=1189"
                  heading="A shared knowledge hub for every team"
                  text="Make it easy for teams to find and contribute critical company knowledge."
                  linkText="View use case"
                  type="0"
                />
              </Grid>
              {/* Card 2 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:8e996431-4263-4755-af2c-cf3ff92dc4fc/4_Streamline%20work.svg?cdnVersion=1189"
                  heading="Streamline work with Accpro and Jira"
                  text="Connect Accpro to Jira Software to organize, manage, and ship projects faster."
                  linkText="View use case"
                  type="0"
                />
              </Grid>
              {/* Card 3 */}
              <Grid item xs={12} sm={6} md={4}>
                <ResourceCard
                  image="https://wac-cdn.atlassian.com/dam/jcr:cd8f61c4-34ba-4454-9005-2ad19c78bfd4/4_Teamwork.svg?cdnVersion=1189"
                  heading="Take the work out of teamwork"
                  text="Collaborate openly and efficiently without meetings or emails."
                  linkText="View use case"
                  type="0"
                />
              </Grid>
            </Grid>
          </section>

          {/* Section 6 */}
          <section className="res-sec3">
            <section className="res-sec3in">
              <Typography variant="h2" className="res-head3">
                Must-watch webinars
              </Typography>

              <Grid
                container
                spacing={3}
                sx={{ maxWidth: "1200px", margin: "auto" }}
              >
                {/* Card 1 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:4cabe8ac-758b-4a9e-93af-7d1bdda777a7/7_Create@4x.png?cdnVersion=1189"
                    heading="Showcase your work in Accpro"
                    text="Learn practical tips from product marketers on building beautiful pages that showcase your work."
                    linkText="Register"
                    type="0"
                  />
                </Grid>
                {/* Card 2 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:f9842642-983e-4e6f-9b97-4543ab5fb962/7_Teamwork@4x.png?cdnVersion=1189"
                    heading="Collaboration in Accpro"
                    text="Product experts showcase how Accpro is evolving to help teams do their best work."
                    linkText="Register"
                    type="0"
                  />
                </Grid>
                {/* Card 3 */}
                <Grid item xs={12} sm={6} md={4}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:2b84a100-de00-468f-a7b3-0fc3f9440855/7_Pro%20tips@4x.png?cdnVersion=1189"
                    heading="Get the most out of Accpro"
                    text="Learn how to use Accpro like a pro with our product marketers."
                    linkText="Register"
                    type="0"
                  />
                </Grid>
              </Grid>
            </section>
          </section>

          {/* Section 7 */}
          <section className="res-sec7">
            <section className="res-sec3in">
              <Typography variant="h2" className="res-head3">
                Guide your team to success
              </Typography>

              <Grid
                container
                spacing={3}
                sx={{ maxWidth: "1400px", margin: "auto" }}
              >
                {/* Card 1 */}
                <Grid item xs={12} sm={6} md={3}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:5f6669a9-da7c-4ad5-804f-d73346f93a28/8_Community%20Illo.svg?cdnVersion=1189"
                    heading="Atlassian Community"
                    text="Connect with other users to maximize your Accpro experience and share feedback for future updates."
                    linkText="Check out our Community"
                    type="1"
                  />
                </Grid>
                {/* Card 2 */}
                <Grid item xs={12} sm={6} md={3}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:2322e120-0c55-4e5e-a043-3246c4602936/8_University%20Illo.svg?cdnVersion=1189"
                    heading="Atlassian University"
                    text="Admins and regular users boost their Accpro IQ with on-demand learning for all proficiency levels."
                    linkText="Get training now"
                    type="1"
                  />
                </Grid>
                {/* Card 3 */}
                <Grid item xs={12} sm={6} md={3}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:39a94aeb-7ac8-4d12-b308-c18cd1b6c013/8_Support%20Illo.svg?cdnVersion=1189"
                    heading="Support"
                    text="Our team is ready from the get-go to set admins and users up for success."
                    linkText="See support options"
                    type="1"
                  />
                </Grid>
                {/* Card 4 */}
                <Grid item xs={12} sm={6} md={3}>
                  <ResourceCard
                    image="https://wac-cdn.atlassian.com/dam/jcr:034071ac-301f-4955-89a2-b7cbf9ed118e/8_Blog%20Illo.svg?cdnVersion=1189"
                    heading="Blog"
                    text="Stay abreast of product news, and read helpful how-to’s for relevant use cases and more."
                    linkText="Check out the blog"
                    type="1"
                  />
                </Grid>
              </Grid>
            </section>
          </section>

          {/* Section 8 */}
          <section className="res-sec8">
            <section className="">
              <ResourceCard2
                image="https://wac-cdn.atlassian.com/dam/jcr:a3a8cc2a-9430-424b-aded-5bd2b0b20981/9_Security.svg?cdnVersion=1189"
                heading="93% of users say our security features are superior*"
                text="We are committed to delivering the highest level of security, reliability, privacy, and compliance in our products."
                linkText="Learn how we keep your data safe"
                type="0"
              />

              <ResourceCard2
                image="https://wac-cdn.atlassian.com/dam/jcr:6169d72f-a166-433f-908b-4fa3a37ce808/10_RoadMap.svg?cdnVersion=1189"
                heading="What’s new with Accpro"
                text="Check out the latest and greatest features in Accpro Cloud, including upcoming and newly released updates."
                linkText="Learn more"
                type="1"
              />

              <ResourceCard2
                image="https://wac-cdn.atlassian.com/dam/jcr:2eeb4c3f-e1ea-4d64-a0a1-b791e611c844/11_Cloud%20Migration@4x.png?cdnVersion=1189"
                heading="Migrating to Accpro Cloud"
                text="Learn how to navigate the migration journey from Accpro Server to Cloud in the way that best suits your team."
                linkText="Explore migrating to Cloud"
                type="0"
              />
            </section>
          </section>

          {/* Section 9 */}
          <section className="res-sec9">
            <section className="res-sec3in" style={{ paddingBottom: "0" }}>
              <Typography
                variant="h2"
                className="res-head3"
                style={{ color: "#fff" }}
              >
                Start free with Accpro
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "auto", display: "flex" }}
              >
                Get it free
              </Button>
            </section>
          </section>
        </Container>
      )}
    </>
  );
}

export default ResourcesPage;
