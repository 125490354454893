import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl, Alert } from "@mui/material";
import { SendRequest } from "../api";

function ScriptsPopup({
  categories,
  setScripts,
  scripts,
  setShowScripts,
  setIsLoading,
  setAlert,
}) {
  const [openForm, setOpenForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [currentScript, setCurrentScript] = useState({});
  const [testType, setTestType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const [error, setError] = useState(null);
  const [category, setCategory] = useState("");
  const [vbsScript, setVbsScript] = useState("");

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setTestType("");
    setCodeDescription("");
    setCategory("");
    setVbsScript("");
    setCurrentScript({});
  };

  useEffect(() => {
    if (openEditForm) {
      if (title && description && testType && codeDescription) {
        setError(null);
      }
    } else {
      if (title && description && testType && codeDescription) {
        setError(null);
      }
    }
  }, [title, description, testType, codeDescription, openEditForm]);

  const handleCreateEdit = async () => {
    if (!title || !description || !testType || !codeDescription) {
      setError("All fields are required");
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      // Create a FormData object to handle file uploads
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("testType", testType);
      formData.append("vbs_description", codeDescription);
      formData.append("vbs_code", vbsScript);
      formData.append("category_name", category);
      formData.append("category", category);

      let response;

      if (openEditForm) {
        response = await SendRequest(
          `/api/scripts/${currentScript.id}/`,
          "PATCH",
          formData
        );
        setScripts((prevScripts) =>
          prevScripts.map((script) =>
            script.id === currentScript.id ? response : script
          )
        );
      } else {
        response = await SendRequest("/api/scripts/", "POST", formData);
        setScripts((prevScripts) => [...prevScripts, response]);
      }

      setOpenForm(false);
    } catch (error) {
      setError("An error occurred while creating the script");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setVbsScript(content);
      };
      reader.readAsText(file);
    }
  };

  const openEdit = (script) => {
    setCurrentScript(script);
    setTitle(script.title);
    setDescription(script.description);
    // console.log("TEST_TYPE ", script.category);
    setTestType(script.test_type.toString());
    setCodeDescription(script.vbs_description);
    setVbsScript(script.vbs_code);
    setCategory(script.category);
    setOpenEditForm(true);
  };

  const handleDelete = async (scriptId) => {
    setIsLoading(true);
    try {
      await SendRequest(`/api/scripts/${scriptId}/`, "DELETE");
      setScripts((prevScripts) => prevScripts.filter((s) => s.id !== scriptId));
    } catch (error) {
      setAlert({ severity: "error", message: "Failed to delete script" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={() => setShowScripts(false)} fullWidth>
      <Box sx={{ p: 3 }}>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            Scripts
          </Typography>

          <Button onClick={() => setOpenForm(true)}>+ Create Script</Button>
        </div>

        <List>
          {scripts.map((script) =>
            script.type == undefined ? (
              <ListItem key={script.id}>
                <ListItemText primary={script.title} />
                {script.sub_domain_name !== "www" && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => openEdit(script)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(script.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ) : null
          )}
        </List>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => setShowScripts(false)}>
            Close
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openForm || openEditForm}
        onClose={() => {
          setOpenForm(false);
          setOpenEditForm(false);
          resetForm();
        }}
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {openEditForm ? "Edit Script" : "Create Script"}
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}

          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <FormControl fullWidth>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={(event) => setCategory(event.target.value)}
            >
              {categories.map((category) => (
                <MenuItem key={category[0]} value={category[0]}>
                  {category[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="test-type-label">Test Type</InputLabel>
            <Select
              labelId="test-type-label"
              id="test-type-select"
              value={testType}
              label="Test Type"
              onChange={(event) => setTestType(event.target.value)}
              disabled={openEditForm}
            >
              <MenuItem value="0">Local</MenuItem>
              <MenuItem value="1">Server</MenuItem>
            </Select>
          </FormControl>

          <TextField
            multiline
            fullWidth
            label="VBS script"
            value={vbsScript}
            onChange={(e) => setVbsScript(e.target.value)}
            style={{ marginBottom: "10px" }}
            disabled={openEditForm}
          />

          <InputLabel htmlFor="vbsfile">Input VBS file</InputLabel>

          <input
            id="vbsfile"
            type="file"
            accept=".vbs"
            onChange={handleFileChange}
            style={{ marginBottom: "10px" }}
          />

          <TextField
            multiline
            fullWidth
            label="Code Description"
            value={codeDescription}
            onChange={(e) => setCodeDescription(e.target.value)}
            disabled={openEditForm}
          />

          <Box mt={3} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={handleCreateEdit}
              disabled={
                !title ||
                !description ||
                !category ||
                !testType ||
                !codeDescription
              }
            >
              {openEditForm ? "Save Changes" : "Create"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenForm(false);
                setOpenEditForm(false);
                resetForm();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Dialog>
  );
}

export default ScriptsPopup;
