import React, { useState, useEffect } from "react";
import { SendRequest } from "../api"; // path to api.js
import "../css/login.css";
import Cookies from "js-cookie"; // import the js-cookie library
import LoadingComponent from "../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  FormHelperText,
} from "@mui/material";

var DOMAIN = ".localhost";

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // add this line

  // const navigate = useNavigate()

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await SendRequest("/api/check_logged_in/", "GET");
        if (response.isLoggedIn) {
          // setIsLoggedIn(true);
          // setUser(response.user)
          // console.log('hereee', response.user)
          window.location.href = response.url + "/dashboard/";
        }
      } catch (error) {
        // setIsLoggedIn(false);
        console.log(error);
      }
    };

    checkLoggedIn();
  }, []); // Only run this effect once, similar to componentDidMount

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await SendRequest("/api/login/", "POST", {
        email,
        password,
      });
      // console.log(response); // Here you will get your response
      if (response["error"]) {
        // console.log('error happ')
        setError(response["error"]);
      } else {
        setError(null);
        // Set the token as a cookie
        Cookies.set("token", response["token"], { expires: 7 }); // 7 days
        // console.log(response['user']['url'])
        window.location.href = response["url"]+ "/dashboard/";
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError("Something went wrong!");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={6} md={4} style={{justifyContent: "center", display: "flex" }}>
          <Card elevation={3} sx={{marginTop:"50px",maxWidth:"400px"}}>
            <CardContent>
              <Typography variant="h4" align="center" gutterBottom>
                Log In
              </Typography>

              <form onSubmit={handleSubmit}>
                {error && <FormHelperText sx={{marginBottom:"20px",fontSize:"16px"}} error>{error}</FormHelperText>}

                <Box mb={3}>
                  <TextField
                    label="Email"
                    type="email"
                    required
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>

                {/* Uncomment if needed later
                <Box mb={3} textAlign="right">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </Box>
                */}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<img src="/images/right-arrow.svg" alt="" />}
                >
                  Login
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginPage;
