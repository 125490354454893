import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ReferenceDropdownComponent from "../components/ReferenceDropdownComponent";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import {
  Button,
  TextField,
  FormControlLabel,
  Container,
  Grid,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomWidgets from "./CustomWidgets";
import HeaderWidgets from "./HeaderWidgets";
import ScriptsPopup from "./ScriptsPopup";

function EditorTop({
  scriptCategories,
  scripts,
  setScripts,
  setAlert,
  users,
  customWidgetEntries,
  setIsLoading,
  customWidgets,
  setCustomWidgets,
  title,
  selectedPages,
  setSelectedPages,
  setSelectedPagesLoaded,
  pages,
  setPages,
  handleMakeTemplate,
  isTemplate,
  editor,
  isSaved,
  handlePublish,
}) {
  const { id, folderid, pageid } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [elementVisibility, setElementVisibility] = useState({
    "reference-item-but": true,
    "script-but": true,
    "para-but": true,
  });

  const [showCustomWidgets, setShowCustomWidgets] = useState(false);
  const [showScripts, setShowScripts] = useState(false);


  useEffect(() => {
    const handleIframeClick = (event) => {
      if (event.target && event.target.matches("button.del-para")) {
        const id = event.target.getAttribute("data-id");
        window.deleteParameter(event, id);
      }
      if (event.target && event.target.matches("button.del-chart")) {
        const id = event.target.getAttribute("data-id");
        window.deleteChart(event, id);
      }

      if (event.target && event.target.matches("button.del-script")) {
        // console.log("DELETE SCRIPT");
        const id = event.target.getAttribute("data-id");
        window.deleteScript(event, id);
      }
      if (event.target && event.target.matches("button.del-report")) {
        // console.log("DELETE report");
        const id = event.target.getAttribute("data-id");
        window.deleteReport(event, id);
      }

      if (event.target && event.target.matches("button.del-ref")) {
        // console.log("CLICKEDDDDDDD", event.target);
        // Get the grandparent
        const grandparent = event.target.parentElement.parentElement;

        // Remove the grandparent from the DOM
        if (grandparent) {
          grandparent.remove();
        }

        // Update the editor's content (assuming the editor's instance is named 'editor')
        const newContent = editor.getDoc().body.innerHTML;
        editor.setContent(newContent);
      }

      if (event.target && event.target.matches("button.del-custom-widget")) {
        // console.log("DELETE CUSTOM WIDGET");
        const id = event.target.getAttribute("data-id");
        window.deleteCustomWidget(event, id);
      }
    };

    if (editor) {
      const iframeBody = editor.getDoc().body;
      if (iframeBody) {
        iframeBody.addEventListener("click", handleIframeClick);
        return () => {
          iframeBody.removeEventListener("click", handleIframeClick);
        };
      }
    }
  }, [editor]);

  const toggleVisibility = (elementTypes) => {
    const editorContent = editor.getContent();
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent, "text/html");

    elementTypes.forEach((elementType) => {
      const elementsToToggle = doc.querySelectorAll(`.${elementType}`);
      elementsToToggle.forEach((el) => {
        if (elementVisibility[elementType]) {
          el.style.display = "none";
        } else {
          el.style.display = "flex";
        }
      });
      setElementVisibility((prevState) => ({
        ...prevState,
        [elementType]: !prevState[elementType],
      }));
    });

    editor.setContent(doc.documentElement.outerHTML);
  };

  const handleClose = () => {
    if (!isSaved) {
      setOpen(true);
    } else {
      runClose();
    }
  };

  const handleClose1 = () => {
    setOpen(false);
  };
  const runClose = () => {
    // console.log("NAVIGATING TO ____>>>>>>", `/project/${id}/`)
    navigate(`/project/${id}/${folderid}/${pageid}/?type=0`);
  };

  const handleConfirm = () => {
    runClose();
    handleClose1();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {"Unpublished Content"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You didn't publish the content, do you still want to close?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} color="primary">
            No
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div className=" ">
        <Grid container spacing={4} className="mt-1 align-items-center">
          <Grid item sx={{ paddingTop: "0 !important" }}>
            <HeaderWidgets
              users={users}
              setIsLoading={setIsLoading}
              pageid={pageid}
              customWidgetEntries={customWidgetEntries}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="mt-4 mb-4  align-items-center">
          <Grid item sx={{ paddingTop: "0 !important" }}>
            <ReferenceDropdownComponent
              selectedPages={selectedPages}
              setSelectedPages={setSelectedPages}
              setSelectedPagesLoaded={setSelectedPagesLoaded}
              pages={pages}
              setPages={setPages}
            />
          </Grid>

          <Grid item sx={{ paddingTop: "0 !important" }}>
            <Button
              onClick={() => setShowCustomWidgets(!showCustomWidgets)}
              className="btn btn-primary"
            >
              Custom widgets
            </Button>
          </Grid>

          <Grid item sx={{ paddingTop: "0 !important" }}>
            <Button
              onClick={() => setShowScripts(!showScripts)}
              className="btn btn-primary"
            >
              Scripts
            </Button>
          </Grid>

          <Grid item sx={{ paddingTop: "0 !important" }}>
            <Button
              onClick={() => handleMakeTemplate()}
              className="btn btn-primary"
              disabled={!title || !pageid}
              style={{ cursor: title && pageid ? "pointer" : "not-allowed" }}
            >
              {isTemplate ? "Remove as template" : "Add as template"}
            </Button>
          </Grid>

          <Grid item sx={{ paddingTop: "0 !important" }}>
            <Button
              onClick={() => handlePublish(0)}
              className="btn btn-primary"
              disabled={!title}
              style={{ cursor: title ? "pointer" : "not-allowed" }}
            >
              Publish
            </Button>
          </Grid>
          <Grid item sx={{ paddingTop: "0 !important" }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </div>

      <FormGroup
        sx={{
          display: "flex",
          gap: "10px",
          flexDirection: "row",
          marginBottom: "10px",
        }}
      >
        <FormControlLabel
          sx={{ width: "fit-content" }}
          control={
            <Switch
              checked={elementVisibility["reference-item-but"]}
              onChange={() =>
                toggleVisibility(["reference-item-but", "para-but"])
              }
            />
          }
          label={
            elementVisibility["reference-item-but"]
              ? "Show References"
              : "Hide References"
          }
        />

        <FormControlLabel
          sx={{ width: "fit-content" }}
          control={
            <Switch
              checked={elementVisibility.scriptVisible}
              onChange={() => toggleVisibility(["script-but"])}
            />
          }
          label={
            elementVisibility["script-but"] ? "Hide Scripts" : "Show Scripts"
          }
        />
      </FormGroup>

      {showCustomWidgets && (
        <CustomWidgets
          setCustomWidgets={setCustomWidgets}
          setIsLoading={setIsLoading}
          customWidgets={customWidgets}
          setShowCustomWidgets={setShowCustomWidgets}
          setAlert={setAlert}
        />
      )}

      {showScripts && (
        <ScriptsPopup
          categories={scriptCategories}
          setScripts={setScripts}
          setIsLoading={setIsLoading}
          scripts={scripts}
          setShowScripts={setShowScripts}
          setAlert={setAlert}
        />
      )}
    </>
  );
}

export default EditorTop;
