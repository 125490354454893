import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {SendRequest} from '../api'; // assuming this is your request function
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function ProjectRightOverviewPage() {
  const { id } = useParams();

  const [content, setContent] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const editPage = () => {
    navigate(`/edit/${id}/0/${pageId}/`);
}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendRequest(`/api/get_overview/${id}/`, 'GET');
        // console.log(response)
        setContent(response.content);
        setTitle(response.name);
        setPageId(response.id);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <LoadingComponent />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={editPage}>
          <EditIcon />
        </IconButton>
      </Box>
      <Box dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  );
}

export default ProjectRightOverviewPage;
