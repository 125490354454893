// LandingPageSection3.jsx
import React from 'react';
import { Typography } from '@mui/material';

function LandingPageSection3() {
  return (
    <div>
      <Typography variant="h2">Section 3 Content Here</Typography>
    </div>
  );
}

export default LandingPageSection3;
