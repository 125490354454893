import React from "react";
import { Typography, Button } from "@mui/material";
import ReviewCard from "./ReviewCard";

// Create a separate component for the icons and images
function IconAndImage({ iconSrc, altText, text1, text2 }) {
  return (
    <div style={{ flex: "1 1 25%", display: "flex" }}>
      <img src={iconSrc} alt={altText} style={{ maxHeight: "32px" }} />
      <div>
        <Typography
          sx={{
            fontSize: "1.463rem",
            lineHeight: "26px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {text1}
        </Typography>

        <Typography sx={{ fontSize: "1rem", lineHeight: "20px" }}>
          {text2}
        </Typography>
      </div>
    </div>
  );
}

function Card({ imageUrl, altText, title, text }) {
  return (
    <div
      style={{
        width: "286px",
        padding: "32px",
        background: "#fff",
        borderRadius: "8px",
        textAlign: "center",
        transition: "box-shadow 0.3s",
        margin: "32px 10px 0",
        boxShadow: "0px 8px 24px 0 rgba(29, 140, 242, 0.16)",
        transition: "filter 0.2s ease-in-out",
        color: "#333333",
      }}
      className="land-card"
    >
      {/* Card content */}
      <img
        src={imageUrl}
        alt={altText}
        style={{
          width: "100px",
          height: "100px",
          margin: "0 auto",
          marginBottom: "16px",
          height: "100px ",
        }}
      />
      <Typography
        variant="h4"
        style={{
          fontSize: "1.125rem",
          lineHeight: "24px",
          letterSpacing: "-0.5px",
          fontWeight: "500",
          marginBottom: "24px",
          minHeight: "64px",
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          fontSize: "1rem",
          lineHeight: "24px",
        }}
      >
        {text}
      </Typography>
    </div>
  );
}

function LandingPageSection4() {
  return (
    <>
      <div
        style={{
          // maxWidth: "1400px",
          display: "flex",
          justifyContent: "center",
          margin: " auto",
          marginBottom: "140px",
          flexWrap: "wrap",
          paddingTop: "100px",
          maxWidth: "1400px",
          padding: "15px",
        }}
      >
        {/* First Column */}
        <div
          style={{
            flex: "1 1 40%",
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
            gap: "50px",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.75rem !important",
              lineHeight: "60px !important",
              fontWeight: "700 !important",
              maxWidth: "300px",
            }}
            className="land-work-os"
            variant="h2"
          >
            See how our customers drive impact
          </Typography>
          <Typography>
            <a href="#" style={{ textDecoration: "none" }}>
              Read more success stories
            </a>
          </Typography>
        </div>

        {/* Second Column */}
        <div style={{ flex: "1 1 60%", minWidth: "350px" }}>
          <div
            style={{
              position: "relative",
              minHeight: "446px",
              maxHeight: "600px",
            }}
          >
            {/* Review Container */}
            <ReviewCard />
          </div>
        </div>
      </div>

      <div>
        {/* Additional Section */}
        <div
          style={{
            flex: "1 1 100%",
            display: "flex",
            margin: "auto",
            marginTop: "50px",
            flexWrap: "wrap",
            maxWidth: "1400px",
          }}
        >
          {/* Left Column with Image */}
          <div style={{ flex: "1 1 40%" }}>
            <img
              src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/Generator_featured images/Home Page - 2022 Rebrand/customer-support/support_globe.png"
              alt="Support Image"
              className="land-world-img"
              
            />
          </div>

          {/* Right Column with Text and 4 Columns */}
          <div style={{ flex: "1 1 60%", padding: "20px", minWidth: "200px" }}>
            <Typography
              sx={{
                fontSize: "2.75rem !important",
                lineHeight: "60px !important",
                fontWeight: "700 !important",
                maxWidth: "550px",
              }}
              variant="h2"
            >
              Supporting your growth every step of the way
            </Typography>
            <Typography
              sx={{
                fontSize: "1.125rem",
                lineHeight: "32px",
                marginTop: "30px",
                marginBottom: "40px",
              }}
            >
              Our support superheroes are a click away to help you get the most
              out of accpro.uk, so you can focus on working without limits.
            </Typography>
            {/* 4 Column Row */}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                marginTop: "20px",
                marginBottom: "100px",
              }}
            >
              {/* Use the IconAndImage component for each column */}
              <IconAndImage
                iconSrc="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/phone.png"
                altText="24/7"
                text1="24/7"
                text2="support anytime, anywhere"
              />
              <IconAndImage
                iconSrc="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/heart.png"
                altText="Icon 2"
                text1="Voted #1"
                text2="Most Loved by customers on G2"
              />
              <IconAndImage
                iconSrc="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/customer-support/fast.png"
                altText="Icon 3"
                text1="2 hour"
                text2="average response time"
              />
            </div>
            {/* Get in Touch Button */}
            <Button variant="contained" color="primary">
              Get in Touch
            </Button>
          </div>
        </div>

        {/* New Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "164px",
            width: "100%",
          }}
        >
          {/* Image */}
          <img
            src="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/stars.png"
            alt="Stars"
            style={{ height: "20px" }}
          />

          {/* h2 */}
          <Typography
            variant="h2"
            style={{
              marginTop: "20px",
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "2.25rem",
              lineHeight: "48px",
              fontWeight: "700",
            }}
          >
            An award-winning platform. Loved by customers.
          </Typography>

          {/* Text */}
          <Typography
            style={{
              textAlign: "center",
              marginBottom: "60px",
              fontSize: "1.125rem",
              lineHeight: "32px",
              wordBreak: "break-word",
            }}
          >
            Based on 10,000+ customer reviews.
          </Typography>

          {/* Cards */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "40px",
              flexWrap: "wrap",
            }}
          >
            <Card
              imageUrl="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/trust.png"
              altText="Card 1"
              title="Voted best feature set, relationship and value"
              text="“This is the best no-code platform I've ever seen.”"
            />

            <Card
              imageUrl="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/capterra.png"
              altText="Card 2"
              title="Shortlisted in over 8 software categories"
              text="“The perfect organizer and team builder.”"
            />

            <Card
              imageUrl="https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/review-cards/g2.png"
              altText="Card 3"
              title="Market leader across 18 categories"
              text="“Flexible product with near endless possibilities.“"
            />
          </div>

          {/* CTA Section */}
          <div
            style={{
              backgroundColor: "rgb(24, 27, 52)",
              color: "#fff",
              padding:"15px",
              paddingTop: "64px",
              paddingBottom: "64px",
              textAlign: "center",
              width: "100%",
              marginTop: "100px",
            }}
          >
            <Typography variant="h3" style={{ marginBottom: "20px" }}>
              Deliver your best work with accpro.uk
            </Typography>
            <Typography style={{ marginBottom: "40px" }}>
              No credit card needed ✦ Unlimited time on Free plan
            </Typography>
            <Button variant="contained" color="primary">
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPageSection4;
