let BASE_INLINE_WIDGET_TOOLS = [
  // {
  //   id: 1,
  //   image: "/images/page.png",
  //   heading: "Table of contents",
  //   subheading: "Add table of contents to your page.",
  //   action_name: "toc",
  //   data_id: "",
  //   parentId: null,
  //   type: 1,
  // },
  {
    id: 2,
    image: "/images/page.png",
    heading: "Parameters",
    subheading: "Add parameters to the page.",
    action_name: "",
    data_id: "",
    parentId: null,
    type: 1,
  },
  {
    id: 3,
    image: "/images/page.png",
    heading: "Pages",
    subheading: "Add a link to the page.",
    action_name: "",
    data_id: "",
    parentId: null,
    type: 1,
  },
  {
    id: 4,
    image: "/images/page.png",
    heading: "Scripts",
    subheading: "Add a script.",
    action_name: "",
    data_id: "",
    parentId: null,
    type: 1,
  },
  {
    id: 5,
    image: "/images/page.png",
    heading: "Custom widgets",
    subheading: "Collection of custom widgets.",
    action_name: "",
    data_id: "",
    parentId: null,
    type: 1,
  },
  {
    id: 6,
    image: "/images/page.png",
    heading: "Header level",
    subheading: "Collection of header level widgets.",
    action_name: "",
    data_id: "",
    parentId: 5,
    type: 1,
  },
  {
    id: 7,
    image: "/images/page.png",
    heading: "Item level",
    subheading: "Collection of Item level widgets.",
    action_name: "",
    data_id: "",
    parentId: 5,
    type: 1,
  },
  {
    id: 8,
    image: "/images/page.png",
    heading: "Charts",
    subheading: "Add different charts to the page.",
    action_name: "",
    data_id: "",
    parentId: null,
    type: 1,
  },
  {
    id: 9,
    image: "/images/page.png",
    heading: "Gantt chart",
    subheading: "Add a gantt chart to the page",
    action_name: "gantt",
    data_id: "",
    parentId: 8,
    type: 1,
  },
  {
    id: 10,
    image: "/images/page.png",
    heading: "Kanban",
    subheading: "Add a Kanban chart to the page",
    action_name: "kanban",
    data_id: "",
    parentId: 8,
    type: 1,
  },
  {
    id: 11,
    image: "/images/page.png",
    heading: "Scheduler",
    subheading: "Add a Scheduler to the page",
    action_name: "scheduler",
    data_id: "",
    parentId: 8,
    type: 1,
  },
  {
    id: 12,
    image: "/images/page.png",
    heading: "Report",
    subheading: "Automatically generate a report",
    action_name: "report",
    data_id: "",
    parentId: null,
    type: 1,
  },
];

export default BASE_INLINE_WIDGET_TOOLS;
