import React, { useEffect, useState } from "react";
import "../css/header.css";
import DashboardHeader from "./DashboardHeader";
import Header from "./Header";
import SubHeader from "./SubHeader";
import { useLocation } from "react-router-dom"; // Import useLocation

function MainHeader({
  isSuperAdmin,
  isSubdomain,
  subdomain,
  isAdmin,
  user,
  isLoggedIn,
}) {
  const location = useLocation(); // Use the useLocation hook

  // Use location.pathname instead of window.location.pathname
  const isDashboard =
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/projects") ||
    location.pathname.startsWith("/project") ||
    location.pathname.startsWith("/subdomain") ||
    location.pathname.startsWith("/templates");
  const isEdit = location.pathname.startsWith("/edit");
  const isScript =
    location.pathname.startsWith("/script") ||
    location.pathname.startsWith("/widgetentry") ||
    location.pathname.startsWith("/report") ||
    location.pathname.startsWith("/chart");
  // console.log("IS EDIT : ", isEdit, location.pathname);
  if (isDashboard) {
    return (
      <DashboardHeader
        isSuperAdmin={isSuperAdmin}
        isLoggedIn={isLoggedIn}
        user={user}
        isAdmin={isAdmin}
      />
    );
  }

  if (isEdit || isScript) {
    return;
  }

  if (isSubdomain) {
    if (subdomain == "www") {
      return <Header isLoggedIn={isLoggedIn} />;
    } else {
      return <SubHeader isLoggedIn={isLoggedIn} />;
    }
  }

  return <Header isLoggedIn={isLoggedIn} />;
}

export default MainHeader;
