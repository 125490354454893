import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Alert,
  TextField,
  Menu,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SendRequest } from "../api";

function CreateReport({
  setShowReport,
  replaceText,
  customWidgets,
  pageid,
  setIsLoading,
  handlePublish,
  users,
}) {
  // console.log("customWidgets", customWidgets)
  const [columns, setColumns] = useState([
    {
      name: "Column1",
      input: "Project",
      parameter: "",
      filter: [],
      output: "",
    },
  ]);

  const [alertError, setAlertError] = useState(null);
  const [filterFocusIndex, setFilterFocusIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState(1);

  useEffect(() => {
    if (reportType === "3") {
      // Set up for Bar chart
      setColumns([
        { name: "x", input: "Project", parameter: "", filter: [], output: "" },
        { name: "y", input: "Project", parameter: "", filter: [], output: "" },
      ]);
    } else {
      // Set up for other types
      setColumns([
        {
          name: "Column1",
          input: "Project",
          parameter: "",
          filter: [],
          output: "",
        },
      ]);
    }
  }, [reportType]);

  const addColumn = () => {
    if (reportType === "3") return; // Prevent adding more columns for bar chart

    const newColumnName = `Column${columns.length + 1}`;
    setColumns((prev) => [
      ...prev,
      { name: newColumnName, input: "", parameter: "", filter: [], output: "" },
    ]);
  };

  const deleteColumn = (index) => {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    setColumns(newColumns);
    setFilterFocusIndex(null); // Reset the filterFocusIndex here
  };

  const updateColumn = (index, key, value) => {
    const newColumns = [...columns];
    if (key === "parameter") {
      newColumns[index].filter = [];
    }
    newColumns[index][key] = value;
    setColumns(newColumns);
  };

  const isDisabled = () => {
    return (
      !reportName.trim() || // Check if reportName is empty
      !reportType || // Check if reportType is empty
      columns.some(
        (col) =>
          !col.input || !col.parameter || (reportType == "1" && !col.output)
      ) ||
      alertError !== null
    );
  };

  const handleFilterClick = (index, event) => {
    setFilterFocusIndex(index);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterDelete = (index) => {
    const newColumns = [...columns];
    if (newColumns[index].filter.length) {
      newColumns[index].filter.pop();
      setColumns(newColumns);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setFilterFocusIndex(null);
  };

  const handleFilterSelect = (value) => {
    const newColumns = [...columns];
    newColumns[filterFocusIndex].filter.push(value);
    setColumns(newColumns);
    setAnchorEl(null); // Close the menu after selection
    setFilterFocusIndex(null); // Reset
  };

  const getNextFilterType = (filters, type) => {
    if (
      !filters.length ||
      filters[filters.length - 1] === "AND" ||
      filters[filters.length - 1] === "OR"
    ) {
      return "operator";
    } else if (
      filters[filters.length - 1] === "=" ||
      filters[filters.length - 1] === "!=" ||
      filters[filters.length - 1] === "<=" ||
      filters[filters.length - 1] === ">=" ||
      filters[filters.length - 1] === "<" ||
      filters[filters.length - 1] === ">"
    ) {
      return type === 1 ? "number" : "operand";
    } else {
      return "logical";
    }
  };

  const validateFilter = (index) => {
    const colFilter = columns[index].filter;

    if (colFilter.length > 0) {
      const lastFilter = colFilter[colFilter.length - 1];
      const selectedWidget = customWidgets.find(
        (widget) => widget.name === columns[index].parameter
      );

      // If there's no selected widget, just set an error
      if (!selectedWidget) {
        setAlertError("Selected widget is not valid.");
        return;
      }

      // Check for type 1 widgets, which accept numbers as operands
      if (selectedWidget.type === 1) {
        if (isNaN(Number(lastFilter))) {
          // Check if the last filter is not a number
          setAlertError(
            "Last filter should be a number for the selected widget type."
          );
          return;
        }
      } else if (selectedWidget.type === 2) {
        // console.log("SELECTED WIDGET TYPE = ", selectedWidget.type);

        const emails = users ? users.map((user) => user.email) : [];

        const validOperands = emails || [];

        if (!validOperands.includes(lastFilter)) {
          setAlertError("Last filter should be one of the valid users.");
          return;
        }
      } else {
        // Extract the choices for the selected widget. If none are found, use an empty array.

        // console.log("SELECTED WIDGET TYPE = ", selectedWidget.type);
        const validOperands = selectedWidget.choices || [];

        if (!validOperands.includes(lastFilter)) {
          setAlertError("Last filter should be one of the valid choices.");
          return;
        }
      }
    }

    setAlertError(null); // If everything's fine, reset any existing error
  };

  const groupColumns = (columns) => {
    const grouped = [];

    columns.forEach((col) => {
      if (col.input === "Project") {
        // If input is 'Project', create a new list and add the current column to it.
        grouped.push([col]);
      } else {
        // If input is not 'Project', add the current column to the last list of the main list.
        if (grouped.length === 0) {
          // Edge case: If there's no group initialized yet, initialize one.
          grouped.push([col]);
        } else {
          grouped[grouped.length - 1].push(col);
        }
      }
    });

    // console.log("GROUPED-->>> ", grouped);

    return grouped;
  };

  const handleSubmit = async () => {
    // Using the state of your component to create the JSON
    const groupedColumns = groupColumns(columns);

    const payload = {
      page: pageid,
      name: reportName,
      type: reportType,
      data: groupedColumns.map((group) =>
        group.map((col) => ({
          columnName: col.name,
          input: col.input,
          parameter: col.parameter,
          filter: col.filter.join(" "),
          output: col.output,
        }))
      ),
    };

    // console.log(payload);
    setIsLoading(true);

    const response = await SendRequest("/api/report/", "POST", payload);
    if ("error" in response) {
      setAlertError(response.error);
      return;
    } else {
      setAlertError(null);
      const link = `<div class="report-but" contenteditable="false" ><div style="background: #ccc;padding: 2px 10px 2px 10px;border-radius: 5px;width:fit-content;">Report - ${response.name}<button data-id="${response.id}" class="del-report">x</button></div><div class="report-content" data-id="${response.id}" ><iframe class="report-iframe" src="/report/${response.id}/" width="1100" height="500"></iframe></div></div>`;
      replaceText(link);
      handlePublish(null);
      setShowReport(false);
    }
    setIsLoading(false);
  };

  const getOperandsForSelectedParameter = () => {
    const selectedParameter = columns[filterFocusIndex].parameter;

    // Check if selectedParameter is defined and not empty
    if (!selectedParameter) {
      return [];
    }

    const widget = customWidgets.find((w) => w.name === selectedParameter);
    // console.log("widget ->>> ", widget, selectedParameter);
    if (widget.type == 2) {
      // console.log(users);
      const emails = users ? users.map((user) => user.email) : [];
      return emails || [];
    } else {
      return widget?.choices || []; // Use optional chaining just in case widget is undefined
    }
  };

  const selectedParameterType = customWidgets.find(
    (widget) => widget.name === columns[filterFocusIndex]?.parameter
  )?.type;

  return (
    <Dialog open={true} fullWidth>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          CREATE REPORT
        </Typography>

        {alertError && <Alert severity="error">{alertError}</Alert>}
        {!reportName.trim() && (
          <Alert severity="error">Please enter a report name.</Alert>
        )}
        {!reportType && (
          <Alert severity="error">Please select a report type.</Alert>
        )}

        <TextField
          label="Report Name"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <FormControl fullWidth variant="outlined">
          <InputLabel>Report Type</InputLabel>
          <Select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <MenuItem value="1">Table</MenuItem>
            <MenuItem value="2">Pie chart</MenuItem>
            <MenuItem value="3">Bar chart</MenuItem>
          </Select>
        </FormControl>

        {columns.map(
          (col, index) =>
            (reportType !== "2" || index === 0) && (
              <Box
                mt={2}
                key={index}
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {reportType === "3" && (
                  // Special rendering for bar chart
                  <Typography variant="subtitle1">
                    {index === 0 ? "X-axis" : "Y-axis"}
                  </Typography>
                )}

                {reportType == "1" && (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id={`input-${index}`}>Input</InputLabel>
                    <Select
                      labelId={`input-${index}`}
                      value={col.input}
                      onChange={(e) =>
                        updateColumn(index, "input", e.target.value)
                      }
                    >
                      <MenuItem value="Project">Project</MenuItem>
                      {index > 0 && (
                        <MenuItem value={columns[index - 1].name}>
                          {columns[index - 1].name}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
                <FormControl fullWidth variant="outlined">
                  <InputLabel id={`parameter-${index}`}>Parameter</InputLabel>
                  <Select
                    labelId={`parameter-${index}`}
                    value={col.parameter}
                    onChange={(e) =>
                      updateColumn(index, "parameter", e.target.value)
                    }
                  >
                    {customWidgets
                      .filter(
                        (widget) =>
                          reportType !== "3" ||
                          (widget.type === 0 || widget.type === 2 ) ||
                          index !== 0
                      )
                      .map((widget, i) => (
                        <MenuItem value={widget.name} key={i}>
                          {widget.level === 0
                            ? `Header - ${widget.name}`
                            : `Item - ${widget.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  {/* <InputLabel id={`filter-${index}`}>Filter</InputLabel> */}
                  <TextField
                    fullWidth
                    label="Filter"
                    variant="outlined"
                    value={col.filter.join(" ")}
                    onClick={(event) => handleFilterClick(index, event)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && col.filter.length) {
                        handleFilterDelete(index); // Pass the current column's index
                        e.preventDefault(); // Prevent default backspace behavior
                      }
                    }}
                    onBlur={() => validateFilter(index)}
                    InputLabelProps={{
                      shrink: Boolean(col.filter.length),
                    }}
                  />
                </FormControl>
                {reportType == "1" && (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id={`output-${index}`}>Output</InputLabel>
                    <Select
                      labelId={`output-${index}`}
                      value={col.output}
                      onChange={(e) =>
                        updateColumn(index, "output", e.target.value)
                      }
                    >
                      {customWidgets.some(
                        (widget) =>
                          widget.name === col.parameter
                      ) && <MenuItem value="Result">Result</MenuItem>}
                      {customWidgets.some(
                        (widget) =>
                          widget.name === col.parameter && widget.type === 1
                      ) && <MenuItem value="Total">Total</MenuItem>}
                      {customWidgets.some(
                        (widget) =>
                          widget.name === col.parameter &&
                          (widget.type === 0 || widget.type === 2)
                      ) && <MenuItem value="Count">Count</MenuItem>}
                      {customWidgets.some(
                        (widget) =>
                          widget.name === col.parameter &&
                          (widget.type === 0 || widget.type === 2)
                      ) && <MenuItem value="Percentage">Percentage</MenuItem>}
                    </Select>
                  </FormControl>
                )}
                {index !== 0 && reportType != "3" && (
                  <IconButton onClick={() => deleteColumn(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            )
        )}

        {reportType !== "2" && reportType !== "3" && (
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            onClick={addColumn}
            disabled={isDisabled()}
          >
            Create Column
          </Button>
        )}

        <Box mt={3} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={() => {
              replaceText("");
              setShowReport(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={isDisabled()}
          >
            Create
          </Button>
        </Box>

        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {filterFocusIndex !== null && (
            <>
              {getNextFilterType(
                columns[filterFocusIndex].filter,
                selectedParameterType
              ) === "operator" && (
                <>
                  {/* Common Operators */}
                  <MenuItem onClick={() => handleFilterSelect("=")}>=</MenuItem>
                  <MenuItem onClick={() => handleFilterSelect("!=")}>
                    =!
                  </MenuItem>

                  {/* Specific Operators for Type 1 */}
                  {selectedParameterType === 1 && (
                    <>
                      <MenuItem onClick={() => handleFilterSelect("<=")}>
                        &lt;=
                      </MenuItem>
                      <MenuItem onClick={() => handleFilterSelect(">=")}>
                        &gt;=
                      </MenuItem>
                      <MenuItem onClick={() => handleFilterSelect("<")}>
                        &lt;
                      </MenuItem>
                      <MenuItem onClick={() => handleFilterSelect(">")}>
                        &gt;
                      </MenuItem>
                    </>
                  )}
                </>
              )}

              {getNextFilterType(
                columns[filterFocusIndex].filter,
                selectedParameterType
              ) === "operand" &&
                getOperandsForSelectedParameter().map((operand) => (
                  <MenuItem
                    key={operand}
                    onClick={() => handleFilterSelect(operand)}
                  >
                    {operand}
                  </MenuItem>
                ))}

              {getNextFilterType(
                columns[filterFocusIndex].filter,
                selectedParameterType
              ) === "number" && (
                <TextField
                  autoFocus
                  type="number"
                  label="Number"
                  onBlur={(e) => {
                    if (e.target.value) {
                      handleFilterSelect(e.target.value);
                    }
                  }}
                  style={{ width: "130px" }}
                />
              )}

              {getNextFilterType(
                columns[filterFocusIndex].filter,
                selectedParameterType
              ) === "logical" && (
                <>
                  <MenuItem onClick={() => handleFilterSelect("AND")}>
                    AND
                  </MenuItem>
                  <MenuItem onClick={() => handleFilterSelect("OR")}>
                    OR
                  </MenuItem>
                </>
              )}
            </>
          )}
        </Menu>
      </Box>
    </Dialog>
  );
}

export default CreateReport;
