// import React from "react";
// import { Modal, Button } from "react-bootstrap";
// import "../css/popup.css";

// const PopupComponent = ({
//   heading,
//   fields,
//   placeholders,
//   values,
//   labels,
//   buttonLabel,
//   buttonAction,
//   showModal,
//   handleCloseModal,
//   onValueChange,
//   errors,
// }) => {
//   // console.log(heading, fields, placeholders, values, labels);
//   return (
//     <Modal
//       show={showModal}
//       onHide={errors && errors.length > 0 ? null : handleCloseModal}
//       backdrop="static"
//       keyboard={!(errors && errors.length > 0)}
//     >
//       <Modal.Header closeButton={!(errors && errors.length > 0)}>
//         <Modal.Title>{heading}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {fields.map((field, index) => (
//           <div className="inp-cont" key={index}>
//             <label>{labels[index]}</label>
//             <input
//               type={field}
//               placeholder={placeholders[index]}
//               value={values[index]}
//               name={labels[index]} // This name is used to identify the input
//               onChange={(e) => {
//                 onValueChange(e.target.name, e.target.value);
//               }}
//             />
//             <div className="error-message1">{errors[index]}</div>
//           </div>
//         ))}
//       </Modal.Body>
//       <Modal.Footer className="justify-content-center">
//         <Button
//           variant="secondary"
//           onClick={handleCloseModal}
//           disabled={errors && errors.length > 0}
//         >
//           Close
//         </Button>

//         <Button
//           variant="primary"
//           onClick={buttonAction}
//           disabled={errors && errors.length > 0}
//         >
//           {buttonLabel}
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default PopupComponent;









import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import "../css/popup.css";

const PopupComponent = ({
  heading,
  fields,
  placeholders,
  values,
  labels,
  buttonLabel,
  buttonAction,
  showModal,
  handleCloseModal,
  onValueChange,
  errors,
}) => {
  return (
    <Dialog
      open={showModal}
      onClose={errors && errors.length > 0 ? null : handleCloseModal}
      keepMounted
    >
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        {fields.map((field, index) => (
          <div className="inp-cont" key={index}>
            <TextField
              fullWidth
              type={field}
              label={labels[index]}
              placeholder={placeholders[index]}
              value={values[index]}
              name={labels[index]}
              variant="outlined"
              margin="normal"
              onChange={(e) => {
                onValueChange(e.target.name, e.target.value);
              }}
              error={!!errors[index]}
              helperText={errors[index]}
              InputLabelProps={values[index] ? { shrink: true } : {}}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions className="justify-content-center">
        <Button
          color="secondary"
          onClick={handleCloseModal}
          disabled={errors && errors.length > 0}
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={buttonAction}
          disabled={errors && errors.length > 0}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupComponent;
