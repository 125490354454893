import React, { useState, useEffect } from "react";
import { SendRequest } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Link 
} from "@mui/material";

function SubDomainsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [subDomains, setSubDomains] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await SendRequest(`/api/subdomains/`, "GET");
        if (response.results) {
          setSubDomains(response.results);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{padding:"20px"}}>
          <Typography variant="h4" gutterBottom sx={{marginTop:"40px",marginBottom:"30px"}}>
            Sub Domains
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Tier</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subDomains.map((subDomain) => (
                  <TableRow key={subDomain.id}>
                    <TableCell>{subDomain.name}</TableCell>
                    <TableCell>{subDomain.tier}</TableCell>
                    <TableCell>
                      <Link 
                        component="button" 
                        variant="body2" 
                        onClick={() => navigate(`/subdomains/${subDomain.id}/`)}
                      >
                        Change Folders
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export default SubDomainsPage;
