import React, { useState, useEffect, useRef } from "react";
import { Select, MenuItem, IconButton, Input } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { SendRequest } from "../api";

function HeaderWidgets({ customWidgetEntries, pageid, setIsLoading, users }) {
  const [widgetsLevelZero, setWidgetsLevelZero] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const levelZeroWidgets = customWidgetEntries.filter(
      (widget) => widget.custom_widget.level === 0
    );
    // console.log("levelZeroWidgets ->>> ", levelZeroWidgets);
    setWidgetsLevelZero(levelZeroWidgets);

    const initialValues = levelZeroWidgets.reduce((acc, widget) => {
      acc[widget.id] =
        widget.custom_widget.type === 1
          ? widget.numeric_value || 0
          : widget.selected_choice || "";
      return acc;
    }, {});

    // console.log("HEADER INITIAL VALUES === ", initialValues);

    setSelectedValues(initialValues);
  }, [customWidgetEntries]);

  const handleChange = (widgetId, customwidgetId, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [widgetId]: value,
    }));

    const widgetType = widgetsLevelZero.find((w) => w.id === widgetId)
      .custom_widget.type;

    if (widgetType === 1) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        updateWidget(widgetId, customwidgetId, value);
      }, 1000);
    } else {
      updateWidget(widgetId, customwidgetId, value);
    }
  };

  const updateWidget = async (widgetId, customwidgetId, value) => {
    setIsLoading(true);
    let data = {
      page: pageid,
      custom_widget: customwidgetId,
    };

    const widgetType = widgetsLevelZero.find((w) => w.id === widgetId)
      .custom_widget.type;
    if (widgetType === 0 || widgetType === 2) {
      data.selected_choice = value;
    } else if (widgetType === 1) {
      data.numeric_value = value;
    }

    // console.log("BEFORE  UPDATE DATA === ", data);

    try {
      const response = await SendRequest(
        `/api/customwidgetentry/${widgetId}/`,
        "PUT",
        data
      );
      // console.log("Update response:", response);
    } catch (error) {
      console.error("Error updating widget entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const displayedWidgets = isExpanded
    ? widgetsLevelZero
    : widgetsLevelZero.slice(0, 5);

  return (
    <div className="d-flex gap-3 align-items-center flex-wrap">
      {widgetsLevelZero.length > 5 && (
        <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      )}

      {displayedWidgets.map((widget) => {
        const widgetType = widget.custom_widget.type;

        return (
          <div key={widget.id} className="d-flex align-items-center gap-2">
            {widgetType === 0 && (
              <Select
                value={selectedValues[widget.id] || ""}
                onChange={(e) =>
                  handleChange(
                    widget.id,
                    widget.custom_widget.id,
                    e.target.value
                  )
                }
                style={{ marginRight: "5px", marginBottom: "5px" }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a {widget.custom_widget.name}
                </MenuItem>
                {widget.custom_widget.choices.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
              </Select>
            )}

            {widgetType === 1 && (
              <div className="d-flex gap-2">
                <div>
                  {widget.custom_widget.name} : &nbsp;&nbsp;
                  <Input
                    type="number"
                    value={selectedValues[widget.id] || 0}
                    onChange={(e) =>
                      handleChange(
                        widget.id,
                        widget.custom_widget.id,
                        e.target.value
                      )
                    }
                    style={{
                      width: "100px",
                      marginRight: "5px",
                      marginBottom: "5px",
                    }}
                  />
                </div>
              </div>
            )}

            {widgetType === 2 && (
              <Select
                value={selectedValues[widget.id] || ""}
                onChange={(e) =>
                  handleChange(
                    widget.id,
                    widget.custom_widget.id,
                    e.target.value
                  )
                }
                style={{ marginRight: "5px", marginBottom: "5px" }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select a user
                </MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.email} value={user.email}>
                    {user.email}
                  </MenuItem>
                ))}
              </Select>
            )}

            <IconButton
              onClick={(event) =>
                window.deleteCustomWidget(event, widget.id, 0)
              }
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
}

export default HeaderWidgets;
