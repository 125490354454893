import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams, useLocation } from "react-router-dom";
import { SendRequest } from "../api";
import { useNavigate } from "react-router-dom";

import AlertContext from "./AlertContext";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Box,
  Button,
  Link as MuiLink,
  IconButton,
  Menu,
  Typography,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import PopupComponent from "../components/PopupComponent";
import LoadingComponent from "../components/LoadingComponent";
import PagePopupComponent from "./PagePopUpComponent";
import FolderPopupComponent from "./FolderPopupComponent";

import RenameSvg from "../svg/rename";
import DeleteSvg from "../svg/delete";
import PlusSvg from "../svg/plus";
import MenuSvg from "../svg/menu";
import OverviewSvg from "../svg/overview";
import FolderSvg from "../svg/folder";
import OpenFolderSvg from "../svg/OpenFolderSvg";

function ProjectLeft({
  type,
  dictionary,
  projectName,
  projectId,
  isAdmin,
  isSuperAdmin,
  isLoading,
  setIsLoading,
  setShowAllFolders,
  setClickedCopyPage,
  handleFolderClick,
  isUpgrade,
}) {

  const location = useLocation();
  const { id, folderid, pageid } = useParams();

  const { setAlert } = useContext(AlertContext);

  const [isEditingProjectName, setIsEditingProjectName] = useState(false);

  const [editing, setEditing] = useState(false); // State to determine editing mode
  const [editingFolderId, setEditingFolderId] = useState(null); // State to store the current editing folder's ID
  const [editingPage, setEditingPage] = useState(false); // State to determine if page renaming is active
  const [editingPageId, setEditingPageId] = useState(null); // State to store the current editing page's ID

  const navigate = useNavigate();
  const [updatedDictionary, setUpdatedDictionary] = useState([]);
  // state for each folder toggle.
  const [expandedFolders, setExpandedFolders] = useState({});

  // Project related states
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const [showProjectRenameModal, setShowProjectRenameModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [values, setValues] = useState([projectName]);
  const [errors, setErrors] = useState([]);

  // Folder related states
  const [showFolderRenameModal, setShowFolderRenameModal] = useState(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState(false);
  const [valuesFolder, setValuesFolder] = useState([]);
  const [errorsFolder, setErrorsFolder] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState(null);
  const [selectedFolderLocation, setSelectedFolderLocation] = useState(null);
  const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false);

  // Page related states
  const [showPageRenameModal, setShowPageRenameModal] = useState(false);
  const [showPageDeleteModal, setShowPageDeleteModal] = useState(false);
  const [valuesPage, setValuesPage] = useState([]);
  const [errorsPage, setErrorsPage] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPageName, setSelectedPageName] = useState(null);
  const [selectedPageLocation, setSelectedPageLocation] = useState(null);
  const [isPagePopupOpen, setIsPagePopupOpen] = useState(false);

  // invite people states

  const [valuesInvite, setValuesInvite] = useState([]);
  const [errorsInvite, setErrorsInvite] = useState([]);

  const toggleFolderVisibility = (event, folderId) => {
    event.stopPropagation();
    event.preventDefault();
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));

    // console.log(expandedFolders);
  };

  useEffect(() => {
    setValues([projectName]);

    if (dictionary) {
      const sortedDictionary = dictionary.map((folder) => ({
        ...folder,
        pages: [...folder.pages].sort((a, b) => a.index - b.index),
      }));
      setUpdatedDictionary(sortedDictionary);
    }
  }, [projectName, dictionary]); // Run this effect whenever `projectName` changes

  useEffect(() => {
    // Reset the states when the component mounts or the navigation changes
    setIsProjectMenuOpen(false);
    setShowProjectRenameModal(false);
    setShowDeleteProjectModal(false);
    setIsFolderPopupOpen(false);
    setIsPagePopupOpen(false);
  }, [location.pathname]); // This effect runs whenever the pathname changes

  // Handle click events of invite, project , folder , page

  const handleFolderMenuClick = (folder, foldername, event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedFolder(folder);
    setSelectedFolderName(foldername);
    setSelectedFolderLocation(event.clientY - 10);
    if (isFolderPopupOpen) {
      setIsFolderPopupOpen(false);
      setIsPagePopupOpen(false);
      setIsProjectMenuOpen(false);
    } else {
      setIsFolderPopupOpen(true);
      setIsPagePopupOpen(false);
      setIsProjectMenuOpen(false);
    }
  };

  const handleProjectClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // console.log(isProjectMenuOpen, showProjectRenameModal);
    if (isProjectMenuOpen) {
      setIsFolderPopupOpen(false);
      setIsPagePopupOpen(false);
      setIsProjectMenuOpen(false);
    } else {
      setIsFolderPopupOpen(false);
      setIsPagePopupOpen(false);
      setIsProjectMenuOpen(true);
      setShowProjectRenameModal(false);
      setShowDeleteProjectModal(false);
    }

    // console.log(isProjectMenuOpen, showProjectRenameModal);
  };

  const handlePageMenuClick = (page, pagename, event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedPage(page);
    setSelectedPageName(pagename);
    setSelectedPageLocation(event.clientY - 10);

    if (isPagePopupOpen) {
      setIsPagePopupOpen(false);
      setIsFolderPopupOpen(false);
      setIsProjectMenuOpen(false);
    } else {
      setIsPagePopupOpen(true);
      setIsFolderPopupOpen(false);
      setIsProjectMenuOpen(false);
    }
  };

  // invite function

  const inviteUser = async () => {
    setIsLoading(true);
    const data = { email: valuesInvite[0], project: projectId };
    const response = await SendRequest(`/api/invite/`, "POST", data);
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      setAlert("User invited successfully", "info");
    }
    setIsLoading(false);
  };
  // Folder menu click functions

  const handleFolderRename = (folder) => {
    // console.log(folder);
    setValuesFolder([selectedFolderName]);
    setShowFolderRenameModal(true);
    // console.log("clikeddd");
  };

  const handleTemplateNew = (folder) => {
    // console.log(folder);
    // console.log("clikeddd");
    navigate(`/templates/${folder}/`);
  };

  const handleFolderDelete = (folder) => {
    setShowFolderDeleteModal(true);
    // console.log(folder);
  };
  // Page menu click functions

  const handlePageEdit = (page) => {
    // console.log(page);
    window.location.href = `/edit/${projectId}/0/${page}/`;
  };
  const handlePageRename = (page) => {
    setValuesPage([selectedPageName]);
    setShowPageRenameModal(true);
    // console.log("clikeddd");
  };
  const handlePageDelete = (page) => {
    setShowPageDeleteModal(true);
    // console.log(page);
  };

  // Handle name changes of invite, project , folder , page
  const handleInviteChange = (name, value) => {
    // console.log(name, value);
    let updatedValues = [...valuesInvite];
    updatedValues[0] = value;
    if (!updatedValues[0]) {
      setErrorsInvite(["Email cannot be empty"]);
    } else {
      setErrorsInvite([]);
    }

    setValuesInvite(updatedValues);
  };

  const handleProjectNameChange = (name, value) => {
    // console.log(name, value);
    let updatedValues = [...values];
    updatedValues[0] = value;
    if (!updatedValues[0]) {
      setErrors(["Name cannot be empty"]);
    } else {
      setErrors([]);
    }
    if (updatedValues[0].length < 15) {
      setValues(updatedValues);
    }
  };

  const handleFolderNameChange = (name, value) => {
    // console.log(name, value);
    let updatedValues = [...valuesFolder];
    updatedValues[0] = value;
    if (!updatedValues[0]) {
      setErrorsFolder(["Name cannot be empty"]);
    } else {
      setErrorsFolder([]);
    }
    if (updatedValues[0].length < 15) {
      setValuesFolder(updatedValues);
    }
  };

  const handlePageNameChange = (name, value) => {
    // console.log(name, value);
    let updatedValues = [...valuesPage];
    updatedValues[0] = value;
    if (!updatedValues[0]) {
      setErrorsPage(["Name cannot be empty"]);
    } else {
      setErrorsPage([]);
    }
    if (updatedValues[0].length < 50) {
      setValuesPage(updatedValues);
    }
  };
  // Project rename , delete functions
  const renameProject = async () => {
    setIsLoading(true);
    const data = { name: values[0] };
    const response = await SendRequest(
      `/api/rename_project/${projectId}/`,
      "POST",
      data
    );
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      setAlert("Project renamed successfully", "info");
    }
    setIsLoading(false);
  };

  const deleteProject = async () => {
    setIsLoading(true);

    const response = await SendRequest(`/api/projects/${projectId}/`, "DELETE");
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
      setIsLoading(false);
    } else {
      // setAlert("Project deleted successfully", "info");
      window.location.href = "/projects/";
    }
  };
  // Folder functions rename , delete

  const renameFolder = async () => {
    setIsLoading(true);
    const data = { name: valuesFolder[0] };
    const response = await SendRequest(
      `/api/rename_folder/${selectedFolder}/`,
      "POST",
      data
    );
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      setAlert("Folder renamed successfully", "info");
      const updatedFolders = updatedDictionary.map((folder) =>
        folder.id === selectedFolder
          ? { ...folder, name: valuesFolder[0] }
          : folder
      );
      setUpdatedDictionary(updatedFolders);
      // window.location.reload();
    }
    setIsLoading(false);
  };

  const deleteFolder = async () => {
    setIsLoading(true);

    const response = await SendRequest(
      `/api/folders/${selectedFolder}/`,
      "DELETE"
    );
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      setAlert("Folder deleted successfully", "info");
      window.location.reload();
    }
    setIsLoading(false);
  };

  // Page functions , edit , rename , delete

  const renamePage = async () => {
    setIsLoading(true);
    const data = { name: valuesPage[0] };
    const response = await SendRequest(
      `/api/rename_page/${selectedPage}/`,
      "POST",
      data
    );
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      const updatedFolders = updatedDictionary.map((folder) => {
        if (folder.pages.some((page) => page.id === selectedPage)) {
          // Update the name of the page inside this folder
          return {
            ...folder,
            pages: folder.pages.map((page) =>
              page.id === selectedPage ? { ...page, name: valuesPage[0] } : page
            ),
          };
        }
        return folder;
      });
      setUpdatedDictionary(updatedFolders);
      setAlert("Page renamed successfully", "info");
      // window.location.reload();
    }
    setIsLoading(false);
  };

  const deletePage = async () => {
    setIsLoading(true);

    const response = await SendRequest(`/api/pages/${selectedPage}/`, "DELETE");
    // console.log(response);
    if (response["error"]) {
      setAlert(response["error"], "error");
    } else {
      setAlert("Page deleted successfully", "info");
      window.location.href = "/projects/";
    }
    setIsLoading(false);
  };

  // Popup creation data for project rename , delete

  const renamePopupData = {
    heading: "Rename Project",
    fields: ["text"],
    placeholders: ["Enter new name..."],
    labels: ["Name"],
    values: values, // you can set an initial value if needed or manage the state for the rename value
    buttonLabel: "Save",
    buttonAction: () => {
      renameProject();
      setShowProjectRenameModal(false);
      setIsProjectMenuOpen(false);
    },
    showModal: showProjectRenameModal,
    handleCloseModal: () => {
      setShowProjectRenameModal(false);
      setIsProjectMenuOpen(false);
      // console.log("closed rename", showProjectRenameModal);
    },
    onValueChange: handleProjectNameChange,
    errors: errors,
  };
  const deleteProjectPopupData = {
    heading: "Do you want to delete the project?",
    fields: [],
    placeholders: [],
    labels: [],
    values: [],
    buttonLabel: "Delete",
    buttonAction: () => {
      // Logic to rename the item goes here
      deleteProject();
      setShowDeleteProjectModal(false); // Close modal after deleting
      setIsProjectMenuOpen(false);
    },
    showModal: showDeleteProjectModal,
    handleCloseModal: () => {
      setShowDeleteProjectModal(false);
      setIsProjectMenuOpen(false);
    },
    onValueChange: undefined,
    errors: [],
  };

  // Popup creation data for folder rename , delete
  const deleteFolderPopupData = {
    heading: "Do you want to delete the folder?",
    fields: [],
    placeholders: [],
    labels: [],
    values: [],
    buttonLabel: "Delete",
    buttonAction: () => {
      deleteFolder();
      setShowFolderDeleteModal(false);
      setIsFolderPopupOpen(false);
    },
    showModal: showFolderDeleteModal,
    handleCloseModal: () => {
      setShowFolderDeleteModal(false);
      setIsFolderPopupOpen(false);
    },
    onValueChange: undefined,
    errors: [],
  };

  const renameFolderPopupData = {
    heading: "Rename Folder",
    fields: ["text"],
    placeholders: ["Enter new name..."],
    labels: ["Name"],
    values: valuesFolder, // you can set an initial value if needed or manage the state for the rename value
    buttonLabel: "Save",
    buttonAction: () => {
      // Logic to rename the item goes here
      renameFolder();
      setShowFolderRenameModal(false);
      setIsFolderPopupOpen(false);
    },
    showModal: showFolderRenameModal,
    handleCloseModal: () => {
      setShowFolderRenameModal(false);
      setIsFolderPopupOpen(false);
    },
    onValueChange: handleFolderNameChange,
    errors: errorsFolder,
  };

  // Popup creation data for page rename , delete

  const deletePagePopupData = {
    heading: "Do you want to delete the page?",
    fields: [],
    placeholders: [],
    labels: [],
    values: [],
    buttonLabel: "Delete",
    buttonAction: () => {
      deletePage();
      setShowPageDeleteModal(false);
      setIsPagePopupOpen(false);
    },
    showModal: showPageDeleteModal,
    handleCloseModal: () => {
      setShowPageDeleteModal(false);
      setIsPagePopupOpen(false);
    },
    onValueChange: undefined,
    errors: [],
  };

  const renamePagePopupData = {
    heading: "Rename Page",
    fields: ["text"],
    placeholders: ["Enter new name..."],
    labels: ["Name"],
    values: valuesPage,
    buttonLabel: "Save",
    buttonAction: () => {
      // Logic to rename the item goes here
      renamePage();
      setShowPageRenameModal(false);
      setIsPagePopupOpen(false);
    },
    showModal: showPageRenameModal,
    handleCloseModal: () => {
      setShowPageRenameModal(false);
      setIsPagePopupOpen(false);
    },
    onValueChange: handlePageNameChange,
    errors: errorsPage,
  };
  // Create new folder , new page

  const createNewFolder = async () => {
    try {
      setIsLoading(true);
      const data = { projectId: projectId };
      const response = await SendRequest("/api/folders/", "POST", data);
      // console.log(response);
      if (response["error"]) {
        setAlert(response["error"], "error");
      } else {
        setUpdatedDictionary((prevState) => [...prevState, response]);
        // console.log(updatedDictionary);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewPage = async (folderId) => {
    window.location.href = `/edit/${projectId}/${folderId}/`;
  };

  // Functions for tree page structure and changing indexes

  const sendUpdatedIndexes = async (dataToSend) => {
    setIsLoading(true);
    try {
      const response = await SendRequest(
        "/api/change_index/",
        "POST",
        dataToSend
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragStart = () => {};

  const handleDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;

    // If the draggable element is dropped outside a droppable zone
    if (!destination) {
      return;
    }

    // If the draggable element is dropped in the same location
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    let newUpdatedDictionary = [...updatedDictionary];

    // If the draggable element is dropped within the same folder
    if (type === "PAGE" && source.droppableId === destination.droppableId) {
      const folderIndex = newUpdatedDictionary.findIndex(
        (folder) => folder.id.toString() === source.droppableId
      );

      if (folderIndex !== -1) {
        const folder = newUpdatedDictionary[folderIndex];
        const updatedPages = Array.from(folder.pages);
        const [removedPage] = updatedPages.splice(source.index, 1);
        updatedPages.splice(destination.index, 0, removedPage);

        const updatedFolder = { ...folder, pages: updatedPages };
        newUpdatedDictionary[folderIndex] = updatedFolder;
      }
    }

    if (type === "PAGE" && source.droppableId !== destination.droppableId) {
      const sourceFolderIndex = newUpdatedDictionary.findIndex(
        (folder) => folder.id.toString() === source.droppableId
      );
      const destinationFolderIndex = newUpdatedDictionary.findIndex(
        (folder) => folder.id.toString() === destination.droppableId
      );

      if (sourceFolderIndex !== -1 && destinationFolderIndex !== -1) {
        const sourceFolder = newUpdatedDictionary[sourceFolderIndex];
        const destinationFolder = newUpdatedDictionary[destinationFolderIndex];

        const updatedSourcePages = Array.from(sourceFolder.pages);
        const [removedPage] = updatedSourcePages.splice(source.index, 1);
        const updatedSourceFolder = {
          ...sourceFolder,
          pages: updatedSourcePages,
        };

        const updatedDestinationPages = Array.from(destinationFolder.pages);
        updatedDestinationPages.splice(destination.index, 0, removedPage);
        const updatedDestinationFolder = {
          ...destinationFolder,
          pages: updatedDestinationPages,
        };

        newUpdatedDictionary[sourceFolderIndex] = updatedSourceFolder;
        newUpdatedDictionary[destinationFolderIndex] = updatedDestinationFolder;
      }
    }

    setUpdatedDictionary(newUpdatedDictionary);

    // create the data to send
    const dataToSend = newUpdatedDictionary.map((folder) => ({
      id: folder.id,
      pages: folder.pages.map((page) => page.id),
    }));

    const newData = { project: projectId, folders: dataToSend };

    // send the updated indexes to the server
    // console.log("DATA TO SEND => ", newData);
    sendUpdatedIndexes(newData);
  };

  // if (isLoading) {
  //   return <LoadingComponent />;
  // }

  return (
    <DragDropContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      getContainerForClone={() => document.body}
    >
      <Box className="project-left">
        <PopupComponent {...renameFolderPopupData} />
        <PopupComponent {...deleteFolderPopupData} />
        <PopupComponent {...deletePagePopupData} />
        <PopupComponent {...renamePagePopupData} />

        {type === 0 ? (
          <>
            <MuiLink
              component={RouterLink}
              to={`/project/${projectId}/`}
              underline="none"
              className=""
            >
              <Box
                display="flex"
                flexDirection="column"
                className="proj-name foldcont"
              >
                {/* <Box>{values[0]}</Box> */}
                <Box onDoubleClick={() => setIsEditingProjectName(true)}>
                  {isEditingProjectName ? (
                    <input
                      value={values[0]}
                      onChange={(e) =>
                        handleProjectNameChange(values[0], e.target.value)
                      }
                      onBlur={() => {
                        setIsEditingProjectName(false);
                        if (!errors.length) {
                          renameProject();
                        }
                      }}
                      autoFocus // This makes sure the input is focused when it appears
                    />
                  ) : (
                    values[0]
                  )}
                </Box>
                <Box display="flex" gap={2}>
                  <Tooltip title="Add New Folder">
                    <IconButton onClick={createNewFolder} className="proj-new">
                      <PlusSvg />
                    </IconButton>
                  </Tooltip>
                  {(isAdmin || isSuperAdmin) && (
                    <Tooltip title="Menu">
                      <IconButton
                        onClick={(e) => handleProjectClick(e)}
                        className="proj-new"
                      >
                        <MenuSvg />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isProjectMenuOpen && (isAdmin || isSuperAdmin) && (
                    <div className="menu">
                      <div
                        className="menu-item"
                        onClick={() => setShowProjectRenameModal(true)}
                      >
                        <div>
                          <RenameSvg />
                        </div>
                        <div className="menu-item-name">Rename</div>
                        <PopupComponent {...renamePopupData} />
                      </div>

                      <div
                        className="menu-item"
                        onClick={() => setShowDeleteProjectModal(true)}
                      >
                        <div>
                          <DeleteSvg />
                        </div>
                        <div className="menu-item-name">Delete</div>
                        <PopupComponent {...deleteProjectPopupData} />
                      </div>
                    </div>
                  )}

                  {isProjectMenuOpen && (isAdmin || isSuperAdmin) && (
                    <Menu>
                      <MenuItem onClick={() => setShowProjectRenameModal(true)}>
                        <RenameSvg />
                        Rename
                      </MenuItem>
                      <MenuItem onClick={() => setShowDeleteProjectModal(true)}>
                        <DeleteSvg />
                        Delete
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              </Box>
            </MuiLink>
          </>
        ) : null}

        {type === 0 ? (
          <>
            {(isAdmin || isSuperAdmin) && (
              <Button
                className="proj-invite btn btn-primary"
                onClick={() => navigate(`/project/${id}/users/`)}
              >
                Users
              </Button>
            )}
          </>
        ) : (
          <Button
            className="proj-invite btn btn-primary"
            onClick={() => handleFolderClick(undefined)}
          >
            Copy
          </Button>
        )}
        <Box className="over-cont">
          {type === 0 ? (
            <>
              <MuiLink
                component={RouterLink}
                to={`/project/${projectId}/`}
                className=""
              >
                <Box
                  display="flex"
                  gap={3}
                  className={`foldcont ${
                    folderid === undefined ? "active" : ""
                  }`}
                >
                  <OverviewSvg />
                  <Typography className="projname">Overview</Typography>
                </Box>
              </MuiLink>
            </>
          ) : null}

          {updatedDictionary.map((folder, folderIndex) => (
            <Box key={folder.id.toString()} className="folder">
              <MuiLink
                component={RouterLink}
                to={`/project/${projectId}/${folder.id}/`}
                className=""
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={`foldcont ${
                    folderid == folder.id ? "active" : ""
                  }`}
                >
                  <Box display="flex" gap={3} sx={{ alignItems: "center" }}>
                    {expandedFolders[folder.id] ? (
                      <OpenFolderSvg />
                    ) : (
                      <FolderSvg />
                    )}
                    {type === 0 && editing && editingFolderId === folder.id ? (
                      <input
                        value={valuesFolder[0]}
                        onChange={(e) =>
                          handleFolderNameChange(folder.name, e.target.value)
                        }
                        onBlur={() => {
                          setEditing(false);
                          if (!errorsFolder.length) {
                            // check for errors before renaming
                            renameFolder();
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            setEditing(false);
                            if (!errorsFolder.length) {
                              // check for errors before renaming
                              renameFolder();
                            }
                          }
                        }}
                        autoFocus // Automatically focuses the input when it appears
                      />
                    ) : (
                      <Typography
                        className="projname"
                        onDoubleClick={() => {
                          setSelectedFolderName(folder.name);
                          setSelectedFolder(folder.id);
                          setValuesFolder([folder.name]);
                          setEditingFolderId(folder.id);
                          setEditing(true);
                        }}
                      >
                        {folder.name}
                      </Typography>
                    )}
                  </Box>

                  <Box display="flex" gap={2}>
                    <IconButton
                      onClick={(e) => toggleFolderVisibility(e, folder.id)}
                    >
                      {expandedFolders[folder.id] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    {type === 0 ? (
                      <>
                        <IconButton
                          onClick={() => createNewPage(folder.id)}
                          className="proj-new"
                        >
                          <PlusSvg />
                        </IconButton>
                        <IconButton
                          onClick={(e) =>
                            handleFolderMenuClick(folder.id, folder.name, e)
                          }
                          className="proj-new"
                        >
                          <MenuSvg />
                        </IconButton>
                      </>
                    ) : null}
                  </Box>
                </Box>
              </MuiLink>
              {expandedFolders[folder.id] && (
                <Droppable droppableId={folder.id.toString()} type="PAGE">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {folder.pages.length === 0 ? (
                        // Render the placeholder if the folder is empty
                        <div style={{ height: "1px", opacity: 0 }}></div>
                      ) : (
                        folder.pages.map((page, pageIndex) => {
                          if (type === 0) {
                            return (
                              <Draggable
                                key={page.id}
                                draggableId={page.id.toString()}
                                index={pageIndex}
                              >
                                {(provided) => (
                                  <MuiLink
                                    component={RouterLink}
                                    to={`/project/${projectId}/${folder.id}/${page.id}/?type=${type}`}
                                    className=""
                                  >
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      className={`page ${
                                        pageid == page.id ? "active" : ""
                                      }`}
                                    >
                                      <img
                                        className="page-img"
                                        src={
                                          page.type
                                            ? page.type.image
                                            : "/images/page.png"
                                        }
                                      />

                                      <Typography className="page-name">
                                        {editingPage &&
                                        editingPageId === page.id ? (
                                          <input
                                            value={valuesPage[0]}
                                            onChange={(e) =>
                                              handlePageNameChange(
                                                page.name,
                                                e.target.value
                                              )
                                            }
                                            onBlur={() => {
                                              setEditingPage(false);
                                              if (!errorsPage.length) {
                                                // Check for errors before renaming
                                                renamePage();
                                              }
                                            }}
                                            onKeyUp={(e) => {
                                              if (e.key === "Enter") {
                                                setEditingPage(false);
                                                if (!errorsPage.length) {
                                                  // Check for errors before renaming
                                                  renamePage();
                                                }
                                              }
                                            }}
                                            autoFocus // Automatically focuses the input when it appears
                                          />
                                        ) : (
                                          <span
                                            onDoubleClick={() => {
                                              setSelectedPageName(page.name);
                                              setValuesPage([page.name]);
                                              setSelectedPage(page.id);
                                              setEditingPageId(page.id);
                                              setEditingPage(true);
                                            }}
                                          >
                                            {page.name}
                                          </span>
                                        )}
                                      </Typography>

                                      <IconButton
                                        onClick={(e) =>
                                          handlePageMenuClick(
                                            page.id,
                                            page.name,
                                            e
                                          )
                                        }
                                        className="proj-new"
                                      >
                                        <MenuSvg />
                                      </IconButton>
                                    </Box>
                                  </MuiLink>
                                )}
                              </Draggable>
                            );
                          } else {
                            return (
                              <MuiLink
                                component={RouterLink}
                                to={`/project/${projectId}/${folder.id}/${page.id}/?type=${type}`}
                                className=""
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  className={`page ${
                                    pageid == page.id ? "active" : ""
                                  }`}
                                >
                                  <img
                                    className="page-img"
                                    src={
                                      page.type
                                        ? page.type.image
                                        : "/images/page.png"
                                    }
                                  />

                                  <Typography className="page-name">
                                    <span>{page.name}</span>
                                  </Typography>

                                  <IconButton
                                    className="proj-new"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setClickedCopyPage(page.id);
                                      setShowAllFolders(true);
                                    }}
                                  >
                                    <FileCopyIcon />
                                  </IconButton>
                                </Box>
                              </MuiLink>
                            );
                          }
                        })
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              )}
            </Box>
          ))}
          {type == 1 && isUpgrade ? (
            <div className="upgrade">
              <img src="/images/card.gif" />
              <div>Upgrade to see more than two folders and pages.</div>
            </div>
          ) : null}
        </Box>

        {isFolderPopupOpen && (
          <FolderPopupComponent
            folder={selectedFolder}
            onClose={() => setIsFolderPopupOpen(false)}
            onEdit={() => handleFolderRename(selectedFolder)}
            onDelete={() => handleFolderDelete(selectedFolder)}
            onTemplateNew={() => handleTemplateNew(selectedFolder)}
            selectedFolderLocation={selectedFolderLocation}
          />
        )}

        {isPagePopupOpen && (
          <PagePopupComponent
            page={selectedPage}
            onClose={() => setIsPagePopupOpen(false)}
            onRename={() => handlePageRename(selectedPage)}
            onEdit={() => handlePageEdit(selectedPage)}
            onDelete={() => handlePageDelete(selectedPage)}
            selectedPageLocation={selectedPageLocation}
          />
        )}
      </Box>
    </DragDropContext>
  );
}

export default ProjectLeft;
