import "../css/project.css";
import React, { useState, useEffect, useContext } from "react";
import ProjectLeft from "../components/ProjectLeft";
import ProjectRight from "../components/ProjectRight";
import { useParams } from "react-router-dom";
import { SendRequest } from "../api";
import LoadingComponent from "../components/LoadingComponent";
import { CSSTransition } from "react-transition-group";
import { Button, Box, CircularProgress } from "@mui/material";
import SplitPane from "react-split-pane";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  ListItem,
  ListItemText,
  Alert,
  List,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AlertContext from "../components/AlertContext";
function ProjectPage({ isAdmin, isSuperAdmin }) {
  const [dictionary, setDictionary] = useState([]);
  const [mainDictionary, setMainDictionary] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [showProjectLeft, setShowProjectLeft] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [showAllFolders, setShowAllFolders] = useState(false);
  const [clickedCopyPage, setClickedCopyPage] = useState(false);
  const { setAlert } = useContext(AlertContext);
  let { id } = useParams(); // get id from URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendRequest(`/api/projects/${id}`, "GET");

        if (response.detail) {
          throw new Error(response.detail);
          // show 404 page
        } else {
          // if(response)
          if (response.folders) {
            setDictionary(response.folders);
            setProjectName(response.name);
            setProjectId(response.id);
          } else {
            // show no folders
          }
        }
      } catch (err) {
        console.log(err);
        setAlert(err, "error");
      } finally {
        setLoading(false);
      }
    };

    const fetchMainData = async () => {
      try {
        const response = await SendRequest(`/api/mainproject/`, "GET");
        if (response.detail) {
          throw new Error(response.detail);
        } else {
          if (response.folders) {
            setMainDictionary(response.folders);
            if (response.is_free) {
              setIsUpgrade(true);
            }
          } else {
          }
        }
      } catch (err) {
        console.log(err);
        setAlert(err, "error");
      }
    };
    fetchData();
    fetchMainData();
  }, [id]); // dependency array, the effect will rerun if id changes

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  async function handleFolderClick(folid) {
    setLoading(true);
    try {
      var response;
      if (folid) {
        response = await SendRequest(`/api/copypage/`, "POST", {
          folder: folid,
          page: clickedCopyPage,
        });
      } else {
        response = await SendRequest(`/api/copypage/`, "POST", { project: id });
      }

      if (response.detail) {
        throw new Error(response.detail);
        // show 404 page
      } else {
        // if(response)
        if (response.error) {
          setAlert(response.error, "error");
        } else {
          setAlert("Copied...", "info");
        }
      }
    } catch (err) {
      console.log(err);
      setAlert(err, "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            bottom: "0",
            zIndex: "1000",
            background: "aliceblue",
            width: "100%",
            height: "100%",
            opacity: "0.8",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      <Box className="fvh">
        <Button
          className="proj-left-tog d-block d-md-none"
          onClick={() => setShowProjectLeft(!showProjectLeft)}
        >
          {showProjectLeft ? "<<" : ">>"}
        </Button>

        <Box className="fvh d-flex gap-5">
          <SplitPane
            split="vertical"
            defaultSize="fit-content"
            minSize={365}
            maxSize={800}
            classNames="project-left"
          >
            <CSSTransition in={showProjectLeft} timeout={200} unmountOnExit>
              {/* Tabs UI */}
              <>
                <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  centered
                  sx={{ width: "100%", minWidth: "365px" }}
                >
                  <Tab label="My project" />
                  <Tab label="Industry standard" />
                </Tabs>

                {activeTab === 0 && (
                  <ProjectLeft
                    type={0}
                    isAdmin={isAdmin}
                    isSuperAdmin={isSuperAdmin}
                    dictionary={dictionary}
                    projectName={projectName}
                    projectId={projectId}
                    isLoading={loading}
                    setIsLoading={setLoading}
                    // classNames="project-left"
                  />
                )}
                {activeTab === 1 && (
                  <ProjectLeft
                    type={1}
                    isAdmin={isAdmin}
                    isSuperAdmin={isSuperAdmin}
                    dictionary={mainDictionary}
                    setShowAllFolders={setShowAllFolders}
                    setClickedCopyPage={setClickedCopyPage}
                    handleFolderClick={handleFolderClick}
                    projectName={projectName}
                    projectId={projectId}
                    isLoading={loading}
                    setIsLoading={setLoading}
                    isUpgrade={isUpgrade}
                    // classNames="project-left"
                  />
                )}
              </>
            </CSSTransition>
            <ProjectRight
              isAdmin={isAdmin}
              type={activeTab}
              dictionary={dictionary}
              mainDictionary={mainDictionary}
            />
          </SplitPane>
        </Box>
      </Box>

      <Dialog
        open={showAllFolders}
        onClose={() => setShowAllFolders(false)}
        aria-labelledby="folders-dialog"
      >
        <DialogTitle
          id="folders-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Select the folder to paste
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setShowAllFolders(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {dictionary && dictionary.length === 0 ? (
              <Alert severity="info">No folders</Alert>
            ) : (
              dictionary.map((folder, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    setShowAllFolders(false);
                    handleFolderClick(folder.id);
                  }}
                >
                  <ListItemText primary={folder.name} />
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ProjectPage;
