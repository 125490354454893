import React, { useState } from "react";
import { SendRequest } from "../api";
import "../css/login.css";
import LoadingComponent from "../components/LoadingComponent";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
function GetStartedPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [site, setSite] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await SendRequest("/api/register/", "POST", {
        email,
        password,
        site,
      });
      // console.log(response);
      setIsLoading(false);
      if (response["error"]) {
        setError(response["error"]);
      } else {
        setError("Subdomain created.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      setError("Something went wrong!");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={6} md={5} style={{justifyContent: "center", display: "flex" }}>
          <Card
            elevation={3}
            sx={{
              marginTop: "50px",
              maxWidth: "400px",
            }}
          >
            <CardContent style={{ }}>
              <Typography variant="h4" align="center" gutterBottom>
                Get Started
              </Typography>

              <form onSubmit={handleSubmit}>
                {error && (
                  <FormHelperText
                    error
                    align="center"
                    sx={{ marginBottom: "20px", fontSize: "16px" }}
                  >
                    {error}
                  </FormHelperText>
                )}
                <Box mb={3}>
                  <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Your site"
                    fullWidth
                    variant="outlined"
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          .accpro.com
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<img src="/images/right-arrow.svg" alt="" />}
                >
                  Continue
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GetStartedPage;
