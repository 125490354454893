import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Link, Alert } from "@mui/material";
import FolderSvg from "../svg/folder";
import ClockSvg from "../svg/clock";
import ProjectSvg from "../svg/project";

function EachRecentComponent({ id, name, project_name, date }) {
  return (
    <Link
      component={RouterLink}
      to={`/project/${id}/`}
      underline="none"
      color="inherit"
      className="eachrecent eachproj"
      sx={{
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        gap: 2,
        alignItems: "center",
        "&:hover": {
          backgroundColor: "rgba(9, 30, 66, 0.04)",
        },
      }}
    >
      <Box display="flex" gap={2} alignItems="center">
        <ProjectSvg />
        <Box>
          <Typography variant="body1" className="projtitle1">{name}</Typography>
          <Box display="flex" gap={1} alignItems="center">
            {/* <Typography variant="body2" component="span">
              {project_name}
            </Typography> */}
            {/* <Typography variant="body2" component="span">
              •
            </Typography> */}
            <ClockSvg />
            <Typography variant="body2" component="span">
              You visited {date}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  );
}

function DashboardRecent({ projects }) {
  return (
    <Box>
      <Typography variant="h6" marginBottom={1.2}>
        Pick up where you left off
      </Typography>
      <Box className="recentcont">
        {projects.length === 0 ? (
          // <Typography variant="body1">No recent projects</Typography>
          <Alert severity="info">No recent projects</Alert>
        ) : (
          projects.map((project) => (
            <EachRecentComponent
              key={project.id} // Replace 'id' with the actual property name for the project ID
              id={project.project.id}
              name={project.project.name} // Replace 'name' with the actual property name for the project name
              project_name={project.project.name} // Replace 'project_name' with the actual property name for the project name
              date={project.accessed_at} // Replace 'date' with the actual property name for the project date
            />
          ))
        )}
      </Box>
    </Box>
  );
}

export default DashboardRecent;
